import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { ProfileService } from 'src/app/views/profile/profileService';
import { environment } from 'src/environments/environment';
import { UserRole } from 'src/app/core/custom-classes/UserRole';
import { ViewPath } from 'src/app/modules/app-routing.module';
import { BurgerOption, M_TicketNotificacion, NotificationInterface, NotificationType, OnboardingComponent, RoleService, RouterService, SessionService } from '@sinigual/angular-lib';
import { UserRoleEnum } from 'src/app/core/enums/UserRoleEnum';
import { apiService } from 'src/app/core/api/api-service';
import { M_CitaNotificacion, M_HolidayNotification, M_MassiveNotification, M_OrNotification } from 'src/app/core/models/M_Notificacion';
import { MenuComponent } from '../menu/menu.component';
import { OrTimerService } from 'src/app/services/or-timer.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent implements OnInit {
  vp = ViewPath;
  env = environment;
  screenWidth: number = window.innerWidth;
  /**Usuarios */
  perfil: BurgerOption;
  configuracion: BurgerOption;
  cerrarSession: BurgerOption;
  role: UserRoleEnum;
  showNotifications: boolean;
  showOnboarding: boolean = true;
  @Input() onboarding!: OnboardingComponent;
  @Input() mc!: MenuComponent;
  @Output() onclick: EventEmitter<any> = new EventEmitter<any>();
  @HostListener('window:resize', ['$event'])
  onResize(_event: any) {
    this.screenWidth = window.innerWidth;
  }
  constructor(public routerS: RouterService, private chdRef: ChangeDetectorRef, private sessionServie: SessionService, public profileService: ProfileService, private r: RoleService, public apiS: apiService, public timerS: OrTimerService) {
    this.perfil = new BurgerOption("Ver mi perfil", "person", ViewPath.profile)
    this.configuracion = new BurgerOption("Configuración", "settings", ViewPath.configuration)
    this.cerrarSession = new BurgerOption("Cerrar sesión", "logout", ViewPath.login, undefined, () => this.logOut())
    this.role = r.getRole();
    this.showNotifications = true;
    this.showOnboarding = this.role != UserRoleEnum.programming;
  }

  ngOnInit() {
    this.timerS.makeCall();
    this.autoRefeshTime();
  }


  /** For the OR timer */
  autoRefeshTime() {
    setTimeout(() => {
      this.chdRef.detectChanges();
      this.autoRefeshTime();
      if (this.timerS.refOr && this.timerS.elapsed) {
        document.title = this.timerS.elapsed;
      }
      else {
        let v = this.r.getViewFromRoute(this.routerS.route_);
        document.title = v ? v.title : "SiniCloud";
      }
    }, 1000)

  }

  logOut() { this.apiS.logout(); this.sessionServie.logOut(); }
  getRole(): string {
    return new UserRole(this.role).name;
  }

  notificationAction(not: NotificationInterface) {
    if (not instanceof M_CitaNotificacion) { this.appointmentNotification(not) }
    else if (not instanceof M_TicketNotificacion) { this.ticketNotification(not) }
    else if (not instanceof M_HolidayNotification) { this.holidayNotification(not) }
    else if (not instanceof M_OrNotification) { this.orNotification(not) }
    else if (not instanceof M_MassiveNotification) { this.massiveNotification(not) }
  }

  appointmentNotification(n: M_CitaNotificacion) {
    this.routerS.goWithQueryParams(ViewPath.cargaTaller, { id: n.cita?.id, date: n.cita!.fecha_cita, section: "appointment" })
  }

  ticketNotification(n: M_TicketNotificacion) {
    switch (n.type) {
      case (NotificationType.TICKETCOMMENT):
      case (NotificationType.TICKETSTATUS):
        this.routerS.goWithQueryParams(ViewPath.incidencias, { ct_id: n.ct.id, ct_proj: n.ct.project });
        break;
    }
  }

  holidayNotification(n: M_HolidayNotification) {
    this.routerS.goTo(ViewPath.profile);
  }

  orNotification(n: M_OrNotification) {
    //Por si algun dia se hacen notificaciones de OR facturadas : this.routerS.goWithQueryParams(ViewPath.cargaTaller, { id: 5, date: "2023-07-20", section: "or", orfilter1 : 2});
    let view = this.profileService.mecanico ? ViewPath.dashboard : ViewPath.cargaTaller;
    this.routerS.goWithQueryParams(view, { id: n.or?.id, date: n.or?.schedule, section: "or" });
  }

  massiveNotification(n: M_MassiveNotification) {
    switch (n.type) {
      case (NotificationType.MASSIVE_MATRICULAS): {
        this.routerS.goTo(ViewPath.clients)
        break;
      }
      case (NotificationType.MASSIVE_OPERARIOS): {
        this.routerS.goTo(ViewPath.users)
        break;
      }
      case (NotificationType.MASSIVE_OR): {
        let view = this.profileService.mecanico ? ViewPath.dashboard : ViewPath.cargaTaller;
        this.routerS.goWithQueryParams(view, { section: "or" });
        break;
      }
      case (NotificationType.MASSIVE_STOCKVO): {
        this.routerS.goTo(ViewPath.stock)
        break;
      }
      case (NotificationType.MASSIVE_ARTICULOS): {
        this.routerS.goTo(ViewPath.articles)
        break;
      }
    }
  }

}
