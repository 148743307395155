import { Component, EventEmitter, Inject, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatAccordion } from '@angular/material/expansion';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { ImageToggleComponent } from 'src/app/components/image-toggle/image-toggle.component';
import { M_Provider } from 'src/app/core/models/M_Provider';
import { M_Stock } from 'src/app/core/models/M_Stock';
import { EditAddComponent } from '../../edit-add.component';
import { AccordionInterface } from '../accordionInterface';
import { SubirFotografiaComponent } from '../subir-fotografia/subir-fotografia.component';

@Component({
  selector: 'app-publicar-anuncio',
  templateUrl: './publicar-anuncio.component.html',
  styleUrls: ['./publicar-anuncio.component.css', '../accordion.css', '../../accordion-cards/vo-section-title/vo-section-title.component.css']
})
export class PublicarAnuncioComponent implements AccordionInterface {
  @Output() onSave: EventEmitter<any> = new EventEmitter();
  @ViewChild("accordion") accordion!: MatAccordion;
  @ViewChild("check") offer!: MatSlideToggle;
  @ViewChild("sd") publicar!: MatSlideToggle;
  @ViewChild(ImageToggleComponent) providers!: ImageToggleComponent;
  @ViewChild(SubirFotografiaComponent) subirFotografia: SubirFotografiaComponent | undefined;
  public form: UntypedFormGroup;

  constructor(@Inject(EditAddComponent) public parent: EditAddComponent, private formBuilder: UntypedFormBuilder) {
    this.form = this.formBuilder.group({
      //titulo: ['', [Validators.required]],
      descripcion: [''],
      pvp: [''],
      precio: [''],
      oferta: [''],
      publicar: [false]
    })

    if (parent.bike) {
      this.setData(parent.bike)
    }
    parent.subject.subscribe(v => this.setData(v));
  }

  get addContentOk() {
    return this.form.get('descripcion')?.value && this.form.get('precio')?.value;
  }

  setData(data: M_Stock): void {
    this.form.patchValue({
      'titulo': data.add?.titulo,
      'descripcion': data.add?.descripcion,
      'pvp': data.add?.pvp,
      'precio': data.add?.precio?.toLocaleString('es'),
      'oferta': data.add?.oferta?.toLocaleString('es'),
      'publicar': data.add?.publicar ? data.add.publicar : false,
    });
  }

  hasProviders() {
    return this.providers != undefined && this.publicar != undefined && this.providers.somethingSelected && this.publicar.checked;
  }

  ngAfterViewInit(): void {
    if (this.form.get('oferta')!.value) {
      this.offer.checked = true;
    }
  }

  hasImages() {
    return this.subirFotografia?.hasImages();
  }

  checkDisabledAndChecked() {
    if (this.publicar == undefined) { return true; }

    if (this.parent.publicationErrors.length != 0) {
      this.form.patchValue({ publicar: false });
      return true;
    }
    return false;
  }

  getSelectedProviders() {
    var selecteds = this.providers.selected;
    if (selecteds == undefined || !Array.isArray(selecteds)) {
      return [];
    }

    var allP = this.parent.providers;
    var newProviders: M_Provider[] = [];

    /** Item seleccionado en la pantalla (index) */
    selecteds.forEach(s => {
      if (s != undefined && allP[s] != undefined) {
        newProviders.push(allP[s]);
      }
    })

    return newProviders;
  }
}
