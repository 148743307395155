import { CustomDate, M_BaseUser } from "@sinigual/angular-lib";
import { apiService } from "../../core/api/api-service";
import { generateHoliday } from "src/app/functionUtils/functions";

/** [Respuesta a una petición GET]
 *  @description Respuesta al hacer la petición http de los datos del perfil
 *  {@link apiService.showProfile()}
 */
export class M_profile extends M_BaseUser {
    passowrd: string;
    hash: string;
    demo: boolean;
    operario_id: number | undefined;
    user_holidays: CustomDate[] = [];
    company_holidays: CustomDate[] = [];

    constructor(obj: any) {
        super(obj);
        this.passowrd = obj.passowrd;
        this.hash = obj.hash;
        this.demo = obj.demo ? obj.demo : false;
        this.operario_id = obj.operario_id;
        this.company_holidays = generateHoliday(obj.holidays, "company");
        this.user_holidays = generateHoliday(obj.holidays, "user");
    }

    isEmpty() {
        return this.name == "" && this.email == "" && this.name == "";
    }
}