import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { apiService } from 'src/app/core/api/api-service';
import { M_User } from 'src/app/core/models/M_User';
import { DeleteUserComponent } from './delete-user/delete-user.component';
import { CreateEditUserComponent } from './create-edit-user/create-edit-user.component';
import { CustomDate, PageStructureComponent, SearchService } from '@sinigual/angular-lib';
import { feature } from 'src/app/core/features-controller/FeaturesController';
import { ActivationStatus } from 'src/app/core/enums/ActivationStatus';
import { calendarEnabled } from 'src/app/constants/constants';
import { HolidaysCalendarComponent, initialHolidayData } from 'src/app/components/holidays-calendar/holidays-calendar.component';
import { forkJoin } from 'rxjs';
import { ProfileService } from '../profile/profileService';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css', '../../css/table.css']
})
export class UsersComponent {

  au = feature.activateUsers;
  wor = feature.workloadOr;

  ae = ActivationStatus;
  @ViewChild(PageStructureComponent) ps!: PageStructureComponent<M_User>;
  @ViewChild(HolidaysCalendarComponent, { static: true }) holidayC?: HolidaysCalendarComponent;

  originalUserData: M_User[] = [];
  enabledCalender = calendarEnabled;

  dh = ['Nombre', 'Correo', 'Rol']
  dc = ['name', 'email', 'role.name'];

  companyHolidays: CustomDate[] = [];

  holidaysExpanded = false;

  constructor(private chdref: ChangeDetectorRef, private matDialog: MatDialog, private apiS: apiService, private searchS: SearchService, private profileS : ProfileService) {
    this.dh.push(...(this.au ? (['Estado activación']) : []));
    this.dc.push(...(this.au ? (['estado_activacion']) : []))
  }

  ngAfterViewInit(): void {
    this.load();
  }

  getRole(user: M_User) {
    return user.role.name;
  }

  onSaveUserHolidays(onSaveData: initialHolidayData, user: M_User) {
    user.holidays = onSaveData.userH;
    if (this.profileS.clientData?.id == user.id){
      this.profileS.clientData.user_holidays = user.holidays;
    }
  }

  getStatus(user: M_User) {
    return user.estado_activacion == ActivationStatus.activated || user.estado_activacion == null ? "Activado" :
      user.estado_activacion == ActivationStatus.sended ? "Pendiente" :
        user.estado_activacion == ActivationStatus.toActivate ? "Por activar" : "??"
  }

  getStatusClass(user: M_User) {
    return user.estado_activacion == ActivationStatus.activated || user.estado_activacion == null ? "c_p" :
      user.estado_activacion == ActivationStatus.sended ? "c_o" :
        user.estado_activacion == ActivationStatus.toActivate ? "c_r" : "c_t2"
  }

  load() {

    const a = this.apiS.showUsers();
    const b = this.apiS.getCompanyInfo();

    forkJoin([a, b]).subscribe(res => {
      this.ps.initTable(res[0]);
      this.originalUserData = res[0];
      this.chdref.detectChanges();
      this.companyHolidays = res[1].day_holiday;
    })
  }

  allMails() {
    let allMails: string[] = []
    this.originalUserData.forEach(u => {
      allMails.push(u.email)
    })
    return allMails;
  }

  activateUser() {
    return this.apiS.activateUser(this.ps.selected!.id).then(res => {
      this.ps!.selected!.estado_activacion = this.ae.sended;
    })
  }


  openEditUserDialog(user: M_User) {
    const dref = this.matDialog.open(CreateEditUserComponent, { data: [user, this.allMails()] })
    dref.afterClosed().subscribe(ok => { if (ok) { this.load(); this.ps.item = undefined } });
  }

  openCreateUserDialog() {
    const dref = this.matDialog.open(CreateEditUserComponent, { data: [undefined, this.allMails()] })
    dref.afterClosed().subscribe(ok => { if (ok) { this.load(); } });
  }

  openDeleteUserDialog(user: M_User) {
    const dref = this.matDialog.open(DeleteUserComponent, { data: user, panelClass: "core-error-dialog" })
    dref.afterClosed().subscribe(ok => { if (ok) { this.load(); this.ps.item = undefined } });
  }
}
