import { Component, OnInit, ViewChild } from '@angular/core';
import { Filter, PageStructureComponent, SliderFilter } from '@sinigual/angular-lib';
import { apiService } from 'src/app/core/api/api-service';
import { M_Article } from 'src/app/core/models/M_Article';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.css']
})
export class ArticlesComponent implements OnInit {
  @ViewChild(PageStructureComponent) ps!: PageStructureComponent<M_Article>;
  filters : Filter[] | undefined = undefined

  constructor(private apiS: apiService) {
    this.getProducts();
  }

  ngOnInit(): void {
  }

  getProducts() {
    this.apiS.showArticulos().then(resp => {
      if (this.ps) {
        this.filters = [new SliderFilter("Coste", Math.max(0,...resp.map(o => o.Coste)), 'Coste').setId(0),
        new SliderFilter("Stock", Math.max(0,...resp.map(o => o.stock)), 'stock').setId(1)]
        this.ps.initTable(resp);
      }
    });
  }

  filter(object: M_Article, ...filters: Filter[]) : boolean {
    let costOK = true;
    let stockOK = true;

    filters.forEach((filter) => {
        if (filter instanceof SliderFilter && filter.id == 0 && costOK) {
          costOK = filter.checkbyProduct(object);
        }

        if (filter instanceof SliderFilter && filter.id == 1 && stockOK) {
          stockOK = filter.checkbyProduct(object);
        }
    })
    return costOK && stockOK;
  }

}
