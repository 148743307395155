import { CustomDate, match } from "@sinigual/angular-lib";
import { apiService } from "../../core/api/api-service";

export interface M_ShowPreorderInterface{
    id: number,
    license_plate: string,
    datetime_or_opening: CustomDate,
    downloaded: string,
    Nombre1 : string;
    TelFijoPar: string | undefined,
    TelMovilPar: string | undefined;
}

/** [Respuesta a una petición GET]
 *  @description  Historial de recepciones
 *  {@link apiService.showPreOrder()}
 */
export class M_ShowPreorder implements M_ShowPreorderInterface{
    id: number;
    license_plate: string;
    datetime_or_opening: CustomDate;
    downloaded: string;
    Nombre1 : string;
    TelMovilPar : string | undefined;
    TelFijoPar: string | undefined;
    comments : string[] = []
    Mail1p : string | undefined;
    
    constructor(obj : any){
        this.id =                   obj.id;
        this.license_plate =        obj.license_plate? obj.license_plate.toUpperCase() : '';
        this.datetime_or_opening =  new CustomDate(obj.datetime_or_opening);
        this.downloaded =           obj.downloaded;
        this.Nombre1 =              obj.Nombre1;
        this.TelMovilPar =          obj.TelMovilPar ? obj.TelMovilPar.replace('.','') : undefined;
        this.TelFijoPar =           obj.TelFijoPar ? obj.TelFijoPar.replace('.','') : undefined;
        this.comments =             this.reparations(obj.comments);
        this.Mail1p =               obj.Mail1p;
    }

    private reparations(v : any) {
        if (v){
            var c = v.split(",");
            for (let i = 0; i < c.length; i++) {
                c[i] = c[i] .replace(/\'/gi, "")
            }
            return c;
        }
        return [];
    }

    defaultSearchFilter(val: string): boolean {
        return match(val, this.id.toString(), this.license_plate, this.Nombre1, this.TelMovilPar, (this.downloaded ? "PENDIENTE" : "ABIERTA"));
    }
}