import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { AppointmentStatus, CalendarService, CustomDate, ErrorDialogService, RoleService } from '@sinigual/angular-lib';
import { apiService } from 'src/app/core/api/api-service';
import { CustomDateByGranularity, DateByGranularity } from 'src/app/core/custom-classes/CustomDateByGranularity';
import { DateGranularity } from 'src/app/core/enums/DateGranulraity';
import { M_Cita } from 'src/app/core/models/M_Cita';
import { CompanyCalendarEeComponent } from 'src/app/views/edit-company-info/company-calendar-ee/company-calendar-ee.component';

export type initialHolidayData = { companyH: CustomDate[], userH: CustomDate[], userID?: number }

@Component({
  selector: 'app-holidays-calendar',
  templateUrl: './holidays-calendar.component.html',
  styleUrls: ['./holidays-calendar.component.css', './holiday-colors.css']
})
export class HolidaysCalendarComponent implements OnInit, OnChanges {

  @ViewChild('datePicker') caledar?: MatCalendar<Date>;
  /** The component can also be initied by param. If the aparams changes, the component re-init itselef */
  @Input() initData?: initialHolidayData;
  /** Array of role numbers that can edit the calendar */
  @Input() enabledFor: number[] = [];
  /** Show a save button on the bottom of the calendar. By default false */
  @Input() selfSave: boolean = false;
  /** Prespective of the calendar */
  @Input() prespective: "company" | "user" = "company";
  /** Profile */
  @Input() profileView: boolean = false;
  /** Show legend */
  @Input() showLegend: boolean = true;
  /** Show unaved changes label? */
  @Input() showUnsaved: boolean = true;
  /** When the changes are saved */
  @Output() onSave: EventEmitter<initialHolidayData> = new EventEmitter<initialHolidayData>();

  /** ---------- MAIN ARRAYS ---------- */
  /** Holidays to show on the calendar */
  companyHolidays: CustomDate[] = [];
  /** User holidays */
  userHolidays: CustomDate[] = [];

  /** The id of the user on which the holidays will be set */
  userID: number | undefined = undefined;
  /** Is the calendar enbled for the current user? */
  enabled: boolean = false;
  /** Bottom contents of the caledar. Shows all months that have holidays with the respective number of days */
  holidayBreakdown: DateByGranularity[] = [];
  /** If ther is some changes on calendar, show unsaved changes */
  holidayChanges = false;
  /** Current user role */
  userRole: number;
  /** Days that its availability is being checked (api call)*/
  private checkingDays: CustomDate[] = [];
  /** Is calendar loading ? */
  loading = true;

  constructor(private d: MatDialog, private apiService: apiService, private errorD: ErrorDialogService, roleS: RoleService, public calendarS: CalendarService) {
    this.userRole = roleS.getRole();
    this.companyHolidays.push(new CustomDate())
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['initData']) {
      this.initComponent(changes['initData'].currentValue)
    }
  }

  ngOnInit(): void {
    this.enabled = this.enabledFor.some(r => r == this.userRole);
  }

  get prespectiveArrray() {
    return this.prespective == "company" ? this.companyHolidays : this.userHolidays;
  }

  get isCompanyPrespective() {
    return this.prespective == "company";
  }

  get isUserPrespective() {
    return this.prespective == "user";
  }

  getDisabledDays: (date: Date | null) => boolean = (date: Date | null) => {
    if (date == null) return false;
    let isSunday = !this.calendarS.sundayFilter(date);
    if (this.prespective != "company"){
      let alreadyCompanyDay = this.companyHolidays.some(d => d.isEquals(new CustomDate(date)));
      return !isSunday && !alreadyCompanyDay;
    }
    else{
      return !isSunday; 
    }

  }

  /** Set the holiday dates to the caledar. */
  initComponent(data: initialHolidayData) {
    this.companyHolidays = [...data.companyH]; // --> Important to lose the reference with the input
    this.userHolidays = [...data.userH]; // --> Important to lose the reference with the input
    this.userID = data.userID;
    this.caledar?.updateTodaysDate()
    this.holidayBreakdown = new CustomDateByGranularity(this.prespectiveArrray, DateGranularity.MONTH).getData();
    this.loading = false;
    this.holidayChanges = false;
  }

  /** Determines if a day is selected or not. */
  dateClass = (d: Date) => {
    let date_ = new CustomDate(d);

    let finalClass = "";
    /** CSS classes */
    var loading = "loadingDay ";
    var company = "companyDay ";
    var miniCompany = "mini-companyDay ";
    var user = "userDay ";

    if (this.checkingDays.some(d => d.isEquals(date_))) {
      finalClass += loading;
    }

    if (this.isCompanyPrespective) {
      if (this.companyHolidays.some(d => d.isEquals(date_))) {
        finalClass += company;
      }
    }
    else {
      if (this.userHolidays.some(d => d.isEquals(date_))) {
        finalClass += user;
      }
      if (this.companyHolidays.some(d => d.isEquals(date_))) {
        finalClass += miniCompany;
      }
    }

    return finalClass;
  }

  /** It is used to go to a specific day on the calendar. 
   * It is useful to go to the months that have been established as holidays 
  **/
  goToMonth(d: CustomDate) {
    this.caledar?._goToDateInView(d.value, 'month')
  }

  /** Refresh the caledar to re-paint the holiday dates with its respective color */
  refreshCalendar() {
    this.caledar?.updateTodaysDate()
    this.holidayBreakdown = new CustomDateByGranularity(this.prespectiveArrray, DateGranularity.MONTH).getData();
  }

  /** On click a day on the caledar */
  public dateChanged(event: any) {
    if (!this.enabled || this.loading) { return }
    if (event) {

      let dayClicked = new CustomDate(event);
      if (dayClicked.getYear(true) == "1989") {
        this.d.open(CompanyCalendarEeComponent);
      }
      else {
        /** Set a new holiday */
        if (!this.prespectiveArrray.find(d => d.isEquals(dayClicked))) { //Si el dia no esta ya en la array
          this.checkingDays.push(dayClicked);
          this.refreshCalendar();
          this.apiService.checkHolidays(dayClicked, this.userID).then(res => {
            if (res == true) {
              this.prespectiveArrray.push(dayClicked);
              this.holidayChanges = true;
            }
            this.removeDayOfArray(this.checkingDays, dayClicked);
            this.refreshCalendar();
          })
        }
        /** Remove existing holiday */
        else {
          let removedDay = new CustomDate(event);
          this.removeDayOfArray(this.prespectiveArrray, removedDay);
          this.holidayChanges = true;
        }
        this.refreshCalendar()
      }
    }
  }

  /** Removes specific day on specific array */
  private removeDayOfArray(array: CustomDate[], day: CustomDate): boolean {
    const index = array.indexOf(array.find(d => d.isEquals(day))!, 0);
    if (index > -1) {
      array.splice(index, 1);
      return true;
    }
    return false;
  }

  /** Triggered when click the self save button. The 'userID'is needed to save. */
  save() {
    this.apiService.saveHolidaysByUserId(this.userHolidays, this.userID).then(res => {
      this.onSave.emit({ companyH: this.companyHolidays, userH: this.userHolidays, userID: this.userID })
      this.holidayChanges = false;
    })
    this.onSave.emit({ companyH: this.companyHolidays, userH: this.userHolidays, userID: this.userID })
  }


  /** Check if a breakdown month is the same month selected in the calendar  */
  isCurrentMonth(d: CustomDate) {
    var selected = this.caledar?.activeDate;
    if (selected) {
      return d.isMonthEqual(new CustomDate(selected));
    }
    return false;
  }

}
