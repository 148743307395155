import { CompanyInterface, CustomColor, CustomDate, M_Parser, generateImageURL, oneOf, parseOBJ } from "@sinigual/angular-lib";
import { generateHoliday } from "src/app/functionUtils/functions";
import { environment } from "src/environments/environment";

export interface M_CompanyInterface {
    company_name: string
    company_logo: string | undefined;
    day_holiday: CustomDate[];
    morning_work_schedule_start: number | undefined;
    morning_work_schedule_finish: number | undefined;
    afternoon_work_schedule_start: number | undefined;
    afternoon_work_schedule_finish: number | undefined;
    granularity: number;
    id: number | undefined;
    range_of_days: string;
    client_code: string | undefined,
    company_code: string | undefined,
    colors: CustomColor[]
}

export class M_Company implements M_CompanyInterface, M_Parser, CompanyInterface {

    id: number | undefined;

    /** General info */
    email: string | undefined;
    nif: string | undefined;
    direccion: string | undefined;
    codigo_postal: number | undefined;
    telefono: number | undefined;
    telefono_alternativo: number | undefined;

    company_name: string
    company_logo: string | undefined;
    granularity: number;
    day_holiday: CustomDate[];
    morning_work_schedule_start: number | undefined;
    morning_work_schedule_finish: number | undefined;
    afternoon_work_schedule_start: number | undefined;
    afternoon_work_schedule_finish: number | undefined;
    needsToBeParsed: boolean = true;
    range_of_days: string;
    colors: CustomColor[] = [];
    hash: string;
    client_code: string | undefined;
    company_code: string | undefined;
    total_citas: number | undefined;
    capacidad_taller: number | undefined;

    constructor(data: any) {


        //constructObj(this,data);
        //console.log(this)

        this.id = data.company?.id ? data.company.id : undefined;

        /** General info */
        this.email = oneOf(data.company?.email, data.email);
        this.nif = oneOf(data.company?.nif, data.nif);
        this.direccion = oneOf(data.company?.direccion, data.direccion);
        this.codigo_postal = oneOf(data.company?.codigo_postal, data.codigo_postal);
        this.telefono = oneOf(data.company?.telefono, data.telefono);
        this.telefono_alternativo = oneOf(data.company?.telefono_alternativo, data.telefono_alternativo);
        this.total_citas = oneOf(data.company?.total_citas, data.total_citas);
        this.capacidad_taller = oneOf(data.company?.capacidad_taller, data.capacidad_taller);

        if (data.company) {
            this.client_code = data.company.client_code;
            this.company_code = data.company.company_code;
        }
        else {
            this.client_code = data.client_code ? data.client_code : undefined;
            this.company_code = data.company_code ? data.company_code : undefined;
        }

        this.company_name = data.company != undefined ? data.company.company_name ? data.company.company_name : undefined : undefined;

        if (this.company_name == undefined) {
            this.company_name = data.company_name ? data.company_name : undefined;
        }

        var cl = oneOf(data.company?.company_logo, data.company_logo);
        this.company_logo = generateImageURL(environment, cl);
        this.day_holiday = generateHoliday(data.day_holiday, "company");
        this.morning_work_schedule_start = data.morning_work_schedule_start;
        this.morning_work_schedule_finish = data.morning_work_schedule_finish;
        this.afternoon_work_schedule_start = data.afternoon_work_schedule_start;
        this.afternoon_work_schedule_finish = data.afternoon_work_schedule_finish;
        this.granularity = data.granularity;
        this.range_of_days = data.range_of_days;
        if (data.company_colors && data.company_colors.length != 0) {
            for (let i = 0; i < data.company_colors.length; i++) {
                this.colors.push(new CustomColor(data.company_colors[i][0][0]))
            }
        }
        else {
            this.colors.push(new CustomColor())
        }
        this.hash = data.company?.hash ? data.company.hash : undefined;
    }
    getMaxAppointments(): number | undefined {
        return this.total_citas;
    }


    getPlaces(): number | undefined {
        return this.capacidad_taller;
    }


    hasScheduleMorning(): boolean {
        return this.morning_work_schedule_start != undefined && this.morning_work_schedule_finish != undefined;
    }

    hasScheduleAfternooon() {
        return this.afternoon_work_schedule_start != undefined && this.afternoon_work_schedule_finish != undefined;
    }

    parse(): any {
        let obj: any = parseOBJ(this);
        let newDates: string[] = [];
        this.day_holiday.forEach(dat => {
            newDates.push(dat.dataBaseFormat)
        });
        obj['day_holiday'] = newDates;

        let colors_: string[][] = [];
        for (let i = 0; i < this.colors.length; i++) {
            colors_.push([this.colors[i].value, this.colors[i].contrastValue])
        }

        obj['colors'] = colors_;

        return obj;
    }

    getColor1() {
        return this.colors.length >= 1 ? this.colors[0] : new CustomColor();
    }

    getColor2() {
        return this.colors.length >= 2 ? this.colors[1] : new CustomColor();
    }

    get holidays() {
        return this.day_holiday;
    }
}