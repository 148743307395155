import { Component, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EntryPointService, OnBoarding, ResponsiveService, RoleService, Section, Step, StorageService, Views } from '@sinigual/angular-lib';
import { UserRole } from 'src/app/core/custom-classes/UserRole';
import { AppLanguages } from 'src/app/core/enums/AppLanguages';
import { UserRoleEnum } from 'src/app/core/enums/UserRoleEnum';
import { ViewPath } from 'src/app/modules/app-routing.module';
import { environment } from 'src/environments/environment';
import * as LogRocket from 'logrocket';
import { feature } from 'src/app/core/features-controller/FeaturesController';
import { MenuComponent } from 'src/app/components/menu/menu.component';
import { MatIconRegistry } from '@angular/material/icon';
import { ProfileService } from '../profile/profileService';
import { apiService } from 'src/app/core/api/api-service';

@Component({
  selector: 'app-root',
  styleUrls: ["./entry.point.component.css"],
  templateUrl: './entry.point.component.html',
})

export class EntryPointComponent {
  @HostListener('window:resize', ['$event'])

  onResize(event: any) {
    this.responsive.width = event.target.innerWidth;
  }
  onboarding: OnBoarding;
  env = environment;
  /** La varaible displayToolbar se usa para saber si la ruta que ha visitado el usuario és la de pedir cita des de parte
   * de los clientes. Para Los clientes que no són usuarios de la aplicación, se ocultan diferentes
   * componentes como el 'ToolBar' por ejemplo. */
  displayToolbar = false;
  noToolbarViews: Views[] = []
  ure = UserRoleEnum;
  items = this.storageS.getAllStorage();

  constructor(iconRegistry: MatIconRegistry, public responsive: ResponsiveService, router: Router, private translate: TranslateService,
    private profileS: ProfileService, public roleS: RoleService, private storageS: StorageService, private entrypointS: EntryPointService,
    private apiS: apiService) {


    //iconRegistry.setDefaultFontSetClass('material-symbols-outlined');

    this.responsive.width = window.innerWidth;
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.displayToolbar = this.entrypointS.currentViewNeedToolbar();
      }
    });

    if (feature.logRocket) {
      LogRocket.init('rfqxuq/sinicloud');
    }

    var isPhone = responsive.isPhone();


    /** VO --> TO DO */
    /** ISSUES --> TO DO*/
    this.onboarding = new OnBoarding([])

    /** RA AND APPONITMENTS */
    if (profileS.gestorasesor) {
      this.onboarding.sections.push(
        /** CREATE CLIENT */
        new Section("Crear nueva recepción", "¿Cómo puedo crear una nueva recepción?", [
          new Step({ target: "onboarading-menu", info: "Abre el menú", view: ViewPath.clients, device: "phone" }),
          new Step({ target: "onboarading-menu-clients", info: "Seleccione la sección de 'Clientes'" }),
          new Step({ target: "onboarading-clients-search", info: ["Busca el cliente al que desees efectuar una recepción activa.", "Una vez lo encuentres, lo seleccionas y das clic al botón de 'NUEVA RECEPCIÓN'"] }),
        ], isPhone),

        /** CREATE APPOINTMENT */
        new Section("Crear cita", "¿Cómo puedo crear una cita?", [
          new Step({ target: "onboarading-menu", device: "phone", info: ["Abre el menú"] }),
          new Step({ target: "onboarding-menu-create-appointment", view: ViewPath.crearCita, info: "Ves a la sección de 'Crear cita' del menú" }),
          new Step({ target: "onboarding-create-appointment-siniwin", view: ViewPath.crearCita, info: "Si es un cliente de Siniwin, utiliza esta pestaña para buscarlo" }),
          new Step({ target: "onboarding-create-appointment-transit", view: ViewPath.crearCita, info: "Si es un cliente de tránsito, puedes crear la cita rellenando los datos de esta pestaña", action: false }),
          new Step({ target: "onboarding-create-appointment-hour", view: ViewPath.crearCita, info: "Seleccione el día y la hora" }),
          new Step({ target: "onboarding-create-appointment-reparations", view: ViewPath.crearCita, info: "Añada las reparaciones" }),
          new Step({ target: "onboarding-create-appointment-finish", view: ViewPath.crearCita, info: "¡Todo listo! Ya puede crear la cita", action: false }),
        ], isPhone),

      )
    }

    /** onboarding-workload-appontment-view
     * onboarding-workload-or-view
     */

    /** WORKLOAD APPOINTMENTS*/
    if (feature.workloadOr && !profileS.mecanico) {
      this.onboarding.sections.push(
        new Section("Carga de taller - Citas", "¿Qué es la carga de taller?", [
          new Step({ target: "onboarading-menu", device: "phone", info: ["Abre el menú"] }),
          new Step({ target: this.profileS.mecanico ? "onboarading-menu-dashboard" : "onboarding-workload", 
          view: this.profileS.mecanico ? ViewPath.dashboard : ViewPath.cargaTaller, info: "Ves a la sección de 'carga de taller' del menú" }),
          ... this.profileS.mecanico ? [] :
            [new Step({ target: "onboarding-workload-appontment-view", info: "Seleccione la pestaña de 'Vista citas'", action: true })],
          new Step({ target: "onboarding-workload-week", action: false, info: "Aquí verás las citas que estén programadas para esta semana" }),
          new Step({ target: "onboarding-workload-change-view", action: false, info: "Puedes cambiar la cantidad de días que se muestran por pantalla mediante este desplegable'" }),
          new Step({ target: "onboarding-workload-filters", info: "Si lo necesitas, puedes filtrar las citas", showStepOn: ["bigger", 1004], action: false }),
          new Step({ target: "onboarding-workload-phone-filters", info: "Si lo necesitas, puedes filtrar las citas", showStepOn: ["smaller", 1004], action: false }),
        ], isPhone))
    }

    /** WORKLOAD OR */
    this.onboarding.sections.push(
      new Section("Carga de taller - OR", "¿Qué es la carga de taller?", [
        new Step({ target: "onboarading-menu", device: "phone", info: ["Abre el menú"] }),
        new Step({ target: this.profileS.mecanico ? "onboarading-menu-dashboard" : "onboarding-workload", 
        view: this.profileS.mecanico ? ViewPath.dashboard : ViewPath.cargaTaller, info: "Ves a la sección de 'carga de taller' del menú" }),
        ... this.profileS.mecanico ? [] :
          [new Step({ target: "onboarding-workload-or-view", info: "Seleccione la pestaña de 'Vista de OR'", action: true })],
        new Step({ target: "onboarding-workload-week", action: false, info: this.profileS.mecanico ? "Aquí verás las órdenes de reparación que tienes asignadas para esta semana" : "Aquí verás las órdenes de reparación que estén programadas para esta semana" }),
        new Step({ target: "onboarding-workload-change-view", action: false, info: "Puedes cambiar la cantidad de días que se muestran por pantalla mediante este desplegable'" }),
        ... this.profileS.mecanico ? [] :
          [new Step({ target: "onboarding-workload-workers", info: "Si lo necesitas, puedes filtrar las órdenes de reparación según los operarios", showStepOn: ["bigger", 1004], action: false })],
      ], isPhone))
  }

  toggleMenu(menu: MenuComponent, phonemenu: MenuComponent) {
    this.responsive.isPhone() ? phonemenu.toggle() : menu.toggle();
  }


  initLanguage() {
    // Definir los idiomas disponibles
    const values = Object.values(AppLanguages);
    const availabelLanguages: string[] = [];
    values.forEach((value) => {
      availabelLanguages.push(value);
    });
    this.translate.addLangs(availabelLanguages)

    //Establecer idoma por defecto
    this.translate.setDefaultLang('es');

    //Usar idioma
    this.translate.use('es');
    const browserLang = this.translate.getBrowserLang();
    if (browserLang) {
      this.translate.use(browserLang!.match(this.getRegexExpression(availabelLanguages)) ? browserLang! : 'es');
    }
    else {
      this.translate.use('es');
    }
  }

  getRegexExpression(availabelLanguages: string[]): string {
    let result = "";
    for (let i = 0; i < availabelLanguages.length; i++) {
      result += "/" + availabelLanguages[i] + "|"
    }
    return result;
  }

  setRole(n: UserRoleEnum) {
    this.apiS.changeRole(n).then(res => {
      this.roleS.setRole(n);
      window.location.reload();
    })
  }
}
