import { Component, ViewChild } from '@angular/core';
import { CustomDate, PageStructureComponent, RouterService } from '@sinigual/angular-lib';
import { apiService } from 'src/app/core/api/api-service';
import { M_Rider } from 'src/app/core/models/M_Rider';
import { ViewPath } from 'src/app/modules/app-routing.module';
import { GoCreateReceptionService } from 'src/app/services/go-create-reception.service';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})
export class ClientsComponent {
  @ViewChild(PageStructureComponent, { static: true }) ps!: PageStructureComponent<M_Rider>;
  v = ViewPath;
  constructor(private apiS: apiService, public goNewReception: GoCreateReceptionService, public routerS: RouterService) { }
  ngOnInit(): void {
    this.apiS.clients().then(res => {
      res.sort((a, b) => {
        if (a.updated_at && b.updated_at) {
          return a.updated_at.value > b.updated_at.value ? -1 : 1
        }
        if (a.updated_at == undefined) { return 1 }
        else { return -1 }
      })
      this.ps?.initTable(res);
    })
  }

  goNewAppointment(r: M_Rider) {
    this.routerS.goToWithState(ViewPath.crearCita, r)
  }
}
