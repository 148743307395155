<div class="df jcc container">
    <app-card [contentLoaded]="profileService.clientData != undefined">
        <app-card-title class="tac">TU PERFIL</app-card-title>
        <div class="mb-20">
            <div>
                <lib-file-upload [crop]="true" [maxSize]="8" (stateChanged)="onStateChange($event)"
                    [uploadtype]="'single'"></lib-file-upload>
                <div class="tac">
                    <app-card-subtitle [noMarginBottom]="true">{{profileService.clientData?.name}} <span
                            class="fw300 fss c_t2">{{getRole()}}</span>
                    </app-card-subtitle>
                    <p class="normal c_b">{{profileService.clientData?.email}}</p>
                </div>
            </div>
        </div>
        <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        CAMBIAR CONTRASEÑA
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div>
                    <form (ngSubmit)="changePassword()" [formGroup]="form">
                        <div class="divFormColumn">
                            <app-password-input #pass [form]="form" formCName="pass1"></app-password-input>
                            <app-repeat-passowrd-input style="width: 100%;" #pass2 class_="mt-20" [otherPass]="pass"
                                [form]="form" formCName="pass2"></app-repeat-passowrd-input>
                            <button [disabled]="pass2.getDisabled()" mat-raised-button
                                [ngClass]="pass2.getDisabled()? 'mt-20':'scb mt-20'">
                                MODIFICAR CONTRASEÑA
                            </button>
                        </div>
                    </form>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </app-card>
    <app-card>
        <app-card-title *ngIf="f.workloadOr" class="tac">TUS DÍAS FESTIVOS</app-card-title>
        <p *ngIf="f.workloadOr && roleS.getRole() != role.gestor" class="fwl tac" style="margin-bottom: 20px;">
            <mat-icon class="c_b vam">info</mat-icon>
            Solo el gestor de tu empresa puede establecer tus días festivos.
        </p>
        <app-holidays-calendar *ngIf="f.workloadOr" [selfSave]="true" [prespective]="'user'" [profileView]="true"
            [enabledFor]="enabledCalender" [showUnsaved]="false" [showLegend]="true" [enabledFor]="enabledCalender"></app-holidays-calendar>
    </app-card>
</div>