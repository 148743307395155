<!--<div class="df jcc">
    <div class="button-parent">
        <img *ngFor="let option of cm.getMotorSection()" class=" floating-button" #img
        src="./assets/img/comentarios/{{option.icon}}.png" (error)="img.className='hidden'">
        <button type="button" mat-mini-fab>
            <mat-icon style="pointer-events: none;">add_cricle</mat-icon>
        </button>
    </div>
</div> !-->

<!--<app-test></app-test>
    <mat-icon fontIcon="home" fontSet="material-icons-outlined"></mat-icon>
!-->


<!--
<div class="s1">


  
    <div>
        <p class="dashboardTitle">SINICLOUD</p>

    </div>
    <div class="dividerParent">
        <svg class="customDivider" aria-hidden="true" fill="#f7f6f6" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1000 300" preserveAspectRatio="none">
            <path
                d="M 1000 300 l 1 -230.29 c -217 -12.71 -300.47 129.15 -404 156.29 c -103 27 -174 -30 -257 -29 c -80 1 -130.09 37.07 -214 70 c -61.23 24 -108 15.61 -126 10.61 v 22.39 z">
            </path>
        </svg>
    </div>
</div>-->

<!--
<p *ngIf="profileService.clientData" @appear class="dashboardSubtitle">Bienvenido de nuevo, <span
    class="dashboardTitleSpan">{{profileService.clientData.user_name}}</span></p>
-->

<div *ngIf="profileS.mecanico; else programming">
    <app-core-carga-taller [workloadData]="wd.wd"></app-core-carga-taller>
</div>

<ng-template #programming>
    <app-cloud-tickets *ngIf="role == ure.programming; else main"></app-cloud-tickets>
</ng-template>


<ng-template #main >
    <div class="sectionParent">
        <!--Proximas citas-->
        <div class="section">
            <app-card [contentLoaded]="loaded">
                <app-card-title>PRÓXIMAS CITAS</app-card-title>
                <div *ngIf="proximasCitas.length == 0 && loaded">
                    <p>Parece que no tienes citas los próximos dias</p>
                    <p>Recuerda que siempre puedes <span (click)="routerS.goTo(vp.crearCita)" class="textButton sc">crear
                            una nueva cita</span>.</p>
                </div>
                <div *ngIf="proximasCitas.length != 0">
                    <p style="margin-bottom: 0px;">Estas són sus próximas citas</p>
                    <div class="ultimasrecepciones">
                        <div *ngFor="let cita of proximasCitas">
                            <div @appear class="appointemnt-dashboard">
                                <app-card-line [cardAspect]="false" [letterSpacingTitle]="false"
                                    [title]="cita.fecha_cita.temporalFormat!" icon="calendar_today" titleColor="#2c3342"
                                    mainColor='#008bd0' [iconBig]="false" @appear (click)="goWorkload(cita)">
                                    <client-info-line name="Hora" [value]="cita.fecha_cita.hourFormat!"></client-info-line>
                                    <client-info-line name="Nombre" [value]="cita.nombre_cliente"></client-info-line>
                                    <client-info-line name="Matrícula" [value]="cita.matricula"></client-info-line>
                                </app-card-line>
                            </div>
                        </div>
                    </div>
                </div>
            </app-card>
        </div>
    
        <div class="section">
            <app-card [contentLoaded]="loaded">
                <div>
                    <app-card-title>RECEPCIÓN ACTIVA </app-card-title>
                </div>
                <div *ngIf="recepciones.length == 0 && loaded">
                    <p>Parece que aún no has creado ninguna recepción.</p>
                    <p>Prueba a <span (click)="routerS.goTo(vp.clients)" class="textButton sc">crear una nueva
                            recepción</span>.</p>
                </div>
                <div *ngIf="recepciones.length != 0">
                    <p style="margin-bottom: 0px;">Estas son las últimas recepciones que has efectuado</p>
                    <div class="ultimasrecepciones">
                        <div *ngFor="let recep of recepciones">
                            <div @appear class="ra-dashboard">
                                <app-card-line class="cp" [cardAspect]="false" (click)="this.routerS.goTo(vp.recepcion)"
                                    [letterSpacingTitle]="false" [title]="recep.datetime_or_opening.temporalFormat!"
                                    icon="assignment" titleColor="#2c3342" mainColor='#FE8854' [iconBig]="false">
                                    <client-info-line name="Nombre" [value]="recep.Nombre1"></client-info-line>
                                    <client-info-line name="Matrícula" [value]="recep.license_plate"></client-info-line>
                                </app-card-line>
                            </div>
                        </div>
                    </div>
                </div>
            </app-card>
        </div>
    
        <div class="section">
            <app-card [contentLoaded]="loaded">
                <app-card-title>FAQS</app-card-title>
                <div class="faqsSection">
                    <!--<app-faqs question="¿Dónde puedo modificar el horario de mi empresa?">
                    Puedes acceder a <span class="semi-bold">Configuración</span> mediante el botón de la parte superior derecha de la pantalla.
                </app-faqs> -->
                    <app-faqs question="¿Qué significa estado PENDIENTE en una recepción activa?">
                        Significa que la Recepción Activa se ha guardado correctamente y que esta pendiente de ser importada
                        a
                        través del programa Siniwin en el ordenador. Para recuperar la Recepción dirígete al ordenador.
                    </app-faqs>
                    <app-faqs question="¿Puedo borrar una Recepción Activa con estado Abierta?">
                        Si, pero tan solo se borrara de la pagina web, no en el programa Siniwin.
                    </app-faqs>
    
                    <p class="mt10">Si sigues teniendo dudas, siempre puedes consultar
                        <a class="textButton" [target]="'blank'"
                            href="https://sinicloud.sinigual.com/ZhLXDZe8Q47v6JEDWuEvt0AHli6mHlv6bahtoltbv2E/Guia citas y recepción activa.pdf">
                            nuestra guía
                        </a>
                        en formato PDF.
                    </p>
                </div>
            </app-card>
        </div>
        <div class="section"></div>
    </div>
</ng-template>

