import { apiService } from "../api/api-service";
import { PlateSearchType } from "../enums/PlateSearchType";
  
/**
 * [POST]
 * Esta classe representa el body de la petición {@link apiService.showRaider()}
 */
export class M_BuscadorMatricula{
    searched: string;
    requestName : string = "";
    searchType : PlateSearchType = PlateSearchType.matricula;

    constructor(searched : string){
        this.searched = searched;
        if (searched.includes("@")) { // Si contiene una @
            this.requestName = "email"
            this.searchType = PlateSearchType.email;
        }
        else {
            if (/\d/.test(searched)){ //Contiene números
                if (/^-?\d+$/.test(searched)){ //Són todo numeros
                    this.requestName = "phone"
                    this.searchType = PlateSearchType.telefono;
                }
                else { // Números y letras
                    if (this.countChar(searched) == 1) {  // Si sólo contiene una letra
                        this.requestName = "nif"
                        this.searchType = PlateSearchType.dni;
                    }
                    else {
                        this.requestName = "license_plate"
                        this.searchType = PlateSearchType.matricula;
                    }
                }
            }
            else { //Si no contiene números
                this.requestName = "name"
                this.searchType = PlateSearchType.nombre;
            }
        }
    }

    countChar(str : string) {
        let numbers =  str.replace(/[^0-9]/g, '').length;
        return str.length - numbers;
    }
    
    getBody(){
        let body : any = {}
        body[this.requestName] = this.searched;
        return body;
    }

    isMatricula(){
        return this.searchType == PlateSearchType.matricula;
    }

    isPhone(){
        return this.searchType == PlateSearchType.telefono;
    }

    isEmail(){
        return this.searchType == PlateSearchType.email;
    }
        
}