

import {NestedTreeControl} from '@angular/cdk/tree';
import {Component, ViewChild} from '@angular/core';
import { MatButton } from '@angular/material/button';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import { Comentario, CommentManager } from '@sinigual/angular-lib';


interface ComentarioNode {
  text: string;
  children?: Comentario[];
}
const TREE_DATA: ComentarioNode[] = [
  {
    text: 'Revisión periódica',
    children: CommentManager.getPeriodicReivisonSection(),
  },
  {
    text: 'Revisión',
    children: CommentManager.getRevisionSection(),
  }
  ,
  {
    text: 'Cambio',
    children: CommentManager.getChangeSection(),
  }
  ,
  {
    text: 'Reglaje',
    children: CommentManager.getAdjustmentSection(),
  }
  ,
  {
    text: 'Otros',
    children: CommentManager.getOthersSection(),
  }
];

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css'],
})
export class TestComponent {
  treeControl = new NestedTreeControl<ComentarioNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<ComentarioNode>();
  hasChild = (_: number, node: ComentarioNode) => !!node.children && node.children.length > 0;

  constructor() {
    this.dataSource.data = TREE_DATA;
  }

  get selecteds() {
    return []
  }

  getBadge(node : ComentarioNode){
    var count : number | null = null;
    node.children?.forEach(ch => {
      if (ch.selected){
        if (count == null) { count = 1;}
        else {count++;}
      }
    })
    return count;
  }

}
