<app-close-dialog-button></app-close-dialog-button>

<app-card-title>HISTORIAL</app-card-title>

<mat-dialog-content>
    <div *ngFor="let t of data.or.timers" class="timers-list">


        <ng-container *ngIf="t.custom">
            <div class="df aic">
                <!--<mat-icon class="starting-icon manually-added" [matTooltip]="'Añadido manualmente'">add_circle</mat-icon>!-->
                <app-circular-letter class="mr5" [showFullName]="false" [small]="true" [user]="getUserByTime(t)"></app-circular-letter>
                <div> {{t.start_time.dayMonthYearFormat}}</div>
            </div>
            <div class="c_t2">Manual</div>
        </ng-container>

        <!-- SAME DAY START AND END-->
        <ng-container *ngIf="t.end_time && t.start_time.isEquals(t.end_time) && !t.custom">
            <div class="df aic">
                <app-circular-letter class="mr5" [showFullName]="false" [small]="true"
                    [user]="getUserByTime(t)"></app-circular-letter>
                <div> {{t.start_time.dayMonthYearFormat}}</div>
            </div>
            <div class="c_t2">{{t.start_time.clockFormat + " - " + t.end_time.clockFormat}}</div>
        </ng-container>

        <!-- DIFFERENT DAYS-->
        <ng-container *ngIf="t.end_time && !t.start_time.isEquals(t.end_time) && !t.custom">
            <div class="df aic">
                <mat-icon class="starting-icon error-icon"
                    [matTooltip]="'El fichaje transcurre en distintos días'">error</mat-icon>
                <app-circular-letter class="mr5" [showFullName]="false" [small]="true"
                    [user]="getUserByTime(t)"></app-circular-letter>
                <div> {{t.start_time.shortFormat}}</div>
            </div>
            <div>{{t.end_time.shortFormat}}</div>
        </ng-container>

        <!-- USER IS ACTUALLY WORKING -->
        <ng-container *ngIf="!t.end_time && !t.custom">
            <div class="df aic">
                <app-circular-letter class="mr5" [showFullName]="false" [small]="true"
                    [user]="getUserByTime(t)"></app-circular-letter>
                <div>{{t.start_time.dayMonthYearFormat}}</div>
            </div>
            <div class="c_t2">{{t.start_time.clockFormat + " - " + '??'}}</div>
        </ng-container>

        <!------------------------------------------------------------------------->

        <!-- ELAPSED TIME AND REMOVE BUTTON IF USER HAS PERMISSIONS-->
        <div class="pr0 df aic">
            <div class="c_p mr10">{{t.elapsed.format}}</div>
            <button mat-icon-button (click)="deleteTimeLine(t.id)" *ngIf="canDelete">
                <mat-icon class="c_r">remove</mat-icon>
            </button>
        </div>

    </div>

    <p *ngIf="data.or.timers.length == 0" class="c_t2 fsm">Nada que mostrar</p>

</mat-dialog-content>

<mat-dialog-actions *ngIf="data.or.timers.length != 0">
    <div class="timer-total">
        <p><span class="c_t fw500">Total: </span>{{getTotalTime().format}}</p>
    </div>
</mat-dialog-actions>