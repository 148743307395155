<app-view-title-lord-icon class="df jcc" [lordIcon]="li" [pageTitle]="'GESTIÓN DE ANUNCIOS'"></app-view-title-lord-icon>
<ng-template #li>
    <script src="https://cdn.lordicon.com/qjzruarw.js"></script>
    <lord-icon src="https://cdn.lordicon.com/nwplhoun.json" trigger="loop" delay="9999" style="width:60px;height:60px">
    </lord-icon>
</ng-template>
<div class="df wr search-prov jcc aie aic">
    <div>
        <div class="df aic">
            <mat-form-field appearance="outline">
                <mat-label>Buscar stock o anuncio</mat-label>
                <input (keyup)="applyFilter(searchBtn.value)" #searchBtn matInput
                    placeholder="Introduce el anuncio a buscar">
                <button class="hover" (click)="applyFilter(searchBtn.value)" mat-icon-button matPrefix>
                    <mat-icon>search</mat-icon>
                </button>
                <button class="hover" mat-icon-button matSuffix (click)="changeDisplayMode($event)">
                    <mat-icon>{{displayMode == displayEnum.row? 'grid_view' : 'splitscreen'}}</mat-icon>
                </button>
            </mat-form-field>
            <app-filters class="circle-responsive" [filters]="filters" (onfiltersDone)="filterVO($event)"></app-filters>
        </div>

    </div>
    <ng-template #skeleton>
        <div class="df fdc jcc">
            <app-skeleton [width]="45" [height]="45"></app-skeleton>
            <div class="mt10"></div>
            <app-skeleton [width]="80" [height]="22"></app-skeleton>
        </div>
    </ng-template>
    <div class="" *ngIf="loaded; else skeleton">
        <div class="df jcc pointer" (click)="goConfig()">
            <div *ngFor="let provider of companyProviders; let last = last">
                <img [matTooltip]="provider.name" class="providersLogo" [src]="provider.image">
            </div>
            <div *ngIf="companyProviders.length == 0 && profileS.gestor">
                <button mat-button class="bc_p c_w">
                    OBTENER PROVEEDORES
                    <mat-icon class="c_w">chevron_right</mat-icon>
                </button>
            </div>
        </div>
        <div class="df jcc">
            <p class="lighter nmb">En stock : <span class="semi-bold sc larger">{{adds.length}}</span></p>
        </div>
    </div>
</div>
<div *ngIf="displayed_add_array.length != 0; else noData">
    <div [ngClass]="{'df wrap jcc gridP' : displayMode == displayEnum.grid}">
        <div *ngFor="let add of displayed_add_array">
            <app-add-card (onRemove)="onRemove($event)" [bike]="add" [displayMode]="displayMode"></app-add-card>
        </div>
    </div>
</div>

<ng-template #noData>
    <div class="df wrap jcc gridP" *ngIf="!loaded">
        <app-skeleton style="margin: 20px; border-radius: 10px;" [width]="300" [height]="450"></app-skeleton>
        <app-skeleton style="margin: 20px; border-radius: 10px;" [width]="300" [height]="450"></app-skeleton>
        <app-skeleton style="margin: 20px; border-radius: 10px;" [width]="300" [height]="450"></app-skeleton>
    </div>
    <div *ngIf="loaded">
        <p class="tac fw400 fsxl" style="margin-top: 25px;">Todavía no has subido ningún vehículo</p>
        <p *ngIf="adds.length == 0; else noDataSearchedFilters" class="tac fw400 fsl" style="margin-top: 20px">
            ¿No sabes como hacerlo?
            Hecha un vistazo a este vídeo :
        </p>
        <video height="auto" controls class="mat-elevation-z4 mt10" onclick="this.play()">
            <source type="video/mp4" src="./assets/video/appmoto.mp4">
        </video>
        <p class="tac fw400 fsi mt10">(Recuerda que debes tener la <span class="c_p fw500">versión 23.04</span> de Siniwn)</p>

        <ng-template #noDataSearchedFilters>
            <p class="noResults c_t2">No se han encontrado resultados para los filtros seleccionados</p>
        </ng-template>
    </div>
</ng-template>