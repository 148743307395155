<div class="df aic fdc">
    <mat-progress-bar *ngIf="loading" class="all-c-width" [mode]="'indeterminate'"></mat-progress-bar>

    <!-- MAIN CALENDAR-->
    <mat-calendar [dateFilter]="getDisabledDays" class="all-c-width mat-elevation-z2" [ngClass]="{'calendar-disabled' : !enabled || loading}" #datePicker [dateClass]="dateClass"
        (selectedChange)="dateChanged($event)"></mat-calendar>

    <!-- BOTTOM CALENDAR CONTENTS-->
    <div class="bottom-contents all-c-width mat-elevation-z2">
        <div class="df fdc aic w100">

            <!-- LEGEND -->
            <div class="legend" *ngIf="showLegend" #header>
                <p><mat-icon class="c_p">fiber_manual_record</mat-icon> {{profileView ? 'Tus festivos ' : 'Festivos usuario'}} </p>
                <p><mat-icon class="c_r">fiber_manual_record</mat-icon> Festivo empresa </p>
            </div>

            <!-- UNSAVED CHANGES-->
            <span *ngIf="holidayChanges && showUnsaved" class="fss c_r fw500 mt5">Cambios sin guardar</span>

            <div class="df fww jcc aic">
                <!-- HOLIDAYS BREAKDOWN-->
                <div *ngFor="let dayByGranularity of holidayBreakdown">
                    <div  class="breakdown-month" (click)="goToMonth(dayByGranularity.splitDate)" [ngClass]="{'current-month mat-elevation-z1': isCurrentMonth(dayByGranularity.splitDate)}" mat-ripple>
                        <!-- Ex : Enero (2023)-->
                        <div class="breakdown-month-title">
                            {{dayByGranularity.splitDate.getMonthName(true)}}
                            <span class="breakdown-month-year">{{"("+dayByGranularity.splitDate.getYear(true) + ")"}}</span>
                        </div>
                        <!-- Ex : 2 días festivos-->
                        <div class="total-days-container">
                            <span class="semi-bold">{{dayByGranularity.data.length}}</span>
                            <span>{{dayByGranularity.data.length >= 2? ' días' : ' día'}}</span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- SELF SAVE BUTTON -->
            <button *ngIf="selfSave && enabled" mat-flat-button [disabled]="!holidayChanges" class="save-button" color="primary" (click)="save()">
                GUARDAR
            </button>

        </div>
    </div>
</div>