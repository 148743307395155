<mat-toolbar class="toolbar" color="primary">
  <button #btn (click)="onclick.emit();" onclick="this.blur()" mat-icon-button class="df aic cp br5"
    [ngClass]="{'onboarading-menu' : mc.hiddenToUser}">
    <mat-icon>{{mc.lockmenu && mc.isDesktop ? 'menu_open' : 'menu'}}</mat-icon>
  </button>
  <img (click)="routerS.goTo(vp.dashboard)" class="logoToolbar cp" src="./assets/img/sinigual.png">

  <span class="example-spacer"></span>

  <!-- CURRENTLY WORKING OR-->
  <div class="df aic" [ngClass]="timerS.refOr != undefined ? 'or-time-appear' : 'or-time-disappear'">
    <div class="timer-container" *ngIf="timerS.refOr" (click)="timerS.goOr(timerS.refOr)" mat-ripple>
      <p class="first-text"><span class="or-prefix">OR</span> {{timerS.refOr.id_to_show}} </p>
      <p class="elapsed-time cp nmb">{{timerS.elapsed}}</p>
    </div>
    <p class="more-or-text" *ngIf="timerS.hasMore" [matMenuTriggerFor]="otherOr">{{timerS.moreNumber}} más</p>
  </div>

  <mat-menu #otherOr="matMenu">
      <div  *ngFor="let or of timerS.moreOr" class="timer-container" style="margin: 5px;" (click)="timerS.goOr(or)">
        <p class="first-text"><span class="or-prefix">OR</span>  {{or.id_to_show}} </p>
        <p class="elapsed-time cp nmb">{{timerS.orElapsed(or)}}</p>
      </div>
  </mat-menu>

  <span class="example-spacer"></span>

  <button mat-icon-button (click)="onboarding.open();" onclick="this.blur()" *ngIf="showOnboarding">
    <mat-icon>help_outlined</mat-icon>
  </button>
  <div *ngIf="showNotifications">
    <app-notifications [class_]="'mr-20'" [getAll]="apiS.getNotifications.bind(apiS)" [autoUpdate]="false"
      [readAll]="apiS.readAllNotifications.bind(apiS)" [setReaded]="apiS.setNotificationReaded.bind(apiS)"
      (onClickNotification)="mc.close(); notificationAction($event)">
    </app-notifications>
  </div>

  <app-circular-letter class="topProfileImage pointer round50" *ngIf="profileService.clientData; else loadingprofile"
    [user]="profileService.clientData" [matMenuTriggerFor]="menu" [showFullName]="false"
    [showTooltip]="false"></app-circular-letter>

  <ng-template #loadingprofile>
    <app-skeleton [circle]="true" [width]="40" [height]="40"></app-skeleton>
  </ng-template>

  <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="profileService.clientData">
    <mat-icon>arrow_drop_down</mat-icon>
  </button>

  <mat-menu #menu="matMenu">
    <app-menu-item [mc]="mc" [matmenu]="true" [data]="perfil"></app-menu-item>
    <app-menu-item [mc]="mc" [matmenu]="true" [data]="configuracion"></app-menu-item>
    <app-menu-item [mc]="mc" [matmenu]="true" [data]="cerrarSession"></app-menu-item>
  </mat-menu>

</mat-toolbar>