import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { BurgerMultipleOption, BurgerOption, ResponsiveService, RoleService, RouterService, Views } from '@sinigual/angular-lib';
import { UserRoleEnum } from 'src/app/core/enums/UserRoleEnum';
import { feature } from 'src/app/core/features-controller/FeaturesController';
import { ViewPath } from 'src/app/modules/app-routing.module';
import { ProfileService } from 'src/app/views/profile/profileService';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent {

  @Input() isDesktop = true;
  @Input() drawer?: MatDrawer;
  @ViewChild('menu') menu!: ElementRef<HTMLDivElement>;
  opened = false;
  enter = false;
  exit = false;
  lockmenu = false;
  /**Entorno de desarrollo actual */
  featrue = feature;

  /**Historial de recepciones */
  historialrecepcion: BurgerOption;


  crearrecepcion: BurgerOption;

  /** Clients */
  clients: BurgerOption;

  /**Usuarios */
  users: BurgerOption;

  /** Articles */
  articles: BurgerOption;

  /** Create appointment */
  createAppointment: BurgerOption;

  /** Workload */
  workload: BurgerOption;

  /** Dashboard */
  dashboard: BurgerOption;

  /** Lanzadera de anuncios */
  lanzadera: BurgerOption;

  /** Cloud Tickets */
  issues: BurgerOption;

  /** Coming soon */
  comingsoon: BurgerOption;

  /** Proyectar citas */
  listacitas: BurgerOption;

  constructor(private routerS: RouterService, private responsiveS: ResponsiveService, public profileS: ProfileService) {
    this.dashboard = new BurgerOption(profileS.mecanico ? "Carga de taller" : "Dashboard", "dashboard", ViewPath.dashboard, undefined, undefined, "onboarading-menu-dashboard");
    this.historialrecepcion = new BurgerOption("Historial recepciones", "assignment", ViewPath.recepcion, undefined, undefined, "onboarading-menu-historial-recepcion");

    this.listacitas = new BurgerOption("Proyectar citas", "desktop_windows", ViewPath.listacitas);
    this.crearrecepcion = new BurgerOption("Crear recepción", "assignment_add", ViewPath.crearRecepcion, undefined, undefined, "onboarding-menu-create-reception");
    this.createAppointment = new BurgerOption("Crear cita", "calendar_add_on", ViewPath.crearCita, undefined, undefined, "onboarding-menu-create-appointment");
    this.clients = new BurgerOption("Clientes", "sports_motorsports", ViewPath.clients, undefined, undefined, "onboarading-menu-clients")

    this.users = new BurgerOption("Usuarios", "people", ViewPath.users, undefined, undefined, "onboarading-menu-users")
    this.articles = new BurgerOption("Artículos", "widgets", ViewPath.articles, undefined, undefined, "onboarading-menu-articles")

    this.lanzadera = new BurgerOption("Gestión de anuncios", "share", ViewPath.stock)
    this.workload = new BurgerOption(feature.workloadOr ? "Carga de taller" : "Gestión de citas", "date_range", ViewPath.cargaTaller, [ViewPath.workOr], undefined, "onboarding-workload")
    this.issues = new BurgerOption("Incidencias", "support_agent", ViewPath.incidencias)

    /** Coming soon */
    this.comingsoon = new BurgerOption("Gestión de anuncios", "campaign", ViewPath.comingsoon)
  }

  goTo(v: Views) {
    if (this.isDesktop) {
      if (!this.lockmenu) {
        this.opened = false;
      }
    }
    else {
      if (this.drawer) {
        this.drawer.close()
        this.opened = false;
      }
    }
    this.routerS.goTo(v);
  }

  ngOnInit(): void { }

  onMouseEnterComponent() {
    if (this.isDesktop) {
      if (!this.lockmenu) {
        this.enter = true;
        this.exit = false;
        setTimeout(() => {
          if (!this.exit) {
            if (this.isDesktop) {
              this.open();
            }
          }
        }, 350);
      }
    }
  }

  get hiddenToUser() {
    return this.responsiveS.isPhone();
  }

  onMouseLeaveComponent() {
    if (this.isDesktop) {
      if (!this.lockmenu) {
        this.exit = true;
        this.enter = false;
        if (this.isDesktop) {
          this.close();
        }
      }
    }
  }

  open() {
    this.opened = true;
  }

  close() {
    this.opened = false;
    if (!this.drawer) {
      this.lockmenu = false;
    }
  }

  toggle() {
    if (this.drawer) {
      if (this.drawer.opened) {
        this.drawer.close();
        this.opened = false;
      }
      else {
        this.drawer.open();
        this.opened = true;
      }
    }
    else {
      this.lockmenu = !this.lockmenu;
      this.opened = !this.opened;
    }
  }

}
