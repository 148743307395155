import { CustomDate, M_BaseNotification, M_CloudTicket, M_TicketNotificacion, NotificationInterface, NotificationType } from "@sinigual/angular-lib";
import { M_OR } from "./M_OR";
import { M_Cita } from "./M_Cita";

export class NotificationCreator {
    createNotificationByResponse(d: any): NotificationInterface[] {
        var notifications: NotificationInterface[] = [];
        for (let i = 0; i < d.length; i++) {
            if (this.isCita(d[i])) {
                notifications.push(new M_CitaNotificacion(d[i]));
            }
            else if (this.isHolidays(d[i])) {
                notifications.push(new M_HolidayNotification(d[i]));
            }
            else if (this.isOr(d[i])) {
                notifications.push(new M_OrNotification(d[i]));
            }
            else if (this.isMassive(d[i])) {
                notifications.push(new M_MassiveNotification(d[i]));
            }
            else {
                var ct = new M_CloudTicket(d[i]);
                ct.events.forEach(event => {
                    notifications.push(new M_TicketNotificacion(ct, event));
                });
            }
        }
        notifications.sort((a, b) => a.time.value > b.time.value ? -1 : 1);
        return notifications;
    }

    private isCita(d: any): boolean {
        if (!d.notificacion?.type) { return false }
        var number = Number(d.notificacion.type);
        return number == NotificationType.CITACREADA || number == NotificationType.CITAMODIFICADA || number == NotificationType.CITAELIMINADA || number == NotificationType.CITAFINALIZADA;
    }

    private isHolidays(d: any) {
        if (!d.notificacion?.type) { return false }
        var number = Number(d.notificacion.type);
        return number == NotificationType.HOLIDAYS;
    }

    private isOr(d: any) {
        if (!d.notificacion?.type) { return false }
        var number = Number(d.notificacion.type);
        return number == NotificationType.ORASSIGN || number == NotificationType.ORCHANGE || number == NotificationType.ORFINISH;
    }

    private isMassive(d: any) {
        if (!d.notificacion?.type) { return false }
        var number = Number(d.notificacion.type);
        return number == NotificationType.MASSIVE_MATRICULAS || number == NotificationType.MASSIVE_STOCKVO || number == NotificationType.MASSIVE_OR ||
            number == NotificationType.MASSIVE_OPERARIOS || number == NotificationType.MASSIVE_ARTICULOS;
    }
}

export class M_HolidayNotification extends M_BaseNotification {
    constructor(data: any) {
        super(data);
        this.icon = "insert_invitation"
        this.title = "Modificación en tus vacaciones"
        this.text = "Se han realizado cambios en tus vacaciones."
    }
}

export class M_MassiveNotification extends M_BaseNotification {
    constructor(data: any) {
        super(data);
        this.icon = "backup"
        this.generatrNotificationsByTipo();
    }

    generatrNotificationsByTipo() {
        switch (this.type) {
            case (NotificationType.MASSIVE_MATRICULAS): {
                this.title = "Subida massiva de matrículas"
                this.text = "Se ha realizado la subida massiva de matrículas."
                break;
            }
            case (NotificationType.MASSIVE_STOCKVO): {
                this.title = "Subida massiva de Stock VO"
                this.text = "Se ha realizado la subida massiva de Stock VO."
                break;
            }
            case (NotificationType.MASSIVE_OR): {
                this.title = "Subida massiva de OR"
                this.text = "Se ha realizado la subida massiva de OR."
                break;
            }
            case (NotificationType.MASSIVE_OPERARIOS): {
                this.title = "Subida massiva de operarios"
                this.text = "Se ha realizado la subida massiva de operarios."
                break;
            }
            case (NotificationType.MASSIVE_ARTICULOS): {
                this.title = "Subida massiva de artículos"
                this.text = "Se ha realizado la subida massiva de artículos."
                break;
            }
        }
    }
}

export class M_OrNotification extends M_BaseNotification {
    or: M_OR | undefined;

    constructor(data: any) {
        super(data);
        this.icon = "build"
        this.or = new M_OR(data.or);
        this.generatrNotificationsByTipo();
    }

    generatrNotificationsByTipo() {
        switch (this.type) {
            case (NotificationType.ORCHANGE): {
                this.title = "Fecha de entrega modificada"
                this.text = "La OR " + this.or?.NumOr + " se ha cambiado de día."
                break;
            }
            case (NotificationType.ORASSIGN): {
                this.title = "Te han asignado una OR"
                this.text = "Tienes asignada la OR " + this.or?.NumOr + "."
                break;
            }
            case (NotificationType.ORFINISH): {
                this.title = "OR finalizada"
                this.text = "Se ha finalizado la OR " + this.or?.NumOr + "."
                break;
            }
        }
    }
}

export class M_CitaNotificacion extends M_BaseNotification {
    cita: M_Cita | undefined;
    constructor(data: any) {
        super(data);
        this.icon = "calendar_today";
        this.generatrNotificationsByTipo(data);
    }
    generatrNotificationsByTipo(data: any) {
        console.log("Cita notification")
        switch (this.type) {
            case (NotificationType.CITACREADA): {
                this.cita = new M_Cita(data.cita);
                this.title = "Cita creada";
                this.text = "Se ha creado una cita con la matrícula " + this.cita?.matricula + " para el día " + this.cita?.fecha_cita?.shortFormat;
                break;
            }
            case (NotificationType.CITAMODIFICADA): {
                this.cita = new M_Cita(data.cita);
                this.title = "Cita modificada";
                this.text = "La cita con la matrícula '" + this.cita?.matricula + "' se ha modificado para el " + this.cita?.fecha_cita?.shortFormat;
                break;
            }
            case (NotificationType.CITAELIMINADA): {
                this.cita = new M_Cita(data.cita);
                this.title = "Cita eliminada";
                this.text = "Se ha eliminado la cita con la matrícula '" + this.cita?.matricula + "." + " Estaba programada para el " + this.cita?.fecha_cita?.shortFormat;
                break;
            }
            case (NotificationType.CITAFINALIZADA): {
                this.cita = new M_Cita(data.cita);
                this.title = "Cita finalizada";
                this.text = "Se ha finalizado la cita con la matrícula " + this.cita?.matricula + " para el día " + this.cita?.fecha_cita?.shortFormat;
                break;
            }
        }
    }
}