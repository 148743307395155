
import { generateImageURL, I_ClassSearcher, M_BaseUser, M_Parser, match, parseOBJ, SearchFiltrable } from "@sinigual/angular-lib";
import { environment } from "src/environments/environment";
import { UserRole } from "../custom-classes/UserRole";
import { ActivationStatus } from "../enums/ActivationStatus";
import { UserRoleEnum } from "../enums/UserRoleEnum";
import { generateHoliday } from "src/app/functionUtils/functions";
import { endpoints } from "../api/Endpoints";

export class M_User extends M_BaseUser implements M_Parser, SearchFiltrable, I_ClassSearcher<M_User> {
    client_code?: string;
    company_code?: string;
    role: UserRole;
    operario_id: number | undefined;
    estado_activacion: null | ActivationStatus;
    needsToBeParsed: boolean = true;
    online = false;

    //name: string, email: string, role: UserRoleEnum, image: string, client_code?: string, company_code?: string, id?: number;
    constructor(d: any) {
        super(d);
        if (d.lient_code) {
            this.client_code = d.client_code;
        }
        if (d.company_code) {
            this.company_code = d.company_code;
        }

        this.operario_id = d.operario_id;

        this.holidays = generateHoliday(d.holidays, "user");

        this.online = d.sesion_activa;

        var act = d.estado_activacion;
        this.estado_activacion = act == 0 ? ActivationStatus.toActivate : act == 1 ? ActivationStatus.sended : act == 2 ? ActivationStatus.activated :  ActivationStatus.activated;
        this.role = d.role ? new UserRole(d.role) : new UserRole(UserRoleEnum.visitante);
        this.img = generateImageURL(environment, d.image ? d.image : d.pict ? d.pict : "");
    }

    defaultSearchFilter(text: string): boolean {
        return match(text, this.name, this.email, this.client_code, this.company_code)
    }

    parse(): any {
        let obj: any = parseOBJ(this);
        obj['role'] = this.role.role;
        return obj;
    }

    getRandomInt(max: number) {
        return Math.floor(Math.random() * max);
    }

    get isActivated() {
        return this.estado_activacion == ActivationStatus.activated;
    }


    /** I_ClassSearcher IMplementation */

    get endpoint() { return endpoints.usersWorkers }
    get cs_id() { return this.id };
    get icon() { return "person" }
    get typeof() { return M_User };

    createNew(d: any): M_User {
        return new M_User(d);
    }

    getInputText(): string {
        return this.name;
    }

    getOptionText(): [string, string] {
        return [this.name, this.role.name];
    }

}