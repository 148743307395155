import { Component, Input, QueryList} from '@angular/core';
import { AbstractControl, FormControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatOption, MatOptionSelectionChange } from '@angular/material/core';
import { apiService } from 'src/app/core/api/api-service';
import { M_Rider } from 'src/app/core/models/M_Rider';

@Component({
  selector: 'app-client-searcher',
  templateUrl: './client-searcher.component.html',
  styleUrls: ['./client-searcher.component.css']
})
export class ClientSearcherComponent {

  searchOnChars: number = 3;
  loaded: boolean = false;
  riders: M_Rider[] = [];
  riderOptions: M_Rider[] = [];
  riderSelected: boolean = false;
  rider: M_Rider | undefined;
  valueOnLoad : M_Rider | MatOptionSelectionChange<M_Rider> | string | undefined;
  fc: FormControl<string | null> = new FormControl("");

  /** Parent form */
  @Input() form?: UntypedFormGroup;

  /** Plate attribute*/
  @Input() formCName?: string;

  constructor(private apiS: apiService) {
    this.apiS.clients().then(res => {
      this.riders = res;
      this.loaded = true;
      if (this.valueOnLoad){
        this.setValue(this.valueOnLoad);
      }
      else if (this.fc.value){
        this.refreshOptions(this.fc.value);
      }
    })

    this.fc.valueChanges.subscribe(v => {
      if (v != null) {
        if (v.length >= this.searchOnChars) {
          this.refreshOptions(v);
        }
        else {
          this.riderOptions = [];
        }
      }
    })

    this.control?.addValidators(this.customValidator())
    this.fc.addValidators(this.customValidator())
  }

  setValue(r: M_Rider | MatOptionSelectionChange<M_Rider> | string) {
    if (!this.loaded){
      this.valueOnLoad = r;
      this.fc.disable();
    }
    else{
      var rider = r instanceof M_Rider ? r : typeof r == "string" ? this.getRiderByPlate(r) : r.source.value;
      if (rider) {
        this.rider = rider;
        this.control?.setValue(rider.Matricula);
        this.riderOptions = [];
        this.riderSelected = true;
        this.fc.disable();
        this.fc.setValue(rider.Matricula)
        this.fc.updateValueAndValidity();
        this.control?.updateValueAndValidity();
        this.valueOnLoad = undefined;
      }
      else {
        this.fc.enable();
      }
    }
  }

  getRiderByPlate(value: string) {
    var filtered = this.riders.filter(r => r.Matricula == value);
    if (filtered.length > 0) {
      return filtered[0];
    }
    return undefined;
  }

  removeValue() {
    this.control?.setValue(undefined);
    this.fc.setValue(null);
    this.riderOptions = [];
    this.fc.enable();
    this.riderSelected = false;
    this.rider = undefined;
    this.fc.updateValueAndValidity();
    this.control?.updateValueAndValidity();
  }

  get control() {
    if (this.form && this.formCName) {
      return this.form?.get(this.formCName);
    }
    return undefined;
  }

  refreshOptions(v: string) {
    this.riderOptions = [];
    this.riders.forEach(r => {
      if (r.defaultSearchFilter(v)) {
        this.riderOptions.push(r);
      }
    })
  }

  onFocusOut(options: QueryList<MatOption<M_Rider>>) {
    if (options.length == 1) {
      var option = options.get(0)!;
      if (!option.disabled) {
        this.setValue(option.value)
      }
    }
  }

  customValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const hasError = this.rider == undefined;
      return hasError ? { required: { value: control.value } } : null;
    };
  }

}
