<app-close-dialog-button></app-close-dialog-button>
<div class="mt10"></div>
<app-card-title>IMPUTAR TIEMPO MANUALMENTE</app-card-title>

<div>
    <app-card-subtitle>Seleccione un usuario con el que imputar el tiempo</app-card-subtitle>
    <p class="c_t2" *ngIf="!profileS.isWorker">Solo los usuarios de Siniwin pueden imputar tiempo</p>
</div>

<p class="c_t2" *ngIf="data.workers.length == 0">Tu compañía no tiene operarios activados</p>

<div class="first-row">
    <div class="df fww mt10 mb10">
        <app-class-searcher #workerSearcher [width100]="true" [masterClass]="usermaster"
            searchPlaceHolder="Buscar operario" [noSelectionPlaceHolder]="'Ningún operario seleccionado'">
        </app-class-searcher>
    </div>

    <mat-form-field appearance="outline">
        <mat-label>Tiempo</mat-label>
        <input [required]="true" type="time" matInput [formControl]="timeFc">
        <!--<button mat-icon-button matSuffix><mat-icon>schedule</mat-icon></button>!-->
    </mat-form-field>
</div>

<mat-form-field appearance="outline" class="w100">
    <mat-label>Elige un día</mat-label>
    <input [required]="true" #pickerInput matInput [matDatepicker]="picker" (click)="picker.open()" required
        [formControl]="dateFc">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
</mat-form-field>



<div class="df jcc">
    <button mat-button class="c_b" [disabled]="!isAddTimeEnabled" (click)="closeWithTime()">
        Añadir tiempo
    </button>
</div>