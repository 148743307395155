import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CreateTicketDialog, M_CloudTicket, ReportedProblem } from '@sinigual/angular-lib';
import { apiService } from 'src/app/core/api/api-service';
import { ViewPath } from 'src/app/modules/app-routing.module';

@Component({
  selector: 'app-cloud-tickets',
  templateUrl: './cloud-tickets.component.html',
  styleUrls: ['./cloud-tickets.component.css', '../../css/table.css']
})
export class CloudTicketsComponent {

  fc = new FormControl('');
  tickets: M_CloudTicket[] = [];
  filteredTickets: M_CloudTicket[] = [];
  loaded = false;

  constructor(private apiS: apiService, private d: MatDialog) {
    this.apiS.getCloudTickets().then(res => {
      this.tickets = res.sort((a, b) => a.created_at!.value > b.created_at!.value ? -1 : 1);
      this.filteredTickets = res;
      this.loaded = true;
    });
  }

  createTicket() {
    this.d.open(CreateTicketDialog, { data: { view: ViewPath.incidencias, showGoIssues: false }, autoFocus: false }).afterClosed().subscribe(res => {
      if (res instanceof ReportedProblem) {
        this.apiS.createCloudTicket(res).then(res => {
          this.tickets.unshift(res);
          this.filteredTickets = this.tickets;
          this.fc.setValue('');
        })
      }
    });
  }

  searchTicket() {
    this.filteredTickets = [];
    this.tickets.forEach(t => {
      if (t.defaultSearchFilter(this.fc.value? this.fc.value : '')) {
        this.filteredTickets.push(t);
      }
    })
  }

  onAddComment(event: [M_CloudTicket, string]) {
    this.apiS.addComment(event[0].id, event[1]).then(res => {})
  }

}
