
/* Calls definition*/
export enum Calls {
    login =                     "/api/custom/login",
    estadoTaller =              "/api/dashboard_or/filter/",
    clients =                   "/api/show/matriculas",
    cargaTaller =               "/api/workshopload/index ",
    showPreorder =              "/api/show/preorder",
    showRider =                 "/api/rider/plate",
    orderCreate =               "/api/order/create2",
    changePicture =             "/api/update/profile",
    showProfile =               "/api/user/profile",
    deleteOr =                  "/api/order/delete",
    createCitaCliente =         "/api/create/rider/appointment",
    showCitaCliente =           "/api/show/appointment/",
    editarCitaCliente =         "/api/show/appointment/update",
    createCitaMecanico=         "/api/create/appointment",
    companyInfo =               "/api/show/company",
    setCompanyInfo =            "/api/show/company/update",
    setCompanyImage =           "/api/update/company/logo",
    getCitaByDay =              "/api/show/appointment/day",
    getBookedHours =            "/api/show/appointment/booked",
    getNotifications =          "/api/show/notification",
    updateNotifications =       "/api/show/update/notification",
    readAllNotifications =      "/api/show/update/all/notification",
    deleteAllNotifications =    "/api/delete/all/notification",
    getAppointmentByDateRange = "/api/show/appointment/rank",
    getNextCitas =              "/api/show/appointment",
    showFirstDay =              "/api/show/day",
    showFirstDayRider =         "/api/show/day/rider",
    showUsers =                 "/api/show/all/users",
    usersWorkers =              "/api/show/users/workers",
    createUser =                "/api/register/user",
    deleteUser =                "/api/delete/user/",
    editUser =                  "/api/update/user",
    showUser =                  "/api/show/user/",
    changePassword =            "/api/reset/password/",
    forgotPassword =            "/api/sendmail/reset/password",
    checkDni =                  "/api/check/client",
    getStock =                  "/api/show/stockVo",
    uploadImage =               "/api/upload/img/publication",
    setMainPicture =            "/api/set/main/photo/stock",
    deleteStockImage =          "/api/delete/image/stock",
    getStockById =              "/api/show/stock",
    deleteStock =               "/api/delete/stockVo",
    assignService =             "/api/assign/service",
    createUpdateAdd =           "/api/create/publication",
    showServices =              "/api/show/services",
    dniOrPlateSearch =          "/api/licenseplate/client",
    getAllAdd =                 "--",

    /**Cliente */
    companyInfoById =           "/api/show/company/client/",
    getBookedHoursByCompanyid = "/api/show/appointment/booked/rider",
    dniAndPlateSearch =         "/api/licenseplate/client/not_user",

    /** Cliente y usuario */
    getHolidays =               "/api/show/holidays",
    getHolidaysRider =          "/api/show/holidays/rider",


    /** Only Dev */
    onlyDev =                   "/api/all/licenseplate",


    /** Cloud tickets */
    cloudtickets =              "/api/cloudtickets",
    createTicket =              "/api/createTicket",
    addcomment =                "/api/addcomment",
    user =                      "/api/user",
    
    /** Insert error logs */
    insertError =               "/api/insert/error",

    /** Check version */
    checkVersion =              "/api/checkversion",

    /** Workload */
    rangeOR =                   "/api/date/range/ORS",
    updateSchedule =            "/api/change/date/OR",
    appointemtsRange =          "/api/date/range/citas",
    changeAppointmentDate =     "/api/change/date/citas",
    assignOR =                  "/api/assign/OR/worker",
    orById =                    "/api/show/or",
    closeOr =                   "/api/close/or",
    addManualTime =             "/api/assign/time/operator",
    checkHolidays =             "/api/check/holiday",
    setHolidays =               "/api/holiday/worker",

    /** OR */
    startTimeOperator =         "/api/time/input/operario/start",
    endTimeOperator =           "/api/time/input/operario/stop",
    deleteTimeHistory=          "/api/delete/time/history",
    articles =                  "/api/show/articles",
    addLine =                   "/api/attach/article/or",
    removeLine =                "/api/delete/article/or",
    currentlyWorking =          "/api/show/task/user",
    forceCloseOr =              "/api/close/all/current/tasks",

    /** Activate users */
    activateUser =              "/api/activate/user/worker",


    /** Change role */
    changeRole =                "/api/GOD",

    /** Change role */
    logout =                "/api/logout",
}
