import { Injectable } from '@angular/core';
import { RoleService, SessionService } from '@sinigual/angular-lib';
import { apiService } from 'src/app/core/api/api-service';
import { M_profile } from 'src/app/core/models/M_Profile';
import { environment } from 'src/environments/environment';
import * as LogRocket from 'logrocket';
import { Subject } from 'rxjs';
import { feature } from 'src/app/core/features-controller/FeaturesController';
import { UserRoleEnum } from 'src/app/core/enums/UserRoleEnum';

@Injectable({
    providedIn: 'root'
})

export class ProfileService {

    subject = new Subject<M_profile>()
    clientData: M_profile | undefined;

    constructor(private apiService: apiService, private sessionS: SessionService, private roleS: RoleService) {
        if (this.sessionS.hasSession()) {
            this.apiService.showProfile().then(response => this.processResponse(response));
        }
    }

    identifyLogRocket(user: M_profile | undefined) {
        if (feature.logRocket) {
            if (user && user.name) {
                LogRocket.identify(user.name.toString(), {
                    name: user.name,
                    email: user.email,
                    environment: environment.dev ? "DEV" : environment.production ? "PRO" : "?"
                });
            }
            else {
                LogRocket.identify(
                    "Invitado", {
                    name: "invitado",
                    email: "Sin correo",
                    environment: environment.dev ? "DEV" : environment.production ? "PRO" : "?"
                });
            }
        }
    }

    processResponse(data: M_profile): void {
        this.clientData = data;
        if (this.clientData.img) {
            this.clientData.img = environment.apiUrl + "/" + this.clientData.img;
        }
        this.subject.next(this.clientData);
        this.identifyLogRocket(this.clientData);
    }

    refresh() {
        this.apiService.showProfile().then(response => this.processResponse(response));
    }


    /** If user has the 'operario_id', meaning it's a Siniwin user */
    get isWorker() { return this.clientData?.operario_id != undefined }

    /** User role getters */
    get gestor() { return this.roleS.getRole() == UserRoleEnum.gestor }
    get asesor() { return this.roleS.getRole() == UserRoleEnum.asesor }
    get gestorasesor() { return this.gestor || this.asesor }
    get mecanico() { return this.roleS.getRole() == UserRoleEnum.mecanico }
    get programming() { return this.roleS.getRole() == UserRoleEnum.programming }
}
