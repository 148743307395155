<app-page-structure [masterClass]="'user'" pageTitle="USUARIOS" [autocompleteID]="'users'" searchLabel="Buscar usuario"
  searchOnboardingClass="onboarding-search-user" (onNoDataAddNew)="openCreateUserDialog()" [data]="[]"
  [canAddNew]="false" [circleIcon]="'person_add'" [displayedHeader]="dh" [displayedColumns]="dc"
  [specialText]="     [undefined, undefined,  getRole,    getStatus]"
  [specialClass]="    [undefined, undefined,  undefined,  getStatusClass]" [card]="card"
  (onclickAddNew)="openCreateUserDialog()" [circularLetter]="true" [lordIcon]="lordIcon">
  <ng-template #lordIcon>
    <lord-icon src="https://cdn.lordicon.com/ajkxzzfb.json" trigger="loop" colors="primary:#faddd1,secondary:#2fbd96"
      state="hover-looking-around" delay="9999" style="width:50px;height:50px">
    </lord-icon>
  </ng-template>
  <ng-template #card>
    <app-card *ngIf="ps.showItem()" [cornerMatMenu]="deleteCorner" [contentLoaded]="true">
      <p *ngIf="ps.selected!.isActivated" class="fw400" [ngClass]="ps.selected!.online ? 'c_p' : 'c_r'">
        <span class="dot" [ngClass]="ps.selected!.online ? 'bc_p' : 'bc_r'"></span> 
        {{ps.selected!.online ? 'Sesión activa' : 'Sin sesión'}}
      </p>
      <div class="df fdc jcc aic mb10i">
        <app-card-title style="margin-top: 30px;" [noMarginBottom]="true">{{ps.selected?.name}}</app-card-title>
        <app-circular-letter [showTooltip]="false" [big]="true" [showFullName]="false"
          [user]="ps.selected!"></app-circular-letter>
      </div>
      <button class="mb10i" mat-stroked-button (click)="openEditUserDialog(ps.selected!)">
        EDITAR
      </button>

      <div *ngIf="au" [ngTemplateOutlet]="activationbuttons"></div>

      <div *ngIf="ps.selected!.isActivated">


        <mat-accordion>
          <mat-expansion-panel [expanded]="holidaysExpanded" (afterExpand)="holidaysExpanded = true"
            (afterCollapse)="holidaysExpanded = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Días festivos
              </mat-panel-title>
              <mat-panel-description>
                Festivos del usuario
                <mat-icon class="ml5">event</mat-icon>
              </mat-panel-description>
            </mat-expansion-panel-header>

            <app-holidays-calendar *ngIf="wor" [showUnsaved]="false" [selfSave]="true" [prespective]="'user'"
              [enabledFor]="enabledCalender"
              [initData]="{companyH : companyHolidays, userH: this.ps.selected!.holidays, userID : this.ps.selected!.id}"
              (onSave)="onSaveUserHolidays($event, ps.selected!)">
            </app-holidays-calendar>

          </mat-expansion-panel>
        </mat-accordion>
      </div>


    </app-card>
  </ng-template>

  <ng-template #deleteCorner>
    <button mat-icon-button [mat-menu-trigger-for]="deleteMenu"><mat-icon>more_vert</mat-icon></button>
    <mat-menu #deleteMenu="matMenu">
      <mat-option style="height: 64px;" (click)="openDeleteUserDialog(ps.selected!)"
        *ngIf="!ps.selected!.role.gestor; else cannotDelete">
        <p class="nmb">Eliminar</p>
      </mat-option>

      <ng-template #cannotDelete>
        <mat-option [disabled]="true">
          <p class="nmb c_t2">No se puede eliminar al gestor</p>
        </mat-option>
      </ng-template>

    </mat-menu>
  </ng-template>
</app-page-structure>

<ng-template #activationbuttons>
  <p class="c_r fw400" *ngIf="!ps.selected!.email; else withEmail">No puedes activar el usuario porque no tiene correo
  </p>
  <ng-template #withEmail>

    <app-layout-send-email *ngIf="ps.selected?.estado_activacion == ae.toActivate" [temaplte]="'activate'"
      [endpoint]="activateUser.bind(this)"></app-layout-send-email>

    <p *ngIf="ps.selected?.estado_activacion == ae.sended" class="tac"><mat-icon class="c_b vam mr5">info</mat-icon>Se
      ha
      enviado el correo de activación al usuario</p>
  </ng-template>



</ng-template>