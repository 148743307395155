import { Endpoint } from '@sinigual/angular-lib';
import { environment } from 'src/environments/environment';
import { Calls } from '../enums/Calls';

/** Los endpoints de la API se generan mediante el ({@link currentEnviroment} +  {@link Calls})*/
export const endpoints = {

    /** Endpoints SIN el header de autorizacón */
    companyInfoById :          new Endpoint(environment.apiUrl, Calls.companyInfoById, false),
    getBookedHoursByCompanyId :new Endpoint(environment.apiUrl, Calls.getBookedHoursByCompanyid, false, false),
    dniAndPlateSearch:         new Endpoint(environment.apiUrl, Calls.dniAndPlateSearch, false, false),
    
    login :                    new Endpoint(environment.apiUrl, Calls.login, false),

    /** Endpoint CON el header de autorizacón */
    estadoTaller :             new Endpoint(environment.apiUrl, Calls.estadoTaller),
    clients :                  new Endpoint(environment.apiUrl, Calls.clients, true, false),
    cargaTaller :              new Endpoint(environment.apiUrl, Calls.cargaTaller),
    showPreorder :             new Endpoint(environment.apiUrl, Calls.showPreorder, true, false),
    showRider :                new Endpoint(environment.apiUrl, Calls.showRider),
    orderCreate :              new Endpoint(environment.apiUrl, Calls.orderCreate),
    changePicture :            new Endpoint(environment.apiUrl, Calls.changePicture),
    showProfile :              new Endpoint(environment.apiUrl, Calls.showProfile, true, false),
    deleteOr :                 new Endpoint(environment.apiUrl, Calls.deleteOr),
    createCitaCliente :        new Endpoint(environment.apiUrl, Calls.createCitaCliente),
    showCitaCliente :          new Endpoint(environment.apiUrl, Calls.showCitaCliente),
    editarCitaCliente :        new Endpoint(environment.apiUrl, Calls.editarCitaCliente),
    createCitaMecanico :       new Endpoint(environment.apiUrl, Calls.createCitaMecanico),
    companyInfo :              new Endpoint(environment.apiUrl, Calls.companyInfo, true, false),
    setCompanyInfo :           new Endpoint(environment.apiUrl, Calls.setCompanyInfo),
    setCompanyImg :            new Endpoint(environment.apiUrl, Calls.setCompanyImage),
    getCitaByDay :             new Endpoint(environment.apiUrl, Calls.getCitaByDay, true, false),
    getBookedHours :           new Endpoint(environment.apiUrl, Calls.getBookedHours, true ,false),
    getNotifications :         new Endpoint(environment.apiUrl, Calls.getNotifications, true, false),
    updateNotifications:       new Endpoint(environment.apiUrl, Calls.updateNotifications),
    readAllNotifications :     new Endpoint(environment.apiUrl, Calls.readAllNotifications),
    deleteAllNotifications :   new Endpoint(environment.apiUrl, Calls.deleteAllNotifications),
    getAppointmentByDateRange :new Endpoint(environment.apiUrl, Calls.getAppointmentByDateRange),
    getNextCitas :             new Endpoint(environment.apiUrl, Calls.getNextCitas, true, false),
    showFirstDay :             new Endpoint(environment.apiUrl, Calls.showFirstDay, true , false),
    showFirstDayRider :        new Endpoint(environment.apiUrl, Calls.showFirstDayRider, false, false),
    showUsers:                 new Endpoint(environment.apiUrl, Calls.showUsers, true, false),
    usersWorkers:              new Endpoint(environment.apiUrl, Calls.usersWorkers, true, false),
    createUser:                new Endpoint(environment.apiUrl, Calls.createUser),
    deleteUser:                new Endpoint(environment.apiUrl, Calls.deleteUser),
    editUser:                  new Endpoint(environment.apiUrl, Calls.editUser),
    showUser:                  new Endpoint(environment.apiUrl, Calls.showUser),
    changePassowrd:            new Endpoint(environment.apiUrl, Calls.changePassword),
    forgotPassword:            new Endpoint(environment.apiUrl, Calls.forgotPassword),
    getAllAdd:                 new Endpoint(environment.apiUrl, Calls.getAllAdd),
    getHolidays:               new Endpoint(environment.apiUrl, Calls.getHolidays, true , false),
    getHolidaysRider:          new Endpoint(environment.apiUrl, Calls.getHolidaysRider, false , false),
    checkDni:                  new Endpoint(environment.apiUrl, Calls.checkDni),
    getStock:                  new Endpoint(environment.apiUrl, Calls.getStock, true , false),
    getStockById:              new Endpoint(environment.apiUrl, Calls.getStockById),
    deleteStock:               new Endpoint(environment.apiUrl, Calls.deleteStock),
    uploadImage :              new Endpoint(environment.apiUrl, Calls.uploadImage),
    setMainPicture :           new Endpoint(environment.apiUrl, Calls.setMainPicture),
    deleteStockImage :         new Endpoint(environment.apiUrl, Calls.deleteStockImage), 
    createUpdateAdd:           new Endpoint(environment.apiUrl, Calls.createUpdateAdd),
    assignService:             new Endpoint(environment.apiUrl, Calls.assignService),
    showServices:              new Endpoint(environment.apiUrl, Calls.showServices, true , false),
    dniOrPlateSearch:          new Endpoint(environment.apiUrl, Calls.dniOrPlateSearch),
    cloudtickets:              new Endpoint(environment.apiUrl, Calls.cloudtickets, true, false),
    createTicket:              new Endpoint(environment.apiUrl, Calls.createTicket),
    addcomment:                new Endpoint(environment.apiUrl, Calls.addcomment),
    user:                      new Endpoint(environment.apiUrl, Calls.user),
    onlyDev:                   new Endpoint(environment.apiUrl, Calls.onlyDev),
    insertError :              new Endpoint(environment.apiUrl, Calls.insertError),
    checkVersion :             new Endpoint(environment.apiUrl, Calls.checkVersion, true, false),

    /** Workload */
    rangeOR :                   new Endpoint(environment.apiUrl, Calls.rangeOR, true, false),
    updateSchedule :            new Endpoint(environment.apiUrl, Calls.updateSchedule),
    appointemtsRange :          new Endpoint(environment.apiUrl, Calls.appointemtsRange, true, false),
    changeAppointmentDate :     new Endpoint(environment.apiUrl, Calls.changeAppointmentDate),
    assignOR :                  new Endpoint(environment.apiUrl, Calls.assignOR),
    orById :                    new Endpoint(environment.apiUrl, Calls.orById),
    closeOr :                   new Endpoint(environment.apiUrl, Calls.closeOr),
    checkHolidays :             new Endpoint(environment.apiUrl, Calls.checkHolidays, true, false),
    setHolidays :               new Endpoint(environment.apiUrl, Calls.setHolidays),

    /** OR */
    startTimeOperator :         new Endpoint(environment.apiUrl, Calls.startTimeOperator, true, true, true),
    endTimeOperator :           new Endpoint(environment.apiUrl, Calls.endTimeOperator, true, true, true),
    deleteTimeHistory:          new Endpoint(environment.apiUrl, Calls.deleteTimeHistory, true, true, true),
    addLine:                    new Endpoint(environment.apiUrl, Calls.addLine, true, true, true),
    removeLine:                 new Endpoint(environment.apiUrl, Calls.removeLine, true, true, true),
    addManualTime :             new Endpoint(environment.apiUrl, Calls.addManualTime, true, true, true),

    articles:                   new Endpoint(environment.apiUrl, Calls.articles),
    currentlyWorking:           new Endpoint(environment.apiUrl, Calls.currentlyWorking),
    forceCloseOr:               new Endpoint(environment.apiUrl, Calls.forceCloseOr),
    
    /** Activate users */
    activateUser :              new Endpoint(environment.apiUrl, Calls.activateUser),


    /** Change Role */
    changeRole :                new Endpoint(environment.apiUrl, Calls.changeRole),


    /** Logout */
    logout :                    new Endpoint(environment.apiUrl, Calls.logout, true, false),
}