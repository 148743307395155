import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ErrorDialogService, FileUploadComponent, RouterService, SnackService } from '@sinigual/angular-lib';
import { forkJoin, Subject } from 'rxjs';
import { apiService } from 'src/app/core/api/api-service';
import { M_Stock } from 'src/app/core/models/M_Stock';
import { M_Provider } from 'src/app/core/models/M_Provider';
import { ViewPath } from 'src/app/modules/app-routing.module';
import { FichaTecnicaComponent } from './accordion-cards/ficha-tecnica/ficha-tecnica.component';
import { PublicarAnuncioComponent } from './accordion-cards/publicar-anuncio/publicar-anuncio.component';
import { M_Add } from 'src/app/core/models/M_Add';
import { MatDialog } from '@angular/material/dialog';
import { AddEditedDialogComponent } from './add-edited-dialog/add-edited-dialog.component';

@Component({
  selector: 'app-edit-add',
  templateUrl: './edit-add.component.html',
  styleUrls: ['./edit-add.component.css']
})
export class EditAddComponent implements OnInit {
  loaded = false;
  id: number | undefined;
  error: boolean = false;
  bike: M_Stock | undefined;
  providers: M_Provider[] = []
  v = ViewPath;
  childsInit = false;
  subject = new Subject<M_Stock>();
  @ViewChild(FichaTecnicaComponent) fichaTecnica!: FichaTecnicaComponent;
  @ViewChild(PublicarAnuncioComponent) publicarAniuncio!: PublicarAnuncioComponent;

  constructor(private errorD: ErrorDialogService, private snackS: SnackService, private d: MatDialog, private apiS: apiService, private route: ActivatedRoute, public routerS: RouterService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params["id"]) {
        this.id = params["id"];
      }
      else {
        this.error = true;
      }
    })
  }

  ngAfterViewInit() {
    this.getBikeFromId(this.id!);
  }

  /** Return the errors */
  get publicationErrors(): string[] {

    var errors = [];

    if (!this.fichaTecnica?.isDisabledFormOk()) {
      errors.push("1 - Datos obligatorios de Siniwin")
    }

    if (!this.fichaTecnica?.datosAdicionalesOk) {
      errors.push("3 - Datos adicionales")
    }


    if (!this.publicarAniuncio?.addContentOk) {
      errors.push("4 - Contenido del anuncio")
    }

    if (!this.publicarAniuncio?.hasImages()) {
      errors.push("5 - Imágenes")
    }

    return errors;
  }

  createUpdateAdd() {

    if (this.bike) {

      /** Get the previous id */
      var previous_id = this.bike.add?.id;

      if (this.bike.add == undefined) {
        this.bike.add = new M_Add({});
      }
      this.bike.add.patchNewData(this.fichaTecnica.form);
      this.bike.add.patchAdd(this.publicarAniuncio.form);
      this.bike.add.patchProviders(this.publicarAniuncio.getSelectedProviders());

      /** Set the prevoius id */
      this.bike.add.id = previous_id;

      this.apiS.createUpdateAdd(this.bike).then(res => {

        /** No comments */
        if (res.url_appmoto) {
          var appmotoProvider = this.providers.find(p => p.name.toLocaleLowerCase() == "appmoto");
          if (appmotoProvider) {
            appmotoProvider.url_publication = res.url_appmoto;
          }
        }

        if (this.bike != undefined && this.bike.add != undefined) {
          if (res.id) {
            this.bike.add.id = res.id;
          }
          if (this.bike.add.id != undefined) {
            this.uploadPictures();
          }
          else {
            this.errorD.showError("¡Oops!", "No se pudo obtener el identificador del anuncio", "Vuelve a intentarlo en unos minutos")
          }
        }
        else {
          this.errorD.showError("¡Oops!", "Parece ser que algo salió mal", "Vuelve a intentarlo en unos minutos")
        }
      })
    }
  }


  switchImages() {
    var newFiles = this.publicarAniuncio.subirFotografia?.fuc;
    var oldFiles = this.publicarAniuncio.subirFotografia?.old;
    if (newFiles && oldFiles) {
      if (Array.isArray(oldFiles.uploadedFiles)) {
        if (!Array.isArray(newFiles.uploadedFiles)) {
          newFiles.uploadedFiles = [];
        }

        oldFiles.setImagesFromDatabase(newFiles.uploadedFiles);
        if (this.bike?.add) {
          var finalFiles = oldFiles.getCustomFiles();
          this.bike.add.fotos = finalFiles != undefined ? finalFiles : [];
        }
        newFiles.uploadedFiles = [];
      }
    }
    else {
      console.log("??")
    }
  }

  triggerCreateUpdateResult() {
    if (!this.publicarAniuncio.hasProviders()) {
      this.snackS.show("Cambios guardados", "Volver a la gestión de anuncios", 5).afterDismissed().subscribe(res => {
        if (res.dismissedByAction){
          this.routerS.goTo(ViewPath.stock);
        }
      });
    }
    else {
      this.d.open(AddEditedDialogComponent, { disableClose: true });
    }
  }

  setNewIds(newFiles: FileUploadComponent, ids: number[]) {
    /** Set databease images ids */
    var files = newFiles?.getCustomFiles();
    for (let i = 0; i < ids.length; i++) {
      if (files && files[i]) {
        files[i].database_id = ids[i];
      }
    }
  }

  uploadPictures() {

    var newFiles = this.publicarAniuncio.subirFotografia?.fuc;
    var oldFiles = this.publicarAniuncio.subirFotografia?.old;

    if (newFiles && oldFiles) {
      //Si tenemos el id del anuncio
      if (this.bike?.add?.id) {
        console.log("Existe el ID del anuncio")
        var files = newFiles.getUploadedFiles();
        if (this.bike?.add?.id && newFiles && files) {
          console.log("Existen nuevas imagenes")
          this.apiS.uploadImage(this.bike.add.id, files).then(res => {
            this.triggerCreateUpdateResult();
            this.setNewIds(newFiles!, res);
            this.switchImages();
          })
        }
        else {
          console.log("No hay imagenes que subir")
          this.triggerCreateUpdateResult();
        }
      }
      //Si no tenemos el id del anuncio
      else {
        this.errorD.showError("¡Oops!", "Parece ser que algo salió mal", "Vuelve a intentarlo en unos minutos")
      }
    }
  }


  getBikeFromId(id: number) {
    const a = this.apiS.getProviders();
    const b = this.apiS.getStockById(id)
    forkJoin([a, b]).subscribe(res => {
      this.providers = res[0];
      this.bike = res[1];
      this.subject.next(this.bike);
      this.loaded = true;
    })
  }
}
