import { Directive, OnInit } from "@angular/core";
import { NgControl } from "@angular/forms";
import { nifValidator } from "@sinigual/angular-lib";

/** Put this on <input> tag */
@Directive({
  selector: '[appTest]'
})
export class TestDirective implements OnInit {
  
  constructor(private control: NgControl) {}

  ngOnInit(){
    this.control.control?.setValidators(nifValidator())
  }
}