import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ProfileService } from '../profile/profileService';
import { animate, style, transition, trigger } from '@angular/animations';
import { apiService } from 'src/app/core/api/api-service';
import { M_ShowPreorder } from 'src/app/core/models/M_ShowPreorder';
import { environment } from 'src/environments/environment';
import { M_Cita } from 'src/app/core/models/M_Cita';
import { forkJoin } from 'rxjs';
import { AppointmentStatus, CustomDate, RoleService, RouterService, timeDifference } from '@sinigual/angular-lib';
import { ViewPath } from 'src/app/modules/app-routing.module';
import { UserRoleEnum } from 'src/app/core/enums/UserRoleEnum';
import { M_OR } from 'src/app/core/models/M_OR';
import { WorkloadService } from 'src/app/core/services/workload.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  animations: [
    trigger('appear', [
      transition('void => *', [
        style({ opacity: 0 }),
        style({ opacity: 0, transform: 'scale(0.8)' }),
        animate(1000, style({ opacity: 1, transform: 'scale(1)' }))
      ])
    ])
  ]
})
export class DashboardComponent implements OnInit {
  env = environment;
  vp = ViewPath;
  maxCards = 4;
  loaded: boolean;
  recepciones: M_ShowPreorder[] = [];
  proximasCitas: M_Cita[] = [];
  workingOr: M_OR | undefined;

  role: UserRoleEnum;
  ure = UserRoleEnum;

  constructor(roleS: RoleService, public profileS: ProfileService, public routerS: RouterService, public apiService: apiService, private chDref: ChangeDetectorRef, public wd: WorkloadService) {
    this.role = roleS.getRole();
    this.loaded = false;
    const a = this.apiService.showPreOrder();
    const b = this.apiService.getNextCitas();
    forkJoin([a, b]).subscribe(response => {
      this.processResponse(response[0], response[1]);
    })
  }

  ngOnInit(): void { }

  async processResponse(recep: M_ShowPreorder[], citas: M_Cita[]) {
    for (let i = 0; i < this.maxCards; i++) {
      await this.sleep(100);
      if (recep[i] != undefined) {
        this.recepciones.push(recep[i]);
      }
      if (citas[i] != undefined && citas[i].estado_cita == AppointmentStatus.pendiente) {
        this.proximasCitas.push(citas[i]);
      }
    }
    this.loaded = true;
  }

  goIssues() {
    this.routerS.goTo(ViewPath.incidencias);
  }

  goWorkload(c: M_Cita) {
    this.routerS.goWithQueryParams(ViewPath.cargaTaller, { id: c.id, date: c.appointment_date, section: "appointment" })
  }

  sleep(ms: any) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }
}

