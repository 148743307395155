import { CustomDate, SearchFiltrable, match } from "@sinigual/angular-lib";
import { apiService } from "../../core/api/api-service";


/** [Respuesta a una petición GET]
 *  @description  Classe para parsear la respuesta a la llamada de buscar matrícula
 *  {@link apiService.showRaider()}
 */
export class M_Rider implements SearchFiltrable {

    id : number;
    company_id: string;
    //------------------------------------
    //Datos Vehiculo 
    Matricula: string;
    Bastidor: string | undefined;
    Color: string | undefined;
    Cilindrada: string | undefined;
    FeUltRepa: CustomDate | undefined;
    KmsUltRepa: string | undefined;
    MdlTaller: string | undefined;
    Marca: string | undefined;
    NumMotor: string | undefined;
    TiMotor: string | undefined;
    TiPintura: string | undefined;
    Nive: string | undefined;
    Serie: string | undefined;
    TiCambio: string | undefined;
    CodModelo: number | undefined;
    //------------------------------------
    //Datos Conductor
    Nombre1: string | undefined;
    Nombre2: string | undefined;
    Nif: string | undefined;
    TelFijoEmp: string | undefined;
    TelMovilEmp: string | undefined;
    TelFijoPar: string | undefined;
    TelMovilPar: string | undefined;
    Mail1p: string | undefined;
    Mail2p: string | undefined;
    Sexo: string | undefined;
    Idioma: string | undefined;
    Poblacion: string | undefined;
    Provincia: string | undefined;
    Pais: string | undefined;
    CodPostal: string | undefined;
    NumCalle1: string | undefined;
    NumCalle2: string | undefined;
    Direccion1: string | undefined;
    Direccion2: string | undefined;
    NumConta: string | undefined;
    Observaciones: string | undefined;
    PerContacto: string | undefined;
    created_at : CustomDate | undefined;
    updated_at : CustomDate | undefined;

    constructor(obj: any, optimize: boolean = false) {

        // Attributes on the page-structure component view of clients
        this.id = obj.id;
        this.Matricula = obj.Matricula;
        this.Nombre1 = obj.Nombre1;
        this.Direccion1 = obj.Direccion1;
        this.Poblacion = obj.Poblacion;
        this.Bastidor = obj.Bastidor
        this.Marca = obj.Marca;

        if (obj.FeUltRepa) {
            try { this.FeUltRepa = new CustomDate(obj.FeUltRepa); }
            catch (e: any) { this.FeUltRepa = undefined; }
        }
        this.KmsUltRepa = obj.KmsUltRepa
        this.CodModelo = obj.CodModelo;
        this.Nif = obj.Nif;
        this.TelMovilPar = obj.TelMovilPar;
        this.Mail1p = obj.Mail1p;
        this.TelFijoPar = obj.TelFijoPar;
        this.company_id = obj.company_id;
        this.MdlTaller = obj.MdlTaller;

        try { this.created_at = new CustomDate(obj.created_at); }
        catch (e: any) { this.created_at = undefined; }

        try { this.updated_at = new CustomDate(obj.updated_at); }
        catch (e: any) { this.updated_at = undefined; }

        if (!optimize) {
            this.Color = obj.Color;
            this.Cilindrada = obj.Cilindrada;
            this.NumMotor = obj.NumMotor;
            this.TiMotor = obj.TiMotor;
            this.TiPintura = obj.TiPintura;
            this.Nive = obj.Nive;
            this.Serie = obj.Serie;
            this.TiCambio = obj.TiCambio;
            this.Bastidor = obj.Bastidor;
            this.Nombre2 = obj.Nombre2;
            this.TelFijoEmp = obj.TelFijoEmp;
            this.TelMovilEmp = obj.TelFijoEmp;
            this.Mail2p = obj.Mail2p;
            this.Sexo = obj.Sexo;
            this.Idioma = obj.Idioma;
            this.Provincia = obj.Provincia;
            this.Pais = obj.Pais;
            this.CodPostal = obj.CodPostal;
            this.NumCalle1 = obj.NumCalle1;
            this.NumCalle2 = obj.NumCalle2;
            this.Direccion2 = obj.Direccion2;
            this.NumConta = obj.NumConta;
            this.Observaciones = obj.Observaciones;
            this.PerContacto = obj.PerContacto;
        }
    }

    getName() {
        return (this.Marca ? (this.Marca + " ") : '') + (this.MdlTaller ? this.MdlTaller : '');
    }

    defaultSearchFilter(text: string): boolean {
        return match(text, this.Matricula, this.Nombre1, this.Nombre2, this.Direccion1, this.Poblacion, this.Marca, this.Nif, this.TelMovilPar, this.Mail1p, this.MdlTaller)
    }

    hasPhone() {
        return this.TelFijoPar || this.TelMovilPar;
    }
    hasMail() {

        return this.Mail1p || this.Mail2p;

    }
}