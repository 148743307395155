import { Injectable } from "@angular/core";
import { CustomDate, CustomTime } from "@sinigual/angular-lib";

export class BookedHoursTime {
    t: CustomTime;
    available: boolean;

    constructor(t: CustomTime, available: boolean) {
        this.t = t;
        this.available = available;
    }
}

@Injectable({
    providedIn: 'root'
})

/** Servicio para gestionar los inputs de las horas 'HH:mm' 
 * forceInclude : Se incluye la fecha en el selector de hora. Es posible que el usuorio cambie la configuración de empresa y por tanto, la hora que tenia la cira no se genere automaticamente.
*/
export class TimeService {
    genereteHoursbyCompany(openHourMorning: number, closeHourMorning: number,
        openHourAfternoon: number, closeHourAfternoon: number,
        granularity: number, notAvailable: number[], forceInclude?: CustomDate): BookedHoursTime[] {
        let hours: BookedHoursTime[] = [];
        for (let i = openHourMorning; i < closeHourMorning; i += granularity) {
            if (i < 60 * 24) {
                let discard = false;
                for (let j = 0; j < notAvailable.length; j++) {
                    if (i == notAvailable[j]) { discard = true; }
                }
                hours.push(new BookedHoursTime(this.parseNumberToHour(i), discard));
            }
        }
        for (let i = openHourAfternoon; i < closeHourAfternoon; i += granularity) {
            if (i < 60 * 24) {
                let discard = false;
                for (let j = 0; j < notAvailable.length; j++) {
                    if (i == notAvailable[j]) { discard = true; }
                }
                hours.push(new BookedHoursTime(this.parseNumberToHour(i), discard));
            }
        }


        if (forceInclude) {
            this.forceIncludeHour(hours, forceInclude)
        }

        return hours;
    }

    forceIncludeHour(hours : BookedHoursTime[], forceInclude : CustomDate){
        var founded = false;
        var searching = this.getTimeFromDate(forceInclude).getValue();
        hours.forEach(h => {
            if (h.t.getValue() == searching){
                founded = true;
            }
        })

        if (!founded){
            hours.push(new BookedHoursTime(this.parseNumberToHour(searching), true));
            hours.sort((a,b)=> a.t.getValue() > b.t.getValue() ? 1 : -1)
        }
    }

    /**A partir de un número de minutos, construye la clase Time (formato HH:mm) */
    parseNumberToHour(minutes: number): CustomTime {
        var h = Math.floor(minutes / 60);
        var m = minutes % 60;
        let hs = h < 10 ? '0' + h : h.toString();
        let ms = m < 10 ? '0' + m : m.toString();
        return new CustomTime(hs, ms)
    }

    parseHourToNumber(time: CustomTime): number {
        return time.getHours() * 60 + time.getMinutes();
    }

    getTimeFromDate(d: CustomDate): CustomTime {
        return new CustomTime(d.value!.getHours().toString(), d.value!.getMinutes().toString())
    }

    /**
     * Crea la classe Time a partir de una string
     * @param time Debe estar con el formato 'HH:mm'
     */
    getTime(time: string): CustomTime {
        if (time) {
            let hourMinutes = time.split(':');
            return new CustomTime(hourMinutes[0], hourMinutes[1], "00")
        }
        else {
            throw Error("Cannot parse time");
        }
    }
} 