import { FormGroup } from "@angular/forms";
import { CustomFile } from "@sinigual/angular-lib";
import { Carnet } from "../enums/Carnet";
import { MotoType } from "../enums/MotoTypes";
import { M_Provider } from "./M_Provider";

export class M_Add {

    id: number | undefined;
    /** Anuncio */
    titulo: string;
    descripcion: string;
    pvp: number;
    precio: number;
    oferta: number | undefined;
    id_services: M_Provider[] = [];
    publicar: boolean;

    /** New data */
    moto_type: MotoType;
    sku: string;
    carnet_type: Carnet;
    potencia: number;
    peso: number;
    moto_year: string;

    status : "V" | null;
    /** Pictures */
    fotos: CustomFile[] = [];
    foto_portada: number | undefined;

    constructor(d: any) {
        this.id = d.id;
        this.moto_type = d.moto_type;
        this.potencia = d.potencia;
        this.sku = d.sku;
        this.carnet_type = d.carnet_type;
        this.peso = d.peso;
        this.precio = d.precio;
        this.oferta = d.oferta;
        this.foto_portada = d.foto_portada;

        if (d.images) {
            for (let i = 0; i < d.images.length; i++) {
                this.fotos.push(new CustomFile(d.images[i].path, undefined, undefined, d.images[i].id));
            }
        }
        this.titulo = d.titulo;
        this.publicar = d.publicar ? d.publicar : false;
        this.titulo = d.titulo;
        this.descripcion = d.descripcion;
        this.pvp = d.pvp;

        if (d.services) {
            for (let i = 0; i < d.services.length; i++) {
                this.id_services.push(new M_Provider(d.services[i]))
            }
        }
        
        this.status = d.status;
        this.moto_year = d.moto_year;
    }

    hasPictures() {
        return this.fotos.length != 0;
    }

   get hasProviders() {
        return this.id_services.length != 0;
    }

    patchNewData(form: FormGroup) {
        this.moto_type = form.get("moto_type")?.value;
        this.sku = form.get("sku")?.value;
        this.carnet_type = form.get("carnet_type")?.value;
        this.potencia = form.get("potencia")?.value;
        this.peso = form.get("peso")?.value;
        this.moto_year = form.get("moto_year")?.value;
    }

    patchAdd(form: FormGroup) {
        this.titulo = form.get("titulo")?.value;
        this.descripcion = form.get("descripcion")?.value;
        this.pvp = form.get("pvp")?.value;
        this.precio = form.get("precio")?.value;
        this.oferta = form.get("oferta")?.value;
        this.id_services = [new M_Provider({ id: 1 })]
        this.publicar = form.get("publicar")?.value;
    }

    /** Pick the image-toggle-values from the screen and create a array of providers whit it */
    patchProviders(providers: M_Provider[]) {
        this.id_services = providers;
    }

    isProviderPreSelected(p: M_Provider) {
        return this.id_services.filter(add => add.id == p.id).length >= 1;
    }

    getIndexPortada() {
        if (!this.foto_portada || !this.fotos.length) { return -1 }
        else {
            for (let i = 0; i < this.fotos.length; i++) {
                if (this.fotos[i].database_id == this.foto_portada) {
                    return i;
                }
            }
            return -1;
        }
    }
}