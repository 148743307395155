import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClassSearcherComponent, CustomDate, CustomTime, ErrorDialogService, SnackService } from '@sinigual/angular-lib';
import { apiService } from 'src/app/core/api/api-service';
import { M_OR } from 'src/app/core/models/M_OR';
import { M_User } from 'src/app/core/models/M_User';
import { ProfileService } from 'src/app/views/profile/profileService';

@Component({
  selector: 'app-add-time-or-dialog',
  templateUrl: './add-time-or-dialog.component.html',
  styleUrls: ['./add-time-or-dialog.component.css']
})
export class AddTimeOrDialogComponent implements AfterViewInit {

  timeFc: FormControl = new FormControl("");
  dateFc: FormControl = new FormControl(new CustomDate().datePickerFormat);

  usermaster = new M_User({});
  @ViewChild("workerSearcher") workerSearcher?: ClassSearcherComponent<M_User>;
  constructor(
    public dialogRef: MatDialogRef<AddTimeOrDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { or: M_OR, workers: M_User[], assigned: M_User | undefined },
    public profileS: ProfileService, private apiS: apiService, private errorS: ErrorDialogService, private snackS: SnackService) {
  }
  ngAfterViewInit(): void {

    if (this.data.workers.length == 0) {
      this.timeFc.disable();
    }

    /** If the user is no worker, pre-assign the assigned OR worker */
    if (this.data.assigned) {
      this.workerSearcher?.setMasterObject(this.data.assigned);
    }
  }

  get isAddTimeEnabled() {
    return this.timeFc.value && this.dateFc.value && this.workerSearcher?.selected != undefined;
  }

  closeWithTime() {
    var id: number | undefined;
    if (this.workerSearcher?.selected) {
      id = this.workerSearcher?.selected.operario_id;
    }
    else {
      id = this.profileS.clientData?.operario_id;
    }

    var ct = new CustomTime(this.timeFc.value);
    let date: CustomDate | undefined = undefined;

    try { date = new CustomDate(this.dateFc.value); }
    catch (e: any) { }

    if (id && date) {
      this.apiS.addManualTime(this.data.or.id, id, ct.getHours(), ct.getMinutes(), date)
        .then(res => {
          this.data.or.addTime(res);
          this.dialogRef.close();
          this.snackS.show("Tiempo añadido correctamente", undefined, 2)

        })
        .catch(e => {
          this.dialogRef.close(new Error(e))
        })
    }
    else {
      this.errorS.showError("No se encuentra el ID del operario", "Contacte con nosotros si el problema persiste")
    }
  }

}
