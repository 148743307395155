import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogService, EmailInputComponent, FileUploadComponent, FormService, SpaceRemoverService } from '@sinigual/angular-lib';
import { apiService } from 'src/app/core/api/api-service';
import { UserRole } from 'src/app/core/custom-classes/UserRole';
import { UserRoleEnum } from 'src/app/core/enums/UserRoleEnum';
import { M_User } from 'src/app/core/models/M_User';

@Component({
  selector: 'app-edit-user',
  templateUrl: './create-edit-user.component.html',
  styleUrls: ['./create-edit-user.component.css']
})
export class CreateEditUserComponent implements OnInit {
  public editForm: UntypedFormGroup;
  pictureChanged = false;
  allRoles: UserRole[];
  @ViewChild(FileUploadComponent) imageUploadC!: FileUploadComponent;
  @ViewChild(EmailInputComponent) emailInput!: EmailInputComponent;

  constructor(private dialog: MatDialog, private chdref: ChangeDetectorRef, private fs: FormService,
    public dialogRef: MatDialogRef<CreateEditUserComponent>, @Inject(MAT_DIALOG_DATA) public data: [M_User, string[]] | [undefined, string[]],
    private formBuilder: UntypedFormBuilder, private apiS: apiService, private confirmDialogS: ConfirmDialogService, private spaceRemover : SpaceRemoverService) {
    this.editForm = this.formBuilder.group({
      name: [data[0]?.name, [Validators.required]],
      email: [data[0]?.email],
      role: [data[0]?.role.role, [Validators.required]],
    });

    this.allRoles = [new UserRole(UserRoleEnum.asesor), new UserRole(UserRoleEnum.mecanico)];
  }
  ngOnInit(): void {
    this.dialogRef.updateSize("500px")
  }

  ngAfterViewInit() {
    if (this.data[0]) {
      if (this.data[0].img) {
        this.imageUploadC.setImageFromDatabase(this.data[0].img);
        this.chdref.detectChanges();
      }
      this.editForm.get("email")?.disable();
      this.chdref.detectChanges();
    }

    this.emailInput.onValueChange(() => this.checkMailExists())
  }

  checkMailExists() {
    if (this.data[1].includes(this.emailInput.value!)) {
      this.emailInput.setRepeatedError();
    }
  }

  getValues() {
    let user = new M_User({});
    user.name = this.editForm.get('name')?.value;
    user.email = this.editForm.get('email')?.value;
    user.role = new UserRole(this.editForm.get('role')?.value);
    user.img = this.imageUploadC.hasFiles() ? this.imageUploadC.getCustomFile()!.src : "";
    if (this.data[0] != undefined) {
      user.id = this.data[0].id;
    }
    return user;
  }

  pictureChangred() {
    this.pictureChanged = true;
  }

  getFormData() {
    let u: M_User = this.getValues();
    let fd = new FormData();
    fd.append("email", u.email)
    fd.append("user_name", u.name)
    fd.append("role", u.role.role.toString())
    var f = this.imageUploadC.getUploadedFile();
    if (f && this.pictureChanged) {
      fd.append("image", f);
    }
    return fd;
  }

  editUserCall() {

    this.spaceRemover.check(this.editForm.get('name')?.value)

    this.apiS.editUser(this.getValues()!.id!, this.getFormData()).then(resp => {
      if (resp) {
        this.dialogRef.close(true);
        this.openSuccesDialog("USUARIO EDITADO CON ÉXITO");
      }
      else {
        this.dialogRef.close(true);
      }
    })
  }

  makeCall() {
    if (this.fs.isOk(this.editForm)) {
        this.editUserCall();
    }
  }

  openSuccesDialog(text: string) {
    this.dialogRef.close(true);
    this.confirmDialogS.show({
      title: text,
      confirmTxt: "OK",
      body: "",
      showCancel: false
    })
  }

  get emailError(){
    return this.data[0] && !this.editForm.get('email')?.value
  }

}
