import { UserRoleEnum } from "../enums/UserRoleEnum";

export class UserRole {
    role: UserRoleEnum;

    constructor(role: UserRoleEnum | string) {
        if (typeof role === "string") {
            if (isNaN(parseFloat(role))) {
                this.role = UserRoleEnum[role as keyof typeof UserRoleEnum]
            }
            else {
                let role_ = parseInt(role);
                this.role = role_;
            }
        }
        else {
            this.role = role;
        }
    }

    get asesor() {return this.role == UserRoleEnum.asesor;}
    get gestor() {return this.role == UserRoleEnum.gestor;}
    get mecanico() {return this.role == UserRoleEnum.mecanico;}
    get programming() {return this.role == UserRoleEnum.programming;}
    get visitante() {return this.role == UserRoleEnum.visitante;}
    get noOne() {return this.role == UserRoleEnum.noOne;}

    get name() {
        switch (this.role) {
            case UserRoleEnum.asesor:
                return "Asesor"
            case UserRoleEnum.gestor:
                return "Gestor"
            case UserRoleEnum.mecanico:
                return "Mecánico"
            case UserRoleEnum.visitante:
                return "Visitante"
            case UserRoleEnum.programming:
                return "Programación"
            case UserRoleEnum.noOne:
                return "--"
        }
    }
}