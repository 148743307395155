<div class="companyFormP">

    
    <div class="df fdc">

        <div class="df jcc aic mt5 mb5 title-save">
            <app-view-title [ngClass]="{'mla' : company}">CONFIGURACIÓN DE EMPRESA</app-view-title>
            <button *ngIf="company" class="save mla" style="width: fit-content;" (click)="makeCall()" mat-button>GUARDAR</button>
        </div>
        <form [formGroup]="companyForm" class="companyForm">
            <div class="left-section" [ngClass]="{'loading' : company == undefined}">
                <app-card [contentLoaded]="company != undefined">
                    <app-card-title [noMarginBottom]="true">SOBRE TU EMPRESA</app-card-title>
                    <div class="picture-text">
                        <lib-file-upload [crop]="true" [maxSize]="8"
                            (stateChanged)="onUploadFile($event)"></lib-file-upload>
                        <!--NOMBRE EMPRESA-->
                        <div>
                            <mat-form-field class="nombreEmpresa" appearance="outline">
                                <mat-label>Nombre empresa</mat-label>
                                <input matInput placeholder="Nombre empresa" formControlName="company_name">
                            </mat-form-field>
                            <p class="nombreEmpresa c_t2 df jcsa" style="margin-top: 5px; cursor: default;"
                                *ngIf="company != undefined;">
                                <span class="company-code"
                                    (click)="clippboard(company.company_code, 'Código de empresa copiado')">
                                    EMPRESA:
                                    <span class="fw500 c_t">{{company.company_code ? company.company_code : "Sin datos"}}</span>
                                    <mat-icon [matTooltip]="'Copiar código de empresa'"
                                        class="icon-codes">content_copy</mat-icon>
                                </span>
                                <span class="client-code"
                                    (click)="clippboard(company.client_code, 'Código de cliente copiado')">
                                    CLIENTE:
                                    <span class="fw500 c_t">{{company.client_code ? company.client_code : "Sin datos"}}</span>
                                    <mat-icon [matTooltip]="'Copiar código de cliente'"
                                        class="icon-codes">content_copy</mat-icon>
                                </span>
                            </p>
                        </div>
    
                    </div>
    
    
                    <!-- COLORES CORPORATIVOS -->
                    <div>
                        <app-card-subtitle>COLORES CORPORATIVOS</app-card-subtitle>
                        <p class="taj">Seleccione los colores corporativos de su empresa.
                            <span> Nos basaremos en estos colores para el envío de correos y la
                                creación de la
                                página para pedir cita.</span>
                        </p>
                        <div class="inputColorParent">
                            <div>
                                <p>Color primario</p>
                                <input formControlName="color1" type="color"
                                    [value]='company? company.getColor1().value : defaultColor() '
                                    style="box-shadow: 5px 3px 15px 0px rgba(0,0,0,0.86);">
                            </div>
                            <div>
                                <p>Color secundario</p>
                                <input formControlName="color2" type="color"
                                    [value]='company? company.getColor2().value: defaultColor()'
                                    style="box-shadow: 5px 3px 15px 0px rgba(0,0,0,0.86);">
                            </div>
                        </div>
                    </div>
    
                    <!-- INFORMACIÓN GENERAL -->
                    <div [ngClass]="{'needsHighlight' : needsHighlight}" (click)="needsHighlight = false">
                        <app-card-subtitle>INFORMACIÓN GENERAL</app-card-subtitle>
                        <p>Rellenar la información general te permitirá obtener <span class="c_p cp fw500"
                                (click)="emitClickProviders()">proveedores</span> para posteriormente utilizar la gestión de
                            anuncios</p>
                        <app-row-two>
                            <app-dni-input [strict]="false" [error]="'Introduce un NIF válido'" class_="w100"
                                [label]="'NIF'" [placeHolder]="'Introduce el NIF'" [required]="false" [form]="companyForm"
                                [formCName]="'nif'"></app-dni-input>
                            <app-email-input [required]="false" class_="w100" [form]="companyForm"
                                [formCName]="'email'"></app-email-input>
                        </app-row-two>
    
                        <app-row-two>
                            <mat-form-field class_="w100" appearance="outline">
                                <mat-label>Dirección</mat-label>
                                <input matInput placeholder="Dirección" formControlName="direccion">
                            </mat-form-field>
                            <mat-form-field class_="w100" appearance="outline">
                                <mat-label>Código postal</mat-label>
                                <input matInput placeholder="Código postal" formControlName="codigo_postal">
                            </mat-form-field>
                        </app-row-two>
    
                        <app-row-two>
                            <app-phone-input [required]="false" class_="w100" [form]="companyForm"
                                [formCName]="'telefono'"></app-phone-input>
                            <app-phone-input [required]="false" class_="w100" [form]="companyForm" [label]="'Teléfono 2'"
                                [required]="false" [formCName]="'telefono_alternativo'"></app-phone-input>
                        </app-row-two>
                    </div>
                </app-card>
            </div>
    
            <div class="right-section" [ngClass]="{'loading' : company == undefined}">
                <!-- INTERVALO -->
                <app-card [contentLoaded]="company != undefined">
                    <app-card-title>CITAS</app-card-title>
                    <app-card-subtitle [first]="true">INTERVALO</app-card-subtitle>
                    <p class="taj">Seleccione el intervalo con la que quiere que los clientes puedan pedir cita.
                        <button style="top: -2px;" type="button" *ngIf="slider.value" (click)="tooltip.toggle()"
                            (mouseenter)="$event.stopImmediatePropagation()"
                            (mouseleave)="$event.stopImmediatePropagation()" mat-icon-button #tooltip="matTooltip"
                            matTooltip="Con la configuración actual, las horas disponibles para los usuarios serian :                 
                                {{getTooltip(slider, select1)}} ..." matTooltipPosition="above">
                            <mat-icon style="color:var(--blue);">info</mat-icon>
                        </button>
                    </p>
                    <p class="tac fsm fwb nmb">{{slider.value}} minutos</p>
                    <mat-slider #slider [max]="30" [min]="5" [step]="5" thumbLabel formControlName="granularity"
                        [value]="company?.granularity">
                        <input matSliderThumb>
                    </mat-slider>
    
                    <app-card-subtitle>MÁXIMO DE CITAS POR DÍA</app-card-subtitle>
                    <p>El número máximo de citas que puedes recibir en un día. Si dejas el valor en 0, <span
                            class="fw500">NO</span> se podrán pedir citas.</p>
                    <mat-form-field class_="w100" appearance="outline">
                        <mat-label>Máximo citas</mat-label>
                        <input matInput type="number" placeholder="Máximo citas" formControlName="total_citas">
                    </mat-form-field>
    
                    <app-card-subtitle>PLAZAS DE TALLER</app-card-subtitle>
                    <p>Indica la cantidad de espacios físicos libres en su taller de motos. De esta manera, podrás saber si
                        hay capacidad para aceptar más motos en su agenda.</p>
                    <mat-form-field class_="w100" appearance="outline">
                        <mat-label>Plazas taller</mat-label>
                        <input matInput type="number" placeholder="Plazas taller" formControlName="capacidad_taller">
                    </mat-form-field>
    
                    <app-card-subtitle>HORARIO</app-card-subtitle>
                    <!--INPUTS DE HORA ENTRADA/SALIDA-->
                    <p>Al establecer el horario de trabajo de su empresa, permitirá que los clientes puedan pedir
                        citas exclusivamente el horario que establezca.</p>
                    <app-row-two>
                        <mat-form-field appearance="outline">
                            <mat-label>Apertura (mañana)</mat-label>
                            <mat-select #select1 formControlName="morning_work_schedule_start" required
                                (selectionChange)="inputChanged($event, 0)">
                                <div *ngFor="let time of COMPANY_HOURS[0]">
                                    <mat-option [value]="time.getValue()">{{time.getText()}}</mat-option>
                                </div>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Cierre (mañana)</mat-label>
                            <mat-select #select2 formControlName="morning_work_schedule_finish" required
                                (selectionChange)="inputChanged($event, 1)">
                                <div *ngFor="let time of COMPANY_HOURS[1]">
                                    <mat-option [value]="time.getValue()">{{time.getText()}}</mat-option>
                                </div>
                            </mat-select>
                        </mat-form-field>
                    </app-row-two>
    
                    <app-row-two>
                        <mat-form-field appearance="outline">
                            <mat-label>Apertura (tarde)</mat-label>
                            <mat-select #select3 formControlName="afternoon_work_schedule_start"
                                (selectionChange)="inputChanged($event, 2)">
                                <mat-option>Sin establecer</mat-option>
                                <div *ngFor="let time of COMPANY_HOURS[2]">
                                    <mat-option [value]="time.getValue()">{{time.getText()}}</mat-option>
                                </div>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Cierre (tarde)</mat-label>
                            <mat-select #select4 formControlName="afternoon_work_schedule_finish"
                                (selectionChange)="inputChanged($event, 3)">
                                <mat-option>Sin establecer</mat-option>
                                <div *ngFor="let time of COMPANY_HOURS[3]">
                                    <mat-option [value]="time.getValue()">{{time.getText()}}</mat-option>
                                </div>
                            </mat-select>
                        </mat-form-field>
                    </app-row-two>
    
                    <!--MARGEN -->
                    <app-card-subtitle>MARGEN</app-card-subtitle>
                    <p class="taj">Seleccione el margen de días con los que los clientes pueden pedir citas.</p>
                    <mat-form-field appearance="outline">
                        <mat-label>Margen de días</mat-label>
                        <mat-select [value]="1" formControlName="range_of_days">
                            <mat-option value="0">Sin margen</mat-option>
                            <mat-option value="1">1 día</mat-option>
                            <mat-option value="2">2 días</mat-option>
                            <mat-option value="3">3 días</mat-option>
                        </mat-select>
                    </mat-form-field>
    
                    <!--CALENDARIO DIAS FESTIVOS-->
                    <app-card-subtitle>DIAS FESTIVOS</app-card-subtitle>
                    <p class="taj">A continuación, introduzca los días festivos. En los días seleccionados, los clientes no
                        podrán pedir citas.</p>
    
                    <app-holidays-calendar [prespective]="'company'" [enabledFor]="enabledCalender" [showLegend]="false">
                    </app-holidays-calendar>
    
                    <!-- URL CITAS-->
                    <app-card-subtitle [noMarginBottom]="true">ENLACE PARA PEDIR CITAS</app-card-subtitle>
                    <mat-form-field style="width: 100%;" appearance="fill">
                        <div class="df">
                            <input matInput [value]="getURL" [disabled]="true">
                            <button mat-icon-button [type]="'button'">
                                <mat-icon (click)="goToPedirCita()" matSuffix>open_in_new</mat-icon></button>
                            <button mat-icon-button [type]="'button'">
                                <mat-icon (click)="copyToClippboard()" matSuffix>content_copy</mat-icon>
                            </button>
                        </div>
                    </mat-form-field>
    
                    <div class="df jcc mt20">
                        <button (click)="makeCall()" mat-button class="save">GUARDAR</button>
                    </div>
    
                </app-card>
    
    
            </div>
    
        </form>
    </div>
    



</div>