<mat-stepper #stepper>
    <mat-step>
        <app-card-title class="tac">¿Que quieres añadir?</app-card-title>

        <div class="parent-container">
            <!-- input.focus() ??-->
            <div class="selectable-button" (click)="stepper.next()">
                <mat-icon>inventory_2</mat-icon>
                <p>ARTÍCULO</p>
            </div>

            <div class="selectable-button" [mat-dialog-close]="'M'">
                <mat-icon>widgets</mat-icon>
                <p>MANUAL</p>
            </div>
        </div>

        <div class="parent-container">
            <div class="selectable-button" [mat-dialog-close]="'X'">
                <mat-icon>home_repair_service</mat-icon>
                <p>TRABAJOS</p>
            </div>

            <div class="selectable-button" [mat-dialog-close]="'C'">
                <mat-icon>chat</mat-icon>
                <p>COMENTARIO</p>
            </div>
        </div>
    </mat-step>
    <mat-step>
        <app-card-title class="tac" [noMarginBottom]="true">Seleccione el artículo</app-card-title>
        <mat-form-field class="w100 mt10 mb10" [appearance]="'outline'">
            <mat-label>Buscar artículo</mat-label>
            <input #input matInput [formControl]="fc">
        </mat-form-field>
        <mat-dialog-content>
                <p *ngFor="let a of matches" class="product-item" [mat-dialog-close]="a" [ngClass]="{'disabled': a.stock <= 0 && a.ControlStock}">
                    <mat-icon class="vam mr5">widgets</mat-icon>
                    <span [ngClass]="{'cursive' : !a.Denominacion1p}">{{a.Denominacion1p ? a.Denominacion1p : '"Sin denominación"'}}</span> 
                    <span class="ref">  {{a.Ref}} </span>
                    <span class="c_p fw500" *ngIf="a.ControlStock">({{a.stock}})</span>
                    <span class="c_p fw500" *ngIf="!a.ControlStock">(Sin control de stock)</span>
                    <!--<span class="c_p fw500">({{a.Dto}})</span>!-->
                </p>
        </mat-dialog-content>
        <button mat-stroked-button (click)="stepper.previous()"><mat-icon>arrow_back</mat-icon> Atrás </button>
    </mat-step>
</mat-stepper>