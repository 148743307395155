import { Injectable } from '@angular/core';
import { WorkloadData, WorkloadInterface } from '@sinigual/angular-lib';
import { ViewPath } from 'src/app/modules/app-routing.module';
import { apiService } from '../api/api-service';
import { endpoints } from '../api/Endpoints';
import { feature } from '../features-controller/FeaturesController';
import { ProfileService } from 'src/app/views/profile/profileService';

@Injectable({
  providedIn: 'root'
})
export class WorkloadService {
  workloadData!: WorkloadData;

  constructor(private apiS: apiService, private profileS : ProfileService) {
   this.refresh();
  }

  refresh(){
    var configuration : WorkloadInterface =
    {
      switch: feature.workloadOr,
      defaultView : feature.workloadOr ? "or" : "appointment",
      general: {
        users: this.apiS.usersWorkers.bind(this.apiS),
        company: this.apiS.getCompanyInfo.bind(this.apiS),
      },
      or: {
        schedules: this.apiS.schedules.bind(this.apiS),
        updateSchedule: this.profileS.mecanico ? undefined : endpoints.updateSchedule,
        reorderAction: undefined,
        assignAction: this.profileS.mecanico ? undefined : endpoints.assignOR,
        userPermission : this.profileS.asesor || this.profileS.gestor || this.profileS.mecanico,
        showContact : this.profileS.gestorasesor,
        views: {
          createOr: undefined,
          editOr: ViewPath.workOr,
        },
        draggable : {
          day : false,
          week : !this.profileS.mecanico,
          month : !this.profileS.mecanico,
        }
      },
      appointments: {
        appointments: this.apiS.appointments.bind(this.apiS),
        updateAppointment: endpoints.changeAppointmentDate,
        userPermission : this.profileS.asesor || this.profileS.gestor,
        views: {
          createAppointment: ViewPath.crearCita,
          editAppointment: undefined,
        },
        draggable : {
          day : false,
          week : true,
          month : true,
        }
      },

    }
    this.workloadData = new WorkloadData(configuration);
  }

  get wd() {
      return this.workloadData;
    }
  }
