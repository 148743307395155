import { Component, OnInit } from '@angular/core';
import { Filter, FilterOption, ImagePreviewService, RoleService, RouterService, SearchService, TagFilter } from '@sinigual/angular-lib';
import { forkJoin } from 'rxjs';
import { apiService } from 'src/app/core/api/api-service';
import { displayMode } from 'src/app/core/enums/DisplayMode';
import { Motor } from 'src/app/core/enums/Motor';
import { M_Stock } from 'src/app/core/models/M_Stock';
import { M_Provider } from 'src/app/core/models/M_Provider';
import { ViewPath } from 'src/app/modules/app-routing.module';
import { ProfileService } from '../profile/profileService';


@Component({
  selector: 'app-lanzadera',
  templateUrl: './lanzadera.component.html',
  styleUrls: ['./lanzadera.component.css']
})
export class LanzaderaComponent implements OnInit {
  allViews = ViewPath;
  displayEnum = displayMode;
  displayMode: displayMode = displayMode.grid;
  loaded = false;

  /** Array de todas las motos */
  adds: M_Stock[] = [];

  displayed_add_array: M_Stock[] = [];

  searchedStr: string = "";
  companyProviders: M_Provider[] = []

  filters = [
    new TagFilter("Estado de la publicación", undefined, new FilterOption("Publicada"), new FilterOption("No publciada"), new FilterOption("Vendida")),
    //new TagFilter("Tipo de combustible", undefined, new FilterOption("Gasolina"), new FilterOption("Eléctrica"))
  ]

  constructor(private apiS: apiService, public searchS: SearchService, public routerS: RouterService, public profileS: ProfileService, public previewS : ImagePreviewService) {}

  ngOnInit(): void {
    const a = this.apiS.getAllAdds();
    const b = this.apiS.getCompanyProviders();
    forkJoin([a, b]).subscribe(res => {
      this.processResponse(res[0]);
      this.companyProviders = res[1];
      this.loaded = true;
    })
  }

  processResponse(resp: M_Stock[]) {
    this.adds = resp;
    this.displayed_add_array = this.adds;
  }

  filterVO(f: Filter[]) {
    this.displayed_add_array = [];
    this.adds.forEach(add => {
      let publicationOK = true;
      let engineTypeOk = true;
      f.forEach((f, index) => {
        if (f.activated) {
          if (f instanceof TagFilter && index == 0) {
            var arrayNum = [];
            if(add.add?.hasProviders) {arrayNum.push(0)}
            if(!add.add?.hasProviders) {arrayNum.push(1)}
            if(add.sold) {arrayNum.push(2)}
            publicationOK = f.checkFilter(arrayNum)
          }
          else if (f instanceof TagFilter && index == 1) {
            engineTypeOk = f.checkFilter(add.TiMotor == Motor.gasolina ? [0] : [1])
          }
        }
      })
      if (publicationOK && engineTypeOk) {
        this.displayed_add_array.push(add)
      }
    })
  }

  applyFilter(filter?: string) {
    this.displayed_add_array = this.adds;
    this.filterVO(this.filters);
    if (filter != undefined && typeof filter == "string"){
      this.searchedStr = filter;
    }
    if (this.displayed_add_array.length != 0) {
      this.exludeNotMatchingAdds(this.searchedStr)
    }
  }


  private exludeNotMatchingAdds(val: string) {
    let finalArray: M_Stock[] = [];

    for (let i = 0; i < this.displayed_add_array.length; i++) {
      if (this.addMatch(val, this.displayed_add_array[i])) {
        finalArray.push(this.displayed_add_array[i]);
      }
    }

    this.displayed_add_array = finalArray;
  }

  addMatch(val: string, stock: M_Stock): boolean {
    return this.searchS.match(val, stock.add?.precio ? stock.add.precio.toString() : '', stock.add?.titulo ? stock.add?.titulo : '', stock.Matricula, stock.Kms.toString())
  }

  changeDisplayMode(event: any) {
    event.stopPropagation();
    this.displayMode = this.displayMode == displayMode.grid ? displayMode.row : displayMode.grid;
  }

  goConfig() {
    this.routerS.goToWithState(this.allViews.configuration, 1)
  }

  onRemove(stock : M_Stock){
    this.adds.removeElement(stock);
    this.displayed_add_array.removeElement(stock);
  }

}
