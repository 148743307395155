import { ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CalendarService, CustomColor, CustomDate, CustomFile, CustomTime, ErrorDialogService, FileUploadComponent, FormService, SnackService, toFormData } from '@sinigual/angular-lib';
import { apiService } from 'src/app/core/api/api-service';
import { feature } from 'src/app/core/features-controller/FeaturesController';
import { M_Company } from 'src/app/core/models/M_Company';
import { M_ProfilePicture } from 'src/app/core/models/M_ProfilePicture';
import { TimeService } from 'src/app/core/services/time.service';
import { ViewPath } from 'src/app/modules/app-routing.module';
import { environment } from 'src/environments/environment';
import { ProfileService } from '../profile/profileService';
import { ConfigurationComponent } from '../configuration/configuration.component';
import { MatSlider } from '@angular/material/slider';
import { HolidaysCalendarComponent } from 'src/app/components/holidays-calendar/holidays-calendar.component';
import { calendarEnabled } from 'src/app/constants/constants';

@Component({
  selector: 'app-edit-company-info',
  templateUrl: './edit-company-info.component.html',
  styleUrls: ['./edit-company-info.component.css']
})
export class EditCompanyInfoComponent implements OnInit {
  env = environment;
  f = feature;
  granularity: number[] = [5, 15, 30]
  @ViewChild('select4') select_ca!: MatSelect;
  @ViewChild('select1') select_om!: MatSelect;
  @ViewChild('select2') select_cm!: MatSelect;
  @ViewChild('select3') select_oa!: MatSelect;
  @ViewChild(FileUploadComponent) imageUploadC!: FileUploadComponent;
  @ViewChild(HolidaysCalendarComponent) hCalendar!: HolidaysCalendarComponent;
  @Output() onProvidersInfo: EventEmitter<any> = new EventEmitter();
  needsHighlight = false;
  loaded = false;

  public companyForm: UntypedFormGroup;
  COMPANY_HOURS: [CustomTime[], CustomTime[], CustomTime[], CustomTime[]] = [[], [], [], []]
  selected: Date | null | undefined;
  todayDate: Date = new Date();
  selectsArray: MatSelect[] = []
  company: M_Company | undefined;
  enabledCalender = calendarEnabled;
  constructor(
    @Inject(ConfigurationComponent) public configComponent: ConfigurationComponent,
    private cdRef: ChangeDetectorRef, public profileS: ProfileService, public cs: CalendarService, private errorDialog: ErrorDialogService,
    private formBuilder: UntypedFormBuilder, private apiService: apiService, private timeService: TimeService, private d: MatDialog,
    private snackService: SnackService, private formService: FormService, private router: Router) {

    this.companyForm = this.formBuilder.group({

      //INFROMACIÓN GENERAL
      email: [''],
      nif: [''],
      direccion: [''],
      codigo_postal: [''],
      telefono: [''],
      telefono_alternativo: [''],
      company_name: ['', [Validators.required]],
      company_logo: [''],
      total_citas: [''],
      capacidad_taller: [''],
      morning_work_schedule_start: ['', [Validators.required]],
      morning_work_schedule_finish: ['', [Validators.required]],
      afternoon_work_schedule_start: [''],
      afternoon_work_schedule_finish: [''],
      range_of_days: ['1', [Validators.required]],
      granularity: ['', [Validators.required]],
      day_holiday: [''],
      color1: [''],
      color2: ['']
    });
  }

  ngOnInit(): void {
    console.log("sd")
  }

  emitClickProviders() {
    this.onProvidersInfo.emit();
  }

  get canActivateProviders() {
    return this.company != undefined &&
      this.company.nif != undefined &&
      this.company.email != undefined &&
      this.company.direccion != undefined &&
      this.company.codigo_postal != undefined &&
      this.company.telefono != undefined;
  }

  ngAfterViewInit() {
    this.apiService.getCompanyInfo().then(response => this.procesResponse(response))
    this.selectsArray = [this.select_om, this.select_cm, this.select_oa, this.select_ca];
    this.cdRef.detectChanges();
  }

  goToPedirCita() {
    window.open(this.getURL, '_blank');
    //this.router.navigate([ViewPath.pedirCita.path], { queryParams: { companyId: this.company?.hash } });
  }

  formatAndSetMultipleDates(data: M_Company) {
    var d: CustomDate[] = []
    for (let i = 0; i < data.day_holiday.length; i++) {
      d.push(new CustomDate(data.day_holiday[i].value!))
    }
    this.hCalendar.initComponent({ companyH: d, userH: [] });
  }

  makeCall() {
    if (this.formService.isOk(this.companyForm)) {
      this.companyForm.patchValue({
        'company_logo': this.imageUploadC.hasFiles() ? this.imageUploadC.getCustomFile()!.src : "",
      });
      let editedCompany: M_Company = new M_Company(this.companyForm.value);
      editedCompany.company_code = this.company?.company_code;
      editedCompany.client_code = this.company?.client_code;
      editedCompany.hash = this.company!.hash;
      editedCompany.company_logo = this.imageUploadC.hasFiles() ? this.imageUploadC.getCustomFile()!.src : "";
      editedCompany.day_holiday = this.hCalendar.companyHolidays;
      editedCompany.colors = [new CustomColor(this.companyForm.get('color1')!.value), new CustomColor(this.companyForm.get('color2')!.value)]
      let config = new MatSnackBarConfig();
      config.duration = 1000;
      config.panelClass = "snack-dialog"
      this.apiService.setCompanyInfo(editedCompany).then(
        _res => {
          this.snackService.show("Datos de empresa guardados")
          this.hCalendar.holidayChanges = false;
          this.company = editedCompany;
          if (this.profileS.clientData) {
            this.profileS.clientData.company_holidays = editedCompany.day_holiday;
          }
        }
      );
    }
  }

  onUploadFile(f: CustomFile) {
    this.apiService.setCompanyImg(toFormData(new M_ProfilePicture(f.file!)));
  }

  procesResponse(response: M_Company): any {
    this.company = response;
    if (this.company.company_logo) {
      this.imageUploadC.setImageFromDatabase(this.company.company_logo);
    }


    this.formatAndSetMultipleDates(this.company);
    this.companyForm.patchValue({ 'range_of_days': this.company.range_of_days })

    this.companyForm.patchValue({
      'email': this.company.email,
      'nif': this.company.nif,
      'direccion': this.company.direccion,
      'codigo_postal': this.company.codigo_postal,
      'telefono': this.company.telefono,
      'telefono_alternativo': this.company.telefono_alternativo,
    })

    this.companyForm.patchValue({
      'company_name': this.company.company_name,
      'total_citas': this.company.total_citas,
      'capacidad_taller': this.company.capacidad_taller,
      'morning_work_schedule_start': this.company.morning_work_schedule_start,
      'morning_work_schedule_finish': this.company.morning_work_schedule_finish,
      'afternoon_work_schedule_start': this.company.afternoon_work_schedule_start,
      'afternoon_work_schedule_finish': this.company.afternoon_work_schedule_finish,
      'granularity': this.company.granularity ? this.company.granularity.toString() : 15,
      'color1': this.company.getColor1().value,
      'color2': this.company.getColor2().value,
    })

    this.generateInputs();
  }

  getTooltip(slider: MatSlider, apertura: MatSelect) {
    let str = "";
    let startTime = apertura.value;
    for (let i = 0; i <= 3; i++) {
      str += this.timeService.parseNumberToHour(startTime).getText() + (i == 3 ? '' : ", ");
      startTime += Number(slider.value);
    }
    return str;
  }

  get getURL() {
    return document.location.protocol + "//" + window.location.hostname + (this.env.local ? ':4200' : '') + '/pedircita?companyId=' + this.company?.hash
  }

  defaultColor() {
    return new CustomColor().value
  }

  copyToClippboard() {
    navigator.clipboard.writeText(this.getURL);
    this.snackService.show("URL copiada en el portapapeles")
  }

  clippboard(d: string | number | undefined, result: string) {
    if (d) {
      navigator.clipboard.writeText(d.toString());
      this.snackService.show(result)
    }
  }

  formatLabel(value: number): string {
    return value + 'm.';
  }

  getStartHour(i: number) {
    if (i == 0 || !this.selectsArray[i - 1].value) { return 0 }
    console.log(this.selectsArray[i - 1].value)
    return this.selectsArray[i - 1].value;
  }

  generateInputs() {
    let maxHour = 60 * 24;
    let granularity = 30;
    for (let i = 0; i < this.selectsArray.length; i++) {
      let hoursavailable: CustomTime[] = []
      let startHour = this.getStartHour(i);
      for (let i = startHour; i < maxHour; i += granularity) {
        if (i < maxHour) {
          hoursavailable.push(this.timeService.parseNumberToHour(i));
        }
      }
      this.COMPANY_HOURS[i] = hoursavailable;
    }
  }


  inputChanged(evnt: MatSelectChange, selectId: number) {
    this.correctProgressiveBigger();
    this.generateInputs();
    this.correctRequired();
  }


  get someAfternoon(){
    if (this.selectsArray.length){
      return this.selectsArray[2].value || this.selectsArray[3].value
    }
    return false;
  }

  correctRequired() {
    var f1 = this.companyForm.get('afternoon_work_schedule_start');
    var f2 = this.companyForm.get('afternoon_work_schedule_finish');

    if(this.someAfternoon){
      f1?.setValidators(Validators.required);
      f2?.setValidators(Validators.required);
    }
    else{
      f1?.removeValidators(Validators.required);
      f2?.removeValidators(Validators.required);
    }

    f1?.updateValueAndValidity();
    f2?.updateValueAndValidity();
    this.cdRef.detectChanges();
  }

  /** If the company shcedule is correct like*/
  correctProgressiveBigger() {
    var ok = true;
    for (let i = 0; i < this.selectsArray.length; i++) {
      if (i != 0) {
        if (this.selectsArray[i - 1].value > this.selectsArray[i].value || this.selectsArray[i - 1].value == undefined) {
          ok = false;
        }
        if (ok == false) {
          this.selectsArray[i].value = "";
        }
      }
    }
    return ok;
  }

}