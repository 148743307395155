import { ChangeDetectorRef, Component, QueryList, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ConfirmData, ConfirmDialogService, M_BaseUser, ParameterStateComponent, ResponsiveService, RoleService, RouterService, or_status_close } from '@sinigual/angular-lib';
import { apiService } from 'src/app/core/api/api-service';
import { SiniwinOrStatus } from 'src/app/core/enums/SiniwinOrStatus';
import { M_OR } from 'src/app/core/models/M_OR';
import { ProfileService } from '../profile/profileService';
import { HistoryTimeOrDialogComponent } from 'src/app/components/history-time-or-dialog/history-time-or-dialog.component';
import { AddTimeOrDialogComponent } from 'src/app/components/add-time-or-dialog/add-time-or-dialog.component';
import { forkJoin } from 'rxjs';
import { M_Article } from 'src/app/core/models/M_Article';
import { LineTypeSelectorComponent } from 'src/app/components/line-type-selector/line-type-selector.component';
import { M_OR_Group } from 'src/app/core/models/M_OR_Group';
import { ViewPath } from 'src/app/modules/app-routing.module';
import { I_CanDeactivate } from 'src/app/interfaces/I_CanDeactivate';
import { OrLineComponent } from 'src/app/components/or-line/or-line.component';
import { OrTimerService } from 'src/app/services/or-timer.service';
import { M_User } from 'src/app/core/models/M_User';




@Component({
  selector: 'app-imputar-tiempo-or',
  templateUrl: './imputar-tiempo-or.component.html',
  styleUrls: ['./imputar-tiempo-or.component.css']
})
export class ImputarTiempoOrComponent extends ParameterStateComponent implements I_CanDeactivate {

  or: M_OR | undefined;
  articles: M_Article[] = [];
  workers: M_BaseUser[] = [];
  v = ViewPath;

  @ViewChildren(OrLineComponent) orLinesComponents: QueryList<OrLineComponent> | undefined;

  /** I_CanDeactivate interface */
  userClickedExit = false;

  constructor(routerS: RouterService, route: ActivatedRoute, private timeS: OrTimerService, private apiS: apiService, private d: MatDialog, public profileS: ProfileService, private dialogS: ConfirmDialogService, private chDref: ChangeDetectorRef, public responsiveS: ResponsiveService) {
    super(routerS, route, ["or", "matricula"]);
    this.autoRefeshTime();
  }

  userHasHolidays(u: M_BaseUser) {
    if (this.or?.schedule == undefined) { return false };
    return u.holidays.some(d => d.isEquals(this.or!.schedule!))
  }

  onClickUser(u: M_BaseUser) {
    this.apiS.assignUser(this.or!, u).then(res => {
      this.or!.assigned = u;
    });
  }

  refreshFromError() {
    if (this.or) {
      const id = this.or.id;
      this.or = undefined;
      this.onParams([{ param: "or", value: id.toString() }]);
      this.timeS.makeCall();
    }
  }

  /** I_CanDeactivate interface */
  get changes(): boolean {
    if (!this.or) { return false }
    return this.or?.groups.some(g => g.changes);
  }

  get totalFinishedErrors() {
    var totalErrors = 0;
    if (this.unsavedChanges) { totalErrors += 1 }
    if (this.isOperatorWorking()) { totalErrors += 1 }
    return totalErrors;
  }

  get closingErrors(): string {
    if (this.unsavedChanges && this.isOperatorWorking()) {
      return "Debes guardar los cambios y detener la imputación de tiempo antes de finalizar la OR";
    }
    else if (this.unsavedChanges) {
      return "Debes guardar los cambios antes de finalizar la OR"
    }
    else if (this.isOperatorWorking()) {
      return "Detén la imputación de tiempo antes de finalizar la OR"
    }
    return ""
  }

  override onParams(params: { param: string; value: string; }[]): void {
    var plate = params.find(p => p.param == "matricula")?.value;
    var orID: string | number | undefined = params.find(p => p.param == "or")?.value;

    if (orID) {
      orID = typeof orID == "string" ? orID.getNumber() : orID;
      const a = this.apiS.orByid(orID, plate);
      const b = this.apiS.usersWorkers();
      const c = this.apiS.showArticulos();
      forkJoin([a, b, c]).subscribe(res => {
        this.or = res[0];
        this.workers = res[1];
        this.articles = res[2];
      })
    }

  }
  updateValidityOfAllInputs() {
    this.orLinesComponents?.forEach(g => {
      if (g.g.isArticle) {
        g.quantity.updateValueAndValidity();
      }
    })
  }

  /** Close  the current OR */
  closeOR() {
    if (!this.or || !this.or.canEdit) { return }

    /** CANNO'T CLOSE (SCREEN ERRORS) */
    if (this.closingErrors) {
      this.dialogS.show(this.ERRORS_CLOSING);
      return;
    }

    /** USER CAN CLOSE THE OR */
    this.dialogS.show(this.SURE_CLOSE).afterClosed().subscribe(res => {
      /** User cancel the dialog */
      if (!res) { return }

      /** Calling endpoint. User accept closing the or.*/
      this.apiS.closeOr(this.or!.id)
        .then(res => {
          /** Set the page OR to closed. */
          if (res == true) { this.finishOR(); }
          /** Somone is working on the OR */
          else { this.isSomoneWorkingOnOR(); }
        })
        .catch(e => {
          /** The api call returns a error (400, 500...) */
          this.showRefreshObligation(e);
        })
    })
  }

  /** If is somone working on the OR, show dialogs based on the current user role */
  isSomoneWorkingOnOR() {
    /** IF the user is gestor or asesor, they can 'force close' the or finishing the current working times */
    if (this.profileS.gestorasesor) {
      this.dialogS.show(this.SOMONE_WORKING_ASESOR).afterClosed().subscribe(res => {
        if (res == true) {
          this.apiS.forceCloseOr(this.or?.id!).then(res => {
            this.finishOR();
          })
        }
      })
    }
    /** Alert user that is somone working on the OR */
    else {
      this.dialogS.show(this.SOMONE_WORKING);
    }
  }

  finishOR() {
    this.or!.siniwin_status = SiniwinOrStatus.FINALIZADA_CLOUD;
    this.or!.status = or_status_close;
    this.orLinesComponents?.forEach(orComponent => {
      orComponent.disable();
    })
    this.timeS.end(this.or);
  }


  autoRefeshTime() {
    setTimeout(() => {
      this.chDref.detectChanges();
      this.autoRefeshTime();
    }, 1000)

  }

  ngAfterContentChecked() {
    this.chDref.detectChanges();
  }


  startStopTime() {
    if (this.or && this.or.canEdit && this.profileS.isWorker) {
      const isworking = this.isOperatorWorking();
      if (isworking) {
        this.apiS.endTimeOperator(isworking.id)
          .then(res => { this.or?.closeTime(isworking!.id); this.timeS.end(this.or); })
          .catch(e => { this.showRefreshObligation(e); });
      }
      else {
        this.apiS.startTimeOperator(this.or!.id)
          .then(res => { this.or?.addTime(res); this.timeS.start(this.or); })
          .catch(e => {
            this.showRefreshObligation(e);
          });
      }
    }
  }

  isOperatorWorking() {
    return this.or?.isOperatorWorking(this.profileS.clientData?.operario_id);
  }

  timeHistory() {
    var w = (this.responsiveS.w < 448 ? "95vw" : undefined);
    this.d.open(HistoryTimeOrDialogComponent, { data: { or: this.or, workers: this.workers, canRemove: this.or?.canEdit }, minWidth: w, autoFocus: false, restoreFocus: false }).afterClosed().subscribe(res => {
      if (res instanceof Error) {
        this.showRefreshObligation(res.message)
      }
    })
  }

  addTime() {
    this.d.open(AddTimeOrDialogComponent, { data: { or: this.or, workers: this.workers, assigned: this.or?.assigned }, autoFocus: false, restoreFocus: false }).afterClosed().subscribe(res => {
      if (res instanceof Error) {
        this.showRefreshObligation(res.message)
      }
    })
  }

  /** When a API call returns a error, force user to refresh the page */
  showRefreshObligation(e?: string) {

    let message = e ? e : "Probablemente se deba a que Siniwin ya se ha bajado los cambios";

    let v: ConfirmData = {
      title: "Se necesita actualizar la página",
      body: message,
      confirmTxt: "Actualizar la página",
      showCancel: false,
      disableClose: true,
      type: "warn"
    }
    this.dialogS.show(v).afterClosed().subscribe(res => {
      if (res) {
        this.refreshFromError();
      }
    });
  }

  get canAddLine() {
    if (this.or == undefined) { return false }
    return this.or.canEdit && this.profileS.gestorasesor;
  }

  get canAddManual() {
    if (this.or == undefined) { return false }
    return this.or.canEdit && this.profileS.gestorasesor;
  }

  openLineTypeDialog() {
    this.d.open(LineTypeSelectorComponent, { data: { articles: this.articles, or: this.or } }).afterClosed().subscribe(res => {
      if (res == "M") {
        this.or!.addLine().Grupo = "M";
      }
      if (res == "X") {
        this.or!.addLine().Grupo = "X";
      }
      if (res == "C") {
        this.or!.addLine().Grupo = "C";
      }
      if (res instanceof M_Article) {
        var g = this.or!.addLine();
        g.Grupo = "A";
        g.addArticle(res)
      }
    })
  }

  onDeleteLine(g: M_OR_Group) {
    this.apiS.removeLine(g.detalle_id)
      .then(res => {
        this.or!.deleteLine(g);
        this.updateValidityOfAllInputs();
      })
      .catch(e => {
        this.showRefreshObligation(e);
      })
  }


  /** Get the total stock of one article
   * @param article_id The ID of the article
   * @returns The total stock
   */
  getStockOf(article_id: number | undefined): number {

    var art = this.articles.find(art => art.id == article_id);
    var groupsWithArt = this.or?.groups.filter(g => g.article_id == article_id);

    if (!art) { return 0; }

    var total = art.stock;
    groupsWithArt?.forEach(g => {
      total -= g.Cantidad;
    })

    return total;
  }

  /** Get the minimum stock of a product */
  getMinimumStock(article_id: number | undefined) {
    var art = this.articles.find(art => art.id == article_id);
    if (!art) { return 0; }
    return art.StockMinimo;
  }

  getStockControl(article_id: number | undefined) {
    var art = this.articles.find(art => art.id == article_id);
    if (!art) { return false }
    return art.ControlStock;
  }

  get unsavedChanges() {
    return this.or?.groups.some(g => g.changes == true)
  }

  get SOMONE_WORKING(): ConfirmData {
    return {
      title: "Imputación de tiempo en curso",
      body: "No se puede cerrar la OR, hay trabajos en curso",
      type: "danger",
      showCancel: false,
      confirmTxt: "OK"
    }
  }

  get SOMONE_WORKING_ASESOR(): ConfirmData {
    return {
      title: "Imputación de tiempo en curso",
      body: "¿Quieres detener las imputaciones en curso y cerrar la OR?",
      type: "warn"
    }
  }

  get SURE_CLOSE(): ConfirmData {
    return { title: "¿Quieres cerrar la OR?", body: "Una vez cerrada la OR, no se podrá abrir de nuevo", confirmTxt: "OK" }
  }

  get ERRORS_CLOSING(): ConfirmData {
    return { title: "NO PUEDES FINALIZAR LA OR", body: this.closingErrors, showCancel: false, type: "danger", confirmTxt: "OK" };
  }

}
