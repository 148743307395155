import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogService, RouterService } from '@sinigual/angular-lib';
import { apiService } from 'src/app/core/api/api-service';
import { displayMode } from 'src/app/core/enums/DisplayMode';
import { Motor } from 'src/app/core/enums/Motor';
import { M_Stock } from 'src/app/core/models/M_Stock';
import { ViewPath } from 'src/app/modules/app-routing.module';

@Component({
  selector: 'app-add-card',
  templateUrl: './add-card.component.html',
  styleUrls: ['./add-card.component.css']
})
export class AddCardComponent implements OnInit {
  index = 0;
  dm = displayMode;
  motor = Motor;
  sold = false;
  @Input() bike!: M_Stock;
  @Input() displayMode!: displayMode;
  @Output() onRemove: EventEmitter<M_Stock> = new EventEmitter();


  constructor(private routerS: RouterService, private chdRef: ChangeDetectorRef, private d: MatDialog, private apiS: apiService, private confirmDialogS: ConfirmDialogService) {
  }

  ngOnInit(): void {
  }

  get isColumn() {
    return this.displayMode == this.dm.grid;
  }

  get published() {
    return this.bike.add?.hasProviders;
  }

  openDeleteDialog() {
    this.confirmDialogS.show({ title: "¿Eliminar stock?", body: "Se eliminarán también las publicaciones relacionadas con este stock.",  type: "danger", confirmTxt: "OK" })
    .afterClosed().subscribe(res => {
      if (res) {
        this.apiS.deleteStock(this.bike.id).then(_res => {
          this.onRemove.emit(this.bike);
        });
      }
    })
  }

  goLeft(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.index != 0) {
      this.index--
      this.chdRef.detectChanges();
    }
  }

  goRigth(event: Event) {
    if (this.bike.add) {
      event.preventDefault();
      event.stopPropagation();
      if (this.index != this.bike.add.fotos.length - 1) {
        this.index++;
        this.chdRef.detectChanges();
      }
    }
  }

  leftDisabled() {
    if (!this.bike.add) { return "disabled" }
    return this.index == 0 || this.bike.add.fotos.length == 0 ? "disabled" : "enabled";
  }

  rigthDisabled() {
    if (!this.bike.add) { return "disabled" }
    return this.index == this.bike.add.fotos.length - 1 || this.bike.add.fotos.length == 0 ? "disabled" : "enabled";
  }

  goToEdit() {
    if (!this.bike.sold) {
      this.routerS.goWithQueryParams(ViewPath.editstock, { id: this.bike.id })
    }
  }

}
