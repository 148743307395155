<h1 mat-dialog-title>EDITAR USUARIO</h1>
<div mat-dialog-content>
    <lib-file-upload (stateChanged)="pictureChangred()"></lib-file-upload>
    <div class="editData">
      <form [formGroup]="editForm" class="createEditForm onboarading-users-form">
        <mat-form-field appearance="outline">
          <mat-label>Nombre</mat-label>
          <input matInput type="text" formControlName="name" [value]="data[0]?.name">
        </mat-form-field>
        
        <app-email-input [form]="editForm" formCName="email"></app-email-input>
        <p *ngIf="emailError" class="c_r">Se debe establecer desde Siniwin</p>

        <mat-form-field appearance="outline" *ngIf="(data[0] && !data[0].role.gestor)">
          <mat-label>Rol</mat-label>
          <mat-select formControlName="role" [value]="data[0]? data[0].role.role : ''">
              <div *ngFor="let role of allRoles">
                <mat-option [value]="role.role">{{role.name}}</mat-option>
              </div>
          </mat-select>
        </mat-form-field>
      </form>
    </div>
</div>
<div mat-dialog-actions [align]="'center'">
  <button mat-button [mat-dialog-close]="false">Cancelar</button>
  <button mat-button (click)="this.makeCall()" class="onboarading-users-form-crate">{{data[0]? "Guardar" : "Crear"}}</button>
</div>