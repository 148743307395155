
/** Este archivo no se puede mover del directoro actual
 * En caso contratio, algunos comandos tipo 'ng genrate component...' dejaran de funcionar
 */
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule, ViewPath } from './modules/app-routing.module';
import { EntryPointComponent } from './views/app-root/entry.point.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { TallerComponent } from './views/taller/taller.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';

import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { SectionTitleComponent } from './views/taller/section-title/section-title.component';
import { EstadoTallerComponent } from './views/taller/estado-taller/estado-taller.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { CargaTallerComponent } from './views/taller/carga-taller/carga-taller.component';
import { NgApexchartsModule } from "ng-apexcharts";
import { MatTableModule } from '@angular/material/table';
import { BuscdorMatriculaComponent } from './views/buscdor-matricula/buscdor-matricula.component';
import { MatriculaResultsComponent } from './views/buscdor-matricula/matricula-results/matricula-results.component';
import { CrearRecepcionComponent } from './views/crear-recepcion/crear-recepcion.component';
import { MatDialogModule } from '@angular/material/dialog';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { DatosClienteComponent } from './views/crear-recepcion/datos-cliente/datos-cliente.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatChipsModule } from '@angular/material/chips';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EstadoTallerCardComponent } from './views/taller/estado-taller/estado-taller-card/estado-taller-card.component';
import { MatBadgeModule } from '@angular/material/badge';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatriculaResultsEmptyComponent } from './views/buscdor-matricula/matricula-results-empty/matricula-results-empty.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { LOCALE_ID } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { CrearCitaComponent } from './views/citas/Mecanico/crear-cita/crear-cita.component';
import { HourPickerComponent } from './components/hour-picker/hour-picker.component';
import { EditCompanyInfoComponent } from './views/edit-company-info/edit-company-info.component';
import { EditarCitaClienteComponent } from './views/citas/Rider/editar-cita-cliente/editar-cita-cliente.component';
import { MatTreeModule } from '@angular/material/tree';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatListModule } from '@angular/material/list';
import { CrearDatosClienteComponent } from './views/crear-recepcion/crear-datos-cliente/crear-datos-cliente.component';
import { LoginComponent } from './views/login/login.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { HistorialRecepcionesComponent } from './views/hisotrial-recepciones/historial-recepciones.component';
import { ProfileComponent } from './views/profile/profile.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CitaResultClienteDialogComponent } from './views/citas/Rider/cita-result/cita-result-cliente-dialog/cita-result-cliente-dialog.component';
import { FaqsComponent } from './views/dashboard/faqs/faqs/faqs.component';
import { CustomDateAdapter } from './adapter/CustomDataAdapter';
import { PhoneMenuComponent } from './components/phone-menu/phone-menu.component';
import { DatosClienteLineComponent } from './views/crear-recepcion/datos-cliente/datos-cliente-line/datos-cliente-line.component';
import { CardLineComponent } from './components/card-line/card-line.component';
import { PlateScanComponent } from './components/camera-preview/plate-scan.component';
import { UsersComponent } from './views/users/users.component';
import { DeleteUserComponent } from './views/users/delete-user/delete-user.component';
import { ChangePasswordComponent } from './views/change-password/change-password.component';

import { MatStepperModule } from '@angular/material/stepper';
import { CreateEditUserComponent } from './views/users/create-edit-user/create-edit-user.component';
import { environment } from 'src/environments/environment';
import { endpoints } from './core/api/Endpoints';
import { AngularLibModule, CoreConfiguration, CustomErrorHandler } from '@sinigual/angular-lib';
import { LanzaderaComponent } from './views/lanzadera/lanzadera.component';
import { AddCardComponent } from './views/lanzadera/add-card/add-card.component';
import { EditAddComponent } from './views/edit-add/edit-add.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FichaTecnicaComponent } from './views/edit-add/accordion-cards/ficha-tecnica/ficha-tecnica.component';
import { PublicarAnuncioComponent } from './views/edit-add/accordion-cards/publicar-anuncio/publicar-anuncio.component';
import { SubirFotografiaComponent } from './views/edit-add/accordion-cards/subir-fotografia/subir-fotografia.component';
import { ImageToggleComponent } from './components/image-toggle/image-toggle.component';
import { ImgToggleItemComponent } from './components/image-toggle/img-toggle-item/img-toggle-item.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ProviderSelectorComponent } from './views/provider-selector/provider-selector.component';
import { MatTabsModule } from '@angular/material/tabs';
import { ConfigurationComponent } from './views/configuration/configuration.component';
import { PricingComponent } from './views/pricing/pricing.component';
import { PricingCardComponent } from './views/pricing/pricing-card/pricing-card.component';
import { RowThreeComponent } from './components/responsive/row-three/row-three.component';
import { RowTwoComponent } from './components/responsive/row-two/row-two.component';
import { RowComponent } from './components/responsive/row/row.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { SectionSubtitleComponent } from './views/taller/section-subtitle/section-subtitle.component';
import { CompanyCalendarEeComponent } from './views/edit-company-info/company-calendar-ee/company-calendar-ee.component';
import { CreateCitaClienteComponent } from './views/citas/Rider/create-cita-cliente/create-cita-cliente.component';
import { EditCitaContentComponent } from './views/citas/Rider/editar-cita-cliente/contents/edit-cita-content.component';
import { CommentPedirCitaComponent } from './views/citas/Rider/editar-cita-cliente/Commentarios/comment-pedir-cita/comment-pedir-cita.component';
import { CreateCitaClientEmailSendComponent } from './views/citas/Rider/create-cita-cliente/create-cita-client-email-send/create-cita-client-email-send.component';
import { SearchResultComponent } from './components/search-result/search-result.component';
import { ListaCitasComponent } from './views/lista-citas/lista-citas.component';
import { ProviderAssignDialogComponent } from './views/provider-selector/provider-assign-dialog/provider-assign-dialog.component';
import { ImputarTiempoOrComponent } from './views/imputar-tiempo-or/imputar-tiempo-or.component';
import { CargaTallerViewComponent } from './carga-taller-view/carga-taller-view.component';
import { AddEditedDialogComponent } from './views/edit-add/add-edited-dialog/add-edited-dialog.component';
import { MatRippleModule } from '@angular/material/core';
import { CloudTicketsComponent } from './views/cloud-tickets/cloud-tickets.component';
import { ComingSoonComponent } from './views/coming-soon/coming-soon.component';
import { TestDirective } from './test.directive';
import { VoSectionTitleComponent } from './views/edit-add/accordion-cards/vo-section-title/vo-section-title.component';
import { MatSliderModule } from '@angular/material/slider';
import { TestComponent } from './components/test/test.component';
import { MecanicoModificarHoraComponent } from './views/citas/Mecanico/mecanico-modificar-hora/mecanico-modificar-hora.component';
import { MecancicoDescartarCitaComponent } from './views/citas/Mecanico/mecancico-descartar-cita/mecancico-descartar-cita.component';
import { MecanicoCitaHechaComponent } from './views/citas/Mecanico/mecanico-cita-hecha/mecanico-cita-hecha.component';
import { MenuComponent } from './components/menu/menu.component';
import { MenuItemComponent } from './components/menu/menu-item/menu-item.component';
import { MenuItemMultipleComponent } from './components/menu/menu-item-multiple/menu-item-multiple.component';
import { AppContentsComponent } from './components/app-contents/app-contents.component';
import { ClientsComponent } from './views/clients/clients.component';
import { ClientSearcherComponent } from './views/crear-recepcion/client-searcher/client-searcher.component';
import { MecanicoMarcarPendienteCitaComponent } from './views/citas/Mecanico/mecanico-marcar-pendiente-cita/mecanico-marcar-pendiente-cita.component';
import { AddTimeOrDialogComponent } from './components/add-time-or-dialog/add-time-or-dialog.component';
import { HistoryTimeOrDialogComponent } from './components/history-time-or-dialog/history-time-or-dialog.component';
import { OrLineComponent } from './components/or-line/or-line.component';
import { LineTypeSelectorComponent } from './components/line-type-selector/line-type-selector.component';
import { HolidaysCalendarComponent } from './components/holidays-calendar/holidays-calendar.component';
import { ArticlesComponent } from './views/articles/articles.component';

registerLocaleData(es);
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

//Configuración del core
export const coreConfiguration = new CoreConfiguration(
  environment,
  ViewPath,
  endpoints,
  ViewPath.login,
  ViewPath.pageNotFound,
  ViewPath.dashboard,
  false,
  "sinicloud",
  endpoints.insertError,
  endpoints.checkVersion,
)

@NgModule({
  declarations: [
    EntryPointComponent,
    LoginComponent,
    TallerComponent,
    ToolbarComponent,
    SectionTitleComponent,
    EstadoTallerComponent,
    CargaTallerComponent,
    HistorialRecepcionesComponent,
    ProfileComponent,
    BuscdorMatriculaComponent,
    MatriculaResultsComponent,
    CrearRecepcionComponent,
    DatosClienteComponent,
    EstadoTallerCardComponent,
    MatriculaResultsEmptyComponent,
    CrearCitaComponent,
    HourPickerComponent,
    EditCompanyInfoComponent,
    EditCitaContentComponent,
    EditarCitaClienteComponent,
    MecanicoModificarHoraComponent,
    MecancicoDescartarCitaComponent,
    MecanicoCitaHechaComponent,
    CrearDatosClienteComponent,
    LanguageSelectorComponent,
    DashboardComponent,
    CommentPedirCitaComponent,
    CitaResultClienteDialogComponent,
    FaqsComponent,
    PhoneMenuComponent,
    DatosClienteLineComponent,
    CardLineComponent,
    PlateScanComponent,
    UsersComponent,
    DeleteUserComponent,
    ChangePasswordComponent,
    LanzaderaComponent,
    AddCardComponent,
    EditAddComponent,
    FichaTecnicaComponent,
    PublicarAnuncioComponent,
    SubirFotografiaComponent,
    ImageToggleComponent,
    ImgToggleItemComponent,
    ProviderSelectorComponent,
    ConfigurationComponent,
    PricingComponent,
    PricingCardComponent,
    RowThreeComponent,
    RowTwoComponent,
    RowComponent,
    SectionSubtitleComponent,
    CompanyCalendarEeComponent,
    CreateCitaClienteComponent,
    CreateCitaClientEmailSendComponent,
    SearchResultComponent,
    ListaCitasComponent,
    ProviderAssignDialogComponent,
    ImputarTiempoOrComponent,
    CargaTallerViewComponent,
    AddEditedDialogComponent,
    CloudTicketsComponent,
    ComingSoonComponent,
    TestDirective,
    VoSectionTitleComponent,
    TestComponent,
    MenuComponent,
    MenuItemComponent,
    MenuItemMultipleComponent,
    AppContentsComponent,
    ClientsComponent,
    ClientSearcherComponent,
    MecanicoMarcarPendienteCitaComponent,
    AddTimeOrDialogComponent,
    HistoryTimeOrDialogComponent,
    OrLineComponent,
    LineTypeSelectorComponent,
    HolidaysCalendarComponent,
    ArticlesComponent,
    CreateEditUserComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatMenuModule,
    MatDividerModule,
    MatButtonToggleModule,
    NgApexchartsModule,
    MatTableModule,
    MatDialogModule,
    HttpClientModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatChipsModule,
    MatSelectModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatBadgeModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatExpansionModule,
    MatTreeModule,
    CdkTreeModule,
    MatListModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatStepperModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatTabsModule,
    ImageCropperModule,
    MatRippleModule,
    MatSliderModule,
    NgxGoogleAnalyticsModule.forRoot(environment.analyticsId),
    NgxGoogleAnalyticsRouterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AngularLibModule.forRoot(coreConfiguration)
  ],
  exports: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: ErrorHandler, useClass: CustomErrorHandler }],
  bootstrap: [EntryPointComponent]
})
export class AppModule { }
