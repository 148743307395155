<!--[filter]="f"
    !-->

<app-page-structure [masterClass]="'product'" pageTitle="ARTÍCULOS" [autocompleteID]="'articles'"
    searchLabel="Buscar artículo" [data]="[]"
    [displayedHeader]=" ['Número', 'Referencia', 'Nombre', 'Pvp', 'Coste', 'Stock', ]"
    [displayedColumns]="['id', 'Ref', 'Denominacion1p', 'Pvp', 'Coste', 'stock']"
    [specialText]="[undefined, undefined, undefined, 'money', undefined]"
    [specialClass]="[undefined, undefined, undefined, undefined, undefined]" [card]="card" [filters]="filters"
    [filter]="filter" [lordIcon]="lordIcon" [canAddNew]="false">
    <ng-template #lordIcon>
        <lord-icon src="https://cdn.lordicon.com/yzjlpgpq.json" trigger="loop" delay="60000"
            colors="outline:#121331,primary:#2f4858,secondary:#646e78,tertiary:#b06836,quaternary:#2fbd96,quinary:#f7c7be"
            style="width:50px;height:50px">
        </lord-icon>
    </ng-template>
    <ng-template #card>
        <app-card *ngIf="ps.showItem()">

            <app-card-title [noMarginBottom]="true">{{ps.selected!.Denominacion1p ? ps.selected!.Denominacion1p :
                "ARTÍCULO Nº" + ps.selected!.id}}</app-card-title>
            <p class="c_t2 fss">{{ps.selected!.Ref}}</p>

            <div class="df jcsb fdr fww">
                <div>
                    <app-card-subtitle [first]="true">STOCK</app-card-subtitle>
                    <div class="df fdr jcsb fww" style="gap:15px">
                        <div>
                            <p class="fw400 nmb">
                                <span class="fw500"> <mat-icon class="vam"
                                        [fontSet]="'material-symbols-outlined'">inventory_2</mat-icon> En Stock: </span>
                                <span class="fwl">{{ps.selected!.stock}} uds.</span>
                            </p>
                            <p class="fw400 nmb">
                                <span class="fw500"> <mat-icon class="vam"
                                        [fontSet]="'material-symbols-outlined'">assignment_late</mat-icon> Control:
                                </span>
                                <span>
                                    <span class="fwl">{{ps.selected?.ControlStock ? 'Tiene control de stock':'Sin control de stock'}}</span>
                                </span>
                            </p>
                        </div>
                        <div>
                            <p class="fw400 nmb">
                                <span class="fw500"> <mat-icon class="vam"
                                        [fontSet]="'material-symbols-outlined'">trending_up</mat-icon>Máximo: </span>
                                <span class="fwl">{{ps.selected!.StockMaximo}} uds.</span>
                            </p>
                            <p class="fw400">
                                <span class="fw500"> <mat-icon class="vam"
                                        [fontSet]="'material-symbols-outlined'">trending_down</mat-icon> Mínimo: </span>
                                <span class="fwl">{{ps.selected!.StockMinimo}} uds.</span>
                            </p>
                        </div>
                    </div>
                </div>

                <div>
                    <app-card-subtitle>PRECIOS</app-card-subtitle>
                    <p class="fw400 nmb">
                        <span class="fw500"> <mat-icon class="vam"
                                [fontSet]="'material-symbols-outlined'">euro</mat-icon> PVP: </span>
                        <span class="fwl" money [val]="ps.selected!.Pvp"></span>
                    </p>
                    <p class="fw400 nmb">
                        <span class="fw500"> <mat-icon class="vam"
                                [fontSet]="'material-symbols-outlined'">euro</mat-icon> Coste: </span>
                        <span class="fwl" money [val]="ps.selected!.Coste"></span>
                    </p>
                    <p class="fw400">
                        <span class="fw500"> <mat-icon class="vam"
                                [fontSet]="'material-symbols-outlined'">percent</mat-icon> Descuento: </span>
                        <span class="fwl"> {{ps.selected!.DtoVta? ps.selected!.DtoVta + "%" : 'Sin descuento'}}</span>
                    </p>
                </div>

                <div> <app-card-subtitle [first]="false">ÚLTIMA ENTRADA</app-card-subtitle>
                    <p>
                        <mat-icon class="vam" [fontSet]="'material-symbols-outlined'">calendar_month</mat-icon>
                        <span class="fwl" nullable [val]="ps.selected?.FeUltEntrada?.dayMonthYearFormat"></span>
                    </p>
                </div>

            </div>
            
        </app-card>
    </ng-template>
</app-page-structure>