import { Injectable } from '@angular/core';
import { apiService } from '../core/api/api-service';
import { M_OR } from '../core/models/M_OR';
import { RouterService, SessionService } from '@sinigual/angular-lib';
import { ViewPath } from '../modules/app-routing.module';
import { ProfileService } from '../views/profile/profileService';

@Injectable({
  providedIn: 'root'
})
export class OrTimerService {
  workingOr: M_OR[] = [];
  constructor(private apiS: apiService, private routerS: RouterService, private profileS: ProfileService, private sessionS: SessionService) {
    this.sessionS.onLogOut.subscribe(v => {
      this.workingOr = [];
    })
  }

  makeCall() {
    this.workingOr = [];
    if (this.sessionS.hasSession()) {
      this.apiS.currentlyWorking().then(res => {
        this.workingOr = res ? res : [];
      })
    }
  }

  /** Is the user working in more than 1 or? */
  get hasMore() {
    return this.workingOr.length > 1;
  }

  /** Number to show */
  get moreNumber() {
    return this.workingOr.length - 1;
  }

  /** Menu or list */
  get moreOr() {
    return this.workingOr.slice(1);
  }

  start(or: M_OR | undefined) {
    if (or) { this.workingOr.unshift(or); }
  }

  end(or: M_OR | undefined) {
    if (or) {
      this.workingOr.forEach((arrayOr, index) => {
        if (arrayOr.id == or.id) {
          this.workingOr.removeIndex(index);
        }
      })
    }
  }

  goOr(or: M_OR) {
    this.routerS.goWithQueryParams(ViewPath.workOr, { or: or.id })
  }

  get refOr() {
    return this.workingOr[0] != undefined ? this.workingOr[0] : undefined;
  }


  get elapsed() {
    if (!this.refOr) { return undefined; }
    return this.orElapsed(this.refOr);
  }

  orElapsed(or: M_OR) {
    var elapsed = or.isOperatorWorking(this.profileS.clientData?.operario_id)?.elapsed;
    if (elapsed){
      return or.isOperatorWorking(this.profileS.clientData?.operario_id)?.elapsed
    }
    else {
      this.end(or)
      return undefined;
    }
  }

}
