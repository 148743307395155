import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogService, PageStructureComponent, ResponsiveService, RouterService } from '@sinigual/angular-lib';
import { apiService } from 'src/app/core/api/api-service';
import { M_DeleteOr } from 'src/app/core/models/M_DeleteOr';
import { M_ShowPreorder } from 'src/app/core/models/M_ShowPreorder';
import { ViewPath } from 'src/app/modules/app-routing.module';

@Component({
  selector: 'app-recepcion-activa',
  templateUrl: './historial-recepciones.component.html',
  styleUrls: ['./historial-recepciones.component.css', '../../css/table.css']
})

export class HistorialRecepcionesComponent implements OnInit {
  @ViewChild(PageStructureComponent) ps!: PageStructureComponent<M_ShowPreorder>;
  dataSource!: MatTableDataSource<M_ShowPreorder>;
  v = ViewPath;

  constructor(public routerS: RouterService, private apiService: apiService, public dialog: MatDialog, public responsiveS: ResponsiveService, private confirmDialogS: ConfirmDialogService) { }

  ngOnInit(): void {
    this.makeCall();
  }


  makeCall(): void {
    this.apiService.showPreOrder().then(result => {
      this.ps.initTable(result)
    });
  }

  statusTxt(d: M_ShowPreorder) {
    return d.downloaded == "0" ? "PENDIENTE" : "ABIERTA"
  }

  statusClass(d: M_ShowPreorder) {
    return d.downloaded == "0" ? "pendiente" : "abierta"
  }

  /**Abre el dialogo de confirmación para eliminar una OR */
  openDeleteDialog(preorder: M_ShowPreorder) {
    this.confirmDialogS.show({ title: "ELIMINAR RECEPCIÓN", body: "¿Seguro que quiere eliminar la recepción?", type: "danger", confirmTxt: "OK" })
      .afterClosed().subscribe((result: any) => {
        if (result) {
          this.apiService.deleteOr(new M_DeleteOr(preorder.id)).then(_response => {
            this.ps.removeElement(preorder);
          });
        }
      });
  }
}
