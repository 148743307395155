import { CustomDate, CustomFile, M_BaseUser, ORInterface, getArrayOf, match, or_status, or_status_SinicloudFinished, or_status_close, or_status_invoiced, or_status_open, or_types, or_types_cargo_interno, or_types_garantia, or_types_mantenimiento, or_types_normal, or_types_siniestro, or_types_todefine } from "@sinigual/angular-lib";
import { M_OR_Group } from "./M_OR_Group";
import { SiniwinOrStatus } from "../enums/SiniwinOrStatus";
import { M_ORTime } from "./M_ORTime";
import { M_User } from "./M_User";

const MAX_OR_LINES = 430;

export class M_OR implements ORInterface {

    id: number = 0;
    NumOr: number;
    schedule: CustomDate | undefined
    delivery: CustomDate | undefined;
    type: or_types[] = [];
    assigned: M_BaseUser | undefined = undefined;
    groups: M_OR_Group[] = [];
    images: CustomFile[] = [];
    nif: string | undefined;
    name1: string | undefined;
    name2: string | undefined;
    license: string;
    marca: string | undefined;
    modelo: string | undefined;
    siniwin_status: SiniwinOrStatus = SiniwinOrStatus.SINESTADO;
    status: or_status = or_status_open;
    timers: M_ORTime[] = [];


    constructor(d: any) {
        this.id = d.id;
        this.NumOr = Number(d.NumOr);
        this.license = d.Matricula;
        this.nif = d.Nif;
        this.marca = d.DenoMarca;
        this.modelo = d.DenoModelo1;
        this.name1 = d.Nombre1 ? d.Nombre1 : undefined;
        this.name2 = d.Nombre2 ? d.Nombre2 : undefined;

        var siniwin_status = d.Situacion;
        if (siniwin_status && typeof siniwin_status == "string") {
            this.siniwin_status = siniwin_status.getNumber();
        }

        if (d.Estado) {
            this.status = d.Estado == 0 ? or_status_open : d.Estado == 1 ? or_status_close : d.Estado == 2 ? or_status_invoiced : or_status_open;
        }

        if (siniwin_status == SiniwinOrStatus.FINALIZADA_CLOUD){
            this.status = or_status_SinicloudFinished;
        }

        // N G C S M
        if (d.TiOr) {
            if (d.TiOr == "N") { this.type.push(or_types_normal) }
            else if (d.TiOr == "G") { this.type.push(or_types_garantia) }
            else if (d.TiOr == "C") { this.type.push(or_types_cargo_interno) }
            else if (d.TiOr == "S") { this.type.push(or_types_siniestro) }
            else if (d.TiOr == "M") { this.type.push(or_types_mantenimiento) }
            else { this.type.push(or_types_normal) }
        }
        else {
            this.type.push(or_types_normal)
        }

        if (d.timers) {
            this.timers = getArrayOf(M_ORTime, d.timers);
        }

        if (d.user) {
            this.assigned = new M_User(d.user);
        }

        var detalle = d.detalle ? d.detalle : d.Detalle ? d.Detalle : undefined;
        if (detalle) {
            this.generateGroups(detalle)
        }

        if (d.FeEntrega) {
            try { this.delivery = new CustomDate(d.FeEntrega); } catch (error) { }
        }

        if (d.FeRealizacion) {
            try { this.schedule = new CustomDate(d.FeRealizacion); } catch (error) { }
        }
    }

    get id_to_show(): number {
        return this.NumOr;
    }

    get isOnMaxLines() {
        return this.groups.length >= MAX_OR_LINES;
    }

    closeTime(time_id: number) {
        var t = this.timers.find(time => time.id == time_id);
        if (t) {
            t.end_time = new CustomDate();
        }
    }

    addTime(t: M_ORTime) {
        this.timers.push(t);
    }

    removeTime(id: number | M_ORTime) {
        var v = typeof id == "number" ? id : id.id;
        this.timers.forEach(t => {
            if (t.id == v) {
                this.timers.removeElement(t);
            }
        })
    }

    isOperatorWorking(op_id: number | undefined): { id: number, elapsed: string } | undefined {
        if (this.timers.length == 0 || op_id == undefined) { return undefined }
        else {
            var filtered = this.timers.filter(time => time.op_id == op_id && time.end_time == undefined);
            if (filtered.length <= 0) { return undefined }

            var startTime = filtered[0].start_time.value;
            var dateNow = new Date();

            // get total seconds between the times
            var delta = Math.abs(startTime.getTime() - dateNow.getTime()) / 1000;

            // calculate (and subtract) whole days
            var days = Math.floor(delta / 86400);
            delta -= days * 86400;

            // calculate (and subtract) whole hours
            var hours = Math.floor(delta / 3600) % 24;
            delta -= hours * 3600;

            // calculate (and subtract) whole minutes
            var minutes = Math.floor(delta / 60) % 60;
            delta -= minutes * 60;

            // what's left is seconds
            var seconds = Math.floor(delta % 60);  // in theory the modulus is not required

            this.format(hours);

            var elapsedTime = this.format(hours) + ":" + this.format(minutes) + ":" + this.format(seconds);
            return { id: filtered[0].id, elapsed: elapsedTime };
        }
    }



    format(value: number) {
        return '' + (value < 10 ? '0' : '') + value;
    }

    generateGroups(d: any) {
        for (let i = 0; i < d.length; i++) {
            var or_g = new M_OR_Group(d[i]);
            or_g.or_id = this.id;
            if (this.type.length) {
                or_g.or_type = this.type[0];
            }
            if (!or_g.deleted) {
                this.groups.push(or_g);
            }
        }
    }

    getStatusName() {
        switch (this.status) {
            case or_status_open: return "OR abierta"
            case or_status_close : return "OR cerrada"
            case or_status_SinicloudFinished: return "OR finalizada en Sinicloud"
            case or_status_invoiced: return "OR facturada"
            default : return "OR sin estado específico"
        }
    }

    get canEdit() {
        return this.siniwin_status == SiniwinOrStatus.ABIERTA_CLOUD ||
            this.siniwin_status == SiniwinOrStatus.LIBRE && this.status.open;
    }

    addLine() {
        var g = new M_OR_Group({});
        g.or_id = this.id;
        /** type[0] by default is "Normal" */
        if (this.type.length) {
            g.TiLinea = this.type[0];
            g.or_type = this.type[0];
        }
        g.changes = true;
        this.groups.push(g);
        return g;
    }

    deleteLine(g: M_OR_Group) {
        this.groups.removeElement(g);
    }

    hasImages(): boolean {
        return false;
    }

    isBudget(): boolean {
        return false;
    }

    vehicleName(): string | undefined {
        return this.marca + " " + this.modelo;
    }
    vehicleId(): number | undefined {
        return undefined;
    }
    vehicleBrand(): string | undefined {
        return this.marca;
    }
    vehicleLicense(): string | undefined {
        return this.license;
    }
    vehicleColor(): string {
        return "";
    }
    clientName(): string | undefined {
        return (this.name1 ? this.name1 : "") + " " + (this.name2 ? this.name2 : "");
    }
    clientNif(): string | undefined {
        return this.nif
    }
    clientId(): number | undefined {
        return 1;
    }
    clientEmail(): string | undefined {
        return ""
    }
    clientPhone(): string | undefined {
        return ""
    }

    defaultSearchFilter(_val: string): boolean {
        return match(_val, this.clientName(), this.NumOr.toString(), this.vehicleLicense(), this.vehicleName(), this.vehicleBrand(), this.modelo);
    }

}