import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAccordion } from '@angular/material/expansion';
import { M_Stock } from 'src/app/core/models/M_Stock';
import { EditAddComponent } from '../../edit-add.component';
import { AccordionInterface } from '../accordionInterface';

@Component({
  selector: 'app-ficha-tecnica',
  templateUrl: './ficha-tecnica.component.html',
  styleUrls: ['./ficha-tecnica.component.css', '../accordion.css']
})
export class FichaTecnicaComponent implements OnInit, AccordionInterface {
  @ViewChild('accordion') accordion!: MatAccordion;
  public disabledFormRequired: UntypedFormGroup;
  public disabledFormOptional: UntypedFormGroup;
  public form: UntypedFormGroup;
  constructor(@Inject(EditAddComponent) parent: EditAddComponent, private formBuilder: UntypedFormBuilder) {

    this.disabledFormRequired = this.formBuilder.group({
      /** SINIWIN FIELDS */
      Matricula: ['', Validators.required],
      Kms: ['', Validators.required],
      DesMarca: ['', Validators.required],
      DesModelo: ['', Validators.required],
      Cilindrada: ['', Validators.required],
      Bastidor: ['', Validators.required],
      ValorCompra: ['', Validators.required],
      FeMatriculacion: ['', Validators.required],
    });

    this.disabledFormOptional = this.formBuilder.group({
      MesesGtia: ['', Validators.required],
      DesColor: ['', Validators.required],
    })

    this.form = this.formBuilder.group({
      /** NO SINIWIN FIELDS */
      moto_type: [0],
      moto_year: [''],
      potencia: ['',],
      carnet_type: ['',],
      peso: ['',],
    });

    if (parent.bike) {
      this.setData(parent.bike)
    }
    parent.subject.subscribe(v => this.setData(v));

  }

  get datosAdicionalesOk() {
    return this.form.get("moto_year")?.value;
  }

  ngOnInit(): void {
    this.disabledFormRequired.disable();
    this.disabledFormOptional.disable();
  }

  isSiniwinValid(v : string){
    var value = this.disabledFormRequired.get(v)?.value;
    if (value == "" || value == null || value == undefined){
      return "mat-form-field-invalid";
    }
    return"";
  }

  setData(data: M_Stock): void {
    if (data.add) {
      this.form.patchValue(data.add)
    }
    this.disabledFormRequired.patchValue(data)
    this.disabledFormOptional.patchValue(data);
    if (data.FeMatriculacion) { this.disabledFormRequired.patchValue({ FeMatriculacion: data.FeMatriculacion.dayMonthYearFormat }) }
  }


  isDisabledFormOk() {
    var ok = true;
    var values: object = this.disabledFormRequired.getRawValue();
    Object.entries(values).forEach(v => {
      if (!this.disabledFormRequired.get(v[0])?.value) {
        ok = false;
      }
    })
    return ok;
  }
}
