import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { FormService, ResponsiveService, or_types_cargo_interno, or_types_garantia, or_types_mantenimiento, or_types_normal, or_types_siniestro } from '@sinigual/angular-lib';
import { apiService } from 'src/app/core/api/api-service';
import { M_OR_Group } from 'src/app/core/models/M_OR_Group';
import { ImputarTiempoOrComponent } from 'src/app/views/imputar-tiempo-or/imputar-tiempo-or.component';
import { ProfileService } from 'src/app/views/profile/profileService';

@Component({
  selector: 'app-or-line',
  templateUrl: './or-line.component.html',
  styleUrls: ['./or-line.component.css']
})
export class OrLineComponent implements OnInit {


  maxDenoLength = 30;

  @Output() ondelete: EventEmitter<M_OR_Group> = new EventEmitter<M_OR_Group>();
  @Input() g!: M_OR_Group;
  @Input() canEditOr: boolean = false;
  @Input() canAddLine: boolean = false;


  t_n = or_types_normal;
  t_ci = or_types_cargo_interno;
  t_g = or_types_garantia;
  t_s = or_types_siniestro;
  t_m = or_types_mantenimiento;


  deno: FormControl<number> = new FormControl();
  quantity: FormControl<number> = new FormControl();
  pvp: FormControl<number> = new FormControl();
  discount: FormControl<number> = new FormControl();

  constructor(@Inject(ImputarTiempoOrComponent) public parent: ImputarTiempoOrComponent, private apiS: apiService,
    private formS: FormService, public responsiveS: ResponsiveService, private profileS: ProfileService) {
    this.deno.setValidators([Validators.maxLength(this.maxDenoLength)])
    this.quantity.setValidators([Validators.max(99999), Validators.min(1)])
    this.pvp.setValidators([Validators.max(99999), Validators.min(1)])
    this.discount.setValidators([Validators.max(100), Validators.min(0)])
  }

  ngOnInit(): void {
    switch (this.g.Grupo) {
      case "A":
      case "M":
      case "X":
        this.quantity.addValidators(Validators.required);
        this.quantity.addValidators(this.stockValidator())
        this.quantity.markAllAsTouched();
        this.pvp.addValidators(Validators.required);
        this.pvp.markAllAsTouched();
        break;
      case "C":
        this.deno.addValidators(Validators.required);
        this.deno.markAllAsTouched();
        break;
    }

    /** Important on add an Articule, update the other inputs stock */
    this.parent.updateValidityOfAllInputs();

    if (!this.canEditOr || !this.canAddLine) {
      this.disable();
    }
  }


  disable() {
    this.deno.disable();
    this.quantity.disable();
    this.pvp.disable();
    this.discount.disable();
  }

  stockValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (this.g.isArticle) {
        return !this.haveStock ? { stock: true } : null;
      }
      return null;
    }
  }

  /** Refresh the page if there is a error */
  save() {
    this.apiS.addLine(this.g)
      .then(res => {
        if (res == false) {
          this.parent.showRefreshObligation();
        }
        else if (res != undefined) {
          this.g.detalle_id = res.id;
        }
        this.g.changes = false;
      })
      .catch(e => {
        this.parent.showRefreshObligation(e);
      })
  }

  /** Overpass min stock*/
  get overPassMinStock() {
    if (!this.g.isArticle) { return false }
    if (this.g.isArticle && !this.stockControl) { return false };
    if (this.g.isArticle && this.articleMinimum == 0) { return false };
    return this.g.isArticle && this.articleStock < this.articleMinimum;
  }

  get haveStock() {
    if (!this.g.isArticle) { return true }
    if (this.g.isArticle && !this.stockControl) { return true };
    return this.g.isArticle && this.articleStock >= 0
  }


  saveExtraInfo(): { error: string, color: "c_r" | "c_o" } | undefined {

    if (this.g.siniwinLine) {
      return undefined;
    }

    switch (this.g.Grupo) {
      /** Article, manual, trabajo */
      case "A":
      case "M":
      case "X":
        if (!this.haveStock) {
          return { error: "Sin stock", color: "c_r" }
        }

        var validForm = this.canEditOr && this.canAddLine ?
          this.quantity.valid &&
          this.pvp.valid &&
          this.discount.valid :
          true; // If the or is non-editable, no need for checking validity of the form controls.

        if (!this.g.created && !validForm) {
          return { error: "Faltan campos obligatorios", color: "c_r" }
        }
        else if (!validForm) {
          return { error: "Detalle con valores incorrectos", color: "c_r" }
        }
        break;
      case "C":
        if (!this.g.created && !this.deno.value) {
          return { error: "Rellene el comentario", color: "c_r" }
        }
        else {
          if (!this.deno.value) {
            return { error: "El comentario es obligatorio", color: "c_r" }
          }
        }
    }
    return undefined;
  }

  /** Only Cloud lines !! */
  saveEnabled() {
    switch (this.g.Grupo) {
      /** Article, manual, trabajo */
      case "A":
      case "M":
      case "X":
        return this.quantity.valid && this.pvp.valid && this.discount.valid && this.g.changes;
      case "C":
        return this.deno.valid && this.g.changes;
      default:
        return this.g.changes;
    }
  }

  get isGestorOrAsesor() {
    return this.profileS.gestor || this.profileS.asesor;
  }

  get canChangeInput() {
    return this.canEditOr && this.isGestorOrAsesor;
  }

  get canChangeGroup() {
    return this.isGestorOrAsesor && this.canEditOr && this.canAddLine && !this.g.siniwinLine;
  }

  get articleStock() {
    return this.parent.getStockOf(this.g.article_id)
  }

  get articleMinimum() {
    return this.parent.getMinimumStock(this.g.article_id);
  }

  get stockControl() {
    return this.parent.getStockControl(this.g.article_id);
  }

  get canDeleteLine() {
    return this.canEditOr && this.isGestorOrAsesor && !this.g.isTempario
  }

}
