import { CustomDate } from "@sinigual/angular-lib";

/** Generte holiday array
 * @param obj : Array containing the holidays object
 * @param of : Get the company or the user holidays
*/
export function generateHoliday(obj: any, of: "company" | "user"): CustomDate[] {
    var returnArray: CustomDate[] = [];
    if (obj) {
        for (let i = 0; i < obj.length; i++) {

            var currentObj = obj[i];

            if (currentObj.day_holiday) {
                if (currentObj.company_id && of == "company") {
                    returnArray.push(new CustomDate(currentObj.day_holiday))
                }
                else if (currentObj.user_id && of == "user") {
                    returnArray.push(new CustomDate(currentObj.day_holiday))
                }
            }
        }
    }
    return returnArray;
}