import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { M_BookedHours } from '../models/M_BookedHours';
import { M_Cita, M_CitaInterface, M_Cita_AlreadyClient } from '../models/M_Cita';
import { M_Company } from '../models/M_Company';
import { NotificationCreator } from '../models/M_Notificacion';
import { M_BuscadorMatricula } from '../models/M_BuscarMatricula';
import { M_DeleteOr } from '../models/M_DeleteOr';
import { M_CargaTaller } from '../models/M_CargaTaller';
import { M_EstadoTallerParam, M_EstadoTaller } from '../models/M_EstadoTaller';
import { M_LoginResponse } from '../models/M_Login';
import { M_profile } from '../models/M_Profile';
import { M_Rider } from '../models/M_Rider';
import { M_ShowPreorder } from '../models/M_ShowPreorder';
import { endpoints } from './Endpoints';
import { M_User } from '../models/M_User';
import { CustomDate, M_BaseUser, M_CloudTicket, NotificationInterface, ORInterface, ReportedProblem, getArrayOf } from '@sinigual/angular-lib';
import { M_Stock } from '../models/M_Stock';
import { M_Provider } from '../models/M_Provider';
import { M_OR } from '../models/M_OR';
import { AppointmentInterface } from '@sinigual/angular-lib/lib/interface/AppointmentInterface';
import { M_ORTime } from '../models/M_ORTime';
import { error } from 'logrocket';
import { M_Article } from '../models/M_Article';
import { M_OR_Group } from '../models/M_OR_Group';
import { generateHoliday } from 'src/app/functionUtils/functions';
import { resolve } from 'dns';
import { rejects } from 'assert';

/**
 * [Service]
 * 
 * Servicio para realizar las llamadas http.
 * 
 * Todas las requests se interceptan el la classe @httpListener
 * 
 * El header de autorización, se añade también en la classe httpListener
 */

@Injectable({
    providedIn: "root"
})

export class apiService {

    constructor(private http: HttpClient) { }

    /**USER LOGIN */
    doLogin(body: UntypedFormGroup) {
        return new Promise<M_LoginResponse>(resolve => {
            this.http.post<any>(endpoints.login.url, body).subscribe(
                data => {
                    resolve(new M_LoginResponse(data));
                }
            );
        })
    }


    clients() {
        return new Promise<M_Rider[]>(resolve => {
            this.http.post<any>(endpoints.clients.url, {}).subscribe(
                data => { resolve(getArrayOf(M_Rider, data)) })
        }
        )
    }

    /**ESTADO TALLER 
     * @param param Días solicitados --> Pueden ser (7), (30), o el mes actual (~30)
    */
    getEstadoTaller(param: M_EstadoTallerParam) {
        return new Promise<M_EstadoTaller>(resolve => {
            this.http.get<any>(endpoints.estadoTaller + param).subscribe(
                data => { resolve(new M_EstadoTaller(data)) })
        }
        )
    }

    /**CARGA TALLER */
    getCargaTaller() {
        return new Promise<M_CargaTaller[]>(resolve => {
            this.http.get<any>(endpoints.cargaTaller.url).subscribe(
                data => {
                    let data_: M_CargaTaller[] = [];
                    for (let i = 0; i < data.length; i++) {
                        data_.push(new M_CargaTaller(data[i]))
                    }
                    resolve(data_);
                });
        }
        )
    }

    /**BUSCADOR POR MATRÍCULA */
    showRaider(body: string, onlyPlate: boolean) {
        body = body.trim().replace(/\s/g, "");
        return new Promise<M_Rider | undefined>(resolve => {
            this.http.post<any>(endpoints.showRider.url, { 'license_plate': body, 'only_plate': onlyPlate }).subscribe(
                data => {
                    if (data.message || Array.isArray(data) && data.length == 0) {
                        resolve(undefined);
                    }
                    else {
                        resolve(new M_Rider(data[0]));
                    }

                },
                _error => {
                    resolve(undefined)
                });
        }
        )
    }

    /**BUSCADOR POR MATRÍCULA */
    showRaiderMultiple(param: M_BuscadorMatricula) {
        return new Promise<M_Rider[] | undefined>(resolve => {
            this.http.post<any>(endpoints.showRider.url, param.getBody()).subscribe(
                data => {
                    if (data.message) {
                        resolve(undefined);
                    }
                    else {
                        let data_: M_Rider[] = [];
                        for (let i = 0; i < data.length; i++) {
                            data_.push(new M_Rider(data[i]))
                        }
                        resolve(data_);
                    }
                    resolve(undefined)
                });
        }
        )
    }

    /**MOSTRAR HISTORIAL DE OR */
    showPreOrder() {
        return new Promise<M_ShowPreorder[]>(resolve => {
            this.http.get<M_ShowPreorder[]>(endpoints.showPreorder.url).subscribe(
                data => {
                    let data_: M_ShowPreorder[] = [];
                    for (let i = 0; i < data.length; i++) {
                        data_.push(new M_ShowPreorder(data[i]))
                    }
                    resolve(data_);
                });
        }
        )
    }

    /**CREACIÓN DE OR */
    createOr(body: FormData) {
        return new Promise<any>(resolve => {
            this.http.post<any>(endpoints.orderCreate.url, body).subscribe(
                data => { resolve(data); });
        }
        )
    }

    /**CAMBIAR IMAGEN DE PERFIL */
    changeProfilePicture(body: FormData) {
        return new Promise<any>(resolve => {
            this.http.post<any>(endpoints.changePicture.url, body).subscribe(
                data => { resolve(data); });
        }
        )
    }

    createUpdateAdd(anuncio: M_Stock) {
        /** Quitar los proveedors si no se tiene que publicar el anuncio */
        var data = { ...anuncio };
        if (data.add != undefined && !data.add.publicar) {
            data.add.id_services = [];
        }
        return new Promise<{ "message": string, "url_appmoto": string | null, id: number }>(resolve => {
            this.http.post<any>(endpoints.createUpdateAdd.url, data).subscribe(
                data => { resolve(data); });
        })
    }


    /**MOSTRAR LOS DATOS DEL PERFIL */
    showProfile() {
        return new Promise<M_profile>(resolve => {
            this.http.get<M_profile>(endpoints.showProfile.url).subscribe(
                data => { resolve(new M_profile(data)); });
        }
        )
    }

    /**ELIMINAR OR */
    deleteOr(body: M_DeleteOr) {
        return new Promise<any>(resolve => {
            this.http.post<any>(endpoints.deleteOr.url, body).subscribe(
                data => { resolve(data); });
        }
        )
    }


    /**CREAR CITA CLIENTE*/
    createCitaCliente(body: M_Cita | M_Cita_AlreadyClient) {
        return new Promise<any>(resolve => {
            this.http.post<any>(endpoints.createCitaCliente.url, body).subscribe(
                data => { resolve(data); });
        }
        )
    }

    /**EDITAR CITA CLIENTE */
    editarCitaCliente(body: M_CitaInterface, hash: string) {
        body['hash'] = hash;
        return new Promise<any>(resolve => {
            this.http.post<any>(endpoints.editarCitaCliente.url, body).subscribe(
                data => { resolve(data); });
        }
        )
    }

    /**CREAR CITA Mecanico*/
    createCitaMecanico(body: M_Cita) {
        return new Promise<number>(resolve => {
            this.http.post<any>(endpoints.createCitaMecanico.url, body).subscribe(
                data => { resolve(data); });
        }
        )
    }

    /**MOSTRAR CITA CLIENTE A PARTIR DE HASH */
    showCitaCliente(hash: string) {
        return new Promise<boolean | M_Cita>(resolve => {
            this.http.get<any>(endpoints.showCitaCliente.url + hash).subscribe(
                data => { resolve(new M_Cita(data)) },
                _error => { resolve(false) })
        }
        )
    }

    /** DEVUELVE LA INFORMACIÓN DE LA COMPAÑÍA */
    getCompanyInfo() {
        return new Promise<M_Company>(resolve => {
            this.http.get<any>(endpoints.companyInfo.url).subscribe(
                data => { resolve(new M_Company(data)) })
        }
        )
    }

    /** GUARDA LA INFORMACIÓN DE LA COMPAÑÍA */
    setCompanyInfo(body: M_Company) {
        return new Promise<M_Company>(resolve => {
            this.http.post<M_Company>(endpoints.setCompanyInfo.url, body).subscribe(
                data => { resolve(data); });
        }
        )
    }

    /** GUARDA IMAGEN COMPAÑÍA */
    setCompanyImg(body: FormData) {
        return new Promise<M_Company>(resolve => {
            this.http.post<M_Company>(endpoints.setCompanyImg.url, body).subscribe(
                data => { resolve(data); });
        }
        )
    }

    /**DEVUELVE TODAS LAS CITAS A PARTIR DEL DÍA QUE SE LE PASA POR PARÁMETRO*/
    getCitaByDay(date: CustomDate) {
        return new Promise<M_Cita[]>(resolve => {
            this.http.post<string>(endpoints.getCitaByDay.url, { 'fecha_cita': date.dataBaseFormat }).subscribe(
                data => {
                    let data_: M_Cita[] = [];
                    for (let i = 0; i < data.length; i++) {
                        data_.push(new M_Cita(data[i]))
                    }
                    resolve(data_);
                });
        }
        )
    }

    /**
     * Obtiene las horas ocupadas al crear una cita
     */
    getBookedHours(date: CustomDate) {
        return new Promise<M_BookedHours>(resolve => {
            this.http.post<any>(endpoints.getBookedHours.url, { 'fecha_cita': date.noHourFormat }).subscribe(
                data => {
                    if (data instanceof M_BookedHours) {
                        resolve(new M_BookedHours(data))
                    }
                    else {
                        resolve(data);
                    }
                })
        }
        )
    }

    /**
     * Solicita las notificaciones del usuario
     * @returns Todas las notificaciones del usuario
     */
    getNotifications() {
        return new Promise<NotificationInterface[]>(resolve => {
            this.http.post<NotificationInterface[]>(endpoints.getNotifications.url, {}).subscribe(
                data => {
                    resolve(new NotificationCreator().createNotificationByResponse(data));
                })
        })
    }

    /**
     * Establece una notificación como leída
     * @param id Id de la notificación
     */
    setNotificationReaded(not: NotificationInterface) {
        return new Promise<any>(resolve => {
            this.http.post<any>(endpoints.updateNotifications.url, { 'notify_id': not.id, type: not.type }).subscribe(
                data => { resolve(data) })
        }
        )
    }

    getFirstDay() {
        return new Promise<CustomDate>(resolve => {
            this.http.get<any>(endpoints.showFirstDay.url).subscribe(
                data => {
                    try {
                        resolve(new CustomDate(data))
                    }
                    catch (e: any) {
                        resolve(new CustomDate())
                    }
                }
            )
        }
        )
    }

    getHoliDays(company_id: number | undefined) {
        return new Promise<CustomDate[]>(resolve => {
            if (company_id) {
                this.http.post<any>(endpoints.getHolidaysRider.url, { "company_id": company_id }).subscribe(res => {
                    resolve(generateHoliday(res.day_holiday, "company"));
                })
            }
            else {
                this.http.post<any>(endpoints.getHolidays.url, {}).subscribe(res => {
                    resolve(generateHoliday(res.day_holiday, "company"));
                })
            }
        })
    }

    /**
     * Primer dia en el que un usuario puede pedir cita
     * (Frontend de la parte de cliente)
     */
    getFirstDayRider(company_id: number) {
        return new Promise<CustomDate>(resolve => {
            this.http.post<any>(endpoints.showFirstDayRider.url, { "company_id": company_id }).subscribe(
                data => {
                    try {
                        resolve(new CustomDate(data))
                    }
                    catch (e: any) {
                        resolve(new CustomDate())
                    }
                }
            )
        }
        )
    }


    /**Marca todas las notificaciones del usuario como leídas */
    readAllNotifications() {
        return new Promise<any>(resolve => {
            this.http.post<any>(endpoints.readAllNotifications.url, {}).subscribe(data => { resolve(data) })
        })
    }

    getNextCitas() {
        return new Promise<M_Cita[]>(resolve => {
            this.http.get<string>(endpoints.getNextCitas.url).subscribe(
                data => {
                    let data_: M_Cita[] = [];
                    for (let i = 0; i < data.length; i++) {
                        data_.push(new M_Cita(data[i]))
                    }
                    resolve(data_);
                });
        }
        )
    }

    /**
     * Obtiene el número de citas que existen a partir de un rango de fechas
     * @param from Fecha de inicio
     * @param to Fecha fin
     * @returns Array que contiene un número para cada día del rango de fechas
     */
    getAppointmentByDateRange(from: CustomDate, to: CustomDate) {
        return new Promise<{ num: number[], pending: boolean[] }>(resolve => {
            this.http.post<any>(endpoints.getAppointmentByDateRange.url, { 'date_from': from.dataBaseFormat, 'date_to': to.dataBaseFormat }).subscribe(
                data => {
                    let data_: { num: number[], pending: boolean[] } = { num: [], pending: [] };
                    for (let i = 0; i < data.length; i++) {
                        data_.num.push(data[i].total_citas);
                        data_.pending.push(data[i].pendientes != 0);
                    }
                    resolve(data_)
                }
            )
        })
    }


    /** ENDPOINTS PEDIR CITA CLIENTE 
     * Se utiliza para cargar la pagina de pedir/editar una cita des de parte del cliente(rider)
    */
    getCompanyInfoById(hash: string) {
        return new Promise<M_Company | boolean>(resolve => {
            this.http.get<any>(endpoints.companyInfoById.url + hash).subscribe(
                data => { resolve(new M_Company(data)) },
                _error => { resolve(false) }) //Poder capturar el error, en caso de passar un company_id que no exista
        }
        )
    }

    /** ENDPOINTS PEDIR HORAS CLIENTE 
     * Se utiliza para obtener las horas ocupadas al pedir una cita des de parte del cliente(rider)
    */
    getBookedHoursByCompanyId(date: CustomDate, companyId: number) {
        return new Promise<M_BookedHours | string>(resolve => {
            this.http.post<any>(endpoints.getBookedHoursByCompanyId.url, { 'fecha_cita': date.noHourFormat, 'company_id': companyId })
                .subscribe(
                    data => {
                        if (data instanceof M_BookedHours) {
                            resolve(new M_BookedHours(data))
                        }
                        else {
                            resolve(data);
                        }
                    })
        },
        )
    }

    showUsers() {
        return new Promise<M_User[]>(resolve => {
            this.http.get<any>(endpoints.showUsers.url).subscribe(
                data => {
                    let data_: M_User[] = [];
                    for (let i = 0; i < data.length; i++) {
                        data_.push(
                            new M_User(data[i])
                        )
                    }
                    resolve(data_)
                })
        })
    }

    createUser(f: FormData) {
        return new Promise<boolean>(resolve => {
            this.http.post<any>(endpoints.createUser.url, f).subscribe(
                data => { resolve(data) },
                _error => { resolve(false) }) //Poder capturar el error, en caso de passar un company_id que no existaista
        })
    }

    editUser(userId: number, f: FormData) {
        return new Promise<boolean>(resolve => {
            f.append("user_id", userId.toString())
            this.http.post<any>(endpoints.editUser.url, f).subscribe(
                data => { resolve(data) },
                _error => { resolve(false) })
        })
    }

    deleteUser(userid: number) {
        return new Promise<number>(resolve => {
            this.http.post<any>(endpoints.deleteUser.url + userid, {}).subscribe(
                data => { resolve(data) })
        }
        )
    }

    showUser(hash: string) {
        return new Promise<M_User | boolean>(resolve => {
            this.http.post<any>(endpoints.showUser.url + hash, {}).subscribe(
                data => {
                    resolve(new M_User(data))
                },
                _error => { resolve(false) })
        }
        )
    }

    changePassword(hash: string, password: string) {
        return new Promise<boolean>(resolve => {
            this.http.post<any>(endpoints.changePassowrd.url + hash, { "password": password }).subscribe(
                data => { resolve(data) })
        })
    }

    forgotPassword(data: any) {
        return new Promise<boolean>(resolve => {
            this.http.post<any>(endpoints.forgotPassword.url, data).subscribe(
                data => { resolve(data) })
        })
    }

    getAllAdds() {
        return new Promise<M_Stock[]>(resolve => {
            this.http.get<any>(endpoints.getStock.url).subscribe(
                data => {
                    var anuncios: M_Stock[] = [];
                    for (let i = 0; i < data.length; i++) {
                        anuncios.push(new M_Stock(data[i]));
                    }
                    resolve(anuncios)
                })
        })
    }

    getStockById(id: number) {
        return new Promise<M_Stock>(resolve => {
            this.http.post<any>(endpoints.getStockById.url, { id: id }).subscribe(
                data => {
                    resolve(new M_Stock(data))
                })
        })
    }

    deleteStock(id: number) {
        return new Promise<boolean>(resolve => {
            this.http.post<any>(endpoints.deleteStock.url, { id: id }).subscribe(
                _data => {
                    resolve(true)
                })
        })
    }

    updateProvider(p: M_Provider) {
        return new Promise<any>(resolve => {
            this.http.post<any>(endpoints.assignService.url, { id_service: p.id }).subscribe(
                data => { resolve((data)) })
        })
    }

    checkDni(dni: string) {
        return new Promise<boolean>(resolve => {
            this.http.post<any>(endpoints.checkDni.url, { dni: dni }).subscribe(
                data => { resolve(data.message) })
        })
    }

    getProviders() {
        return new Promise<M_Provider[]>(resolve => {
            this.http.get<any>(endpoints.showServices.url).subscribe(
                data => {
                    var providers: M_Provider[] = [];
                    for (let i = 0; i < data.length; i++) {
                        providers.push(new M_Provider(data[i]));
                    }
                    resolve(providers)
                })
        })
    }

    /** Calls getProviders() function and filter the result*/
    getCompanyProviders() {
        return new Promise<M_Provider[]>(resolve => { this.getProviders().then(res => { resolve(res.filter(p => p.onCompany == true)); }) })
    }


    /** Client side client search  */
    searchDniAndPlate(matricula: string, dni: string, company_hash?: string) {

        var obj = company_hash == undefined ?
            { matricula: matricula, dni: dni } :
            { matricula: matricula, dni: dni, company_hash: company_hash }

        return new Promise<{ client: boolean, email: boolean }>(resolve => {
            this.http.post<any>(endpoints.dniAndPlateSearch.url, obj).subscribe(
                data => {
                    resolve({
                        client: data.client ? data.client : false,
                        email: data.email ? data.email : false
                    })
                })
        })
    }

    /** Client side client search  */
    searchDniOrPlate(matricula: string, dni: string) {
        return new Promise<M_Rider[] | undefined>(resolve => {
            this.http.post<any>(endpoints.dniOrPlateSearch.url, { matricula: matricula, dni: dni }).subscribe(
                data => {
                    let data_: M_Rider[] = [];
                    for (let i = 0; i < data.length; i++) {
                        data_.push(new M_Rider(data[i]))
                    }
                    resolve(data_);
                },
                _error => {
                    resolve(undefined)
                })
        })
    }



    uploadImage(id: number, files: File[] | undefined) {
        return new Promise<number[]>(resolve => {
            if (files == undefined) { resolve([]) }
            else {
                /** Creating form with files */
                const formData: FormData = new FormData();
                formData.append('id', id.toString());
                var flen = 0;
                for (let i = 0; i < files.length; i++) {
                    var f = files[i];
                    if (f) {
                        formData.append('images' + i, f);
                        flen++;
                    }
                }
                formData.append('imageLength', flen.toString());
                this.http.post<any>(endpoints.uploadImage.url, formData).subscribe(data => {
                    var images: number[] = [];
                    if (data.images) {
                        for (let i = 0; i < data.images.length; i++) {
                            images.push(data.images[i].id);
                        }
                    }
                    resolve(images);
                })
            }
        })
    }

    setMainPicture(id: number, id_img: number) {
        return new Promise<any>(resolve => {
            this.http.post<any>(endpoints.setMainPicture.url, { id: id, id_img: id_img }).subscribe(_data => {
                resolve(true)
            })
        })
    }

    deleteStockImage(id: number, id_img: number) {
        return new Promise<any>(resolve => { this.http.post<any>(endpoints.deleteStockImage.url, { id: id, id_img: id_img }).subscribe(_data => { resolve(true) }) })
    }

    /** ONLY DEV CALL */
    allUsers() {
        return new Promise<M_Rider[]>(resolve => {
            this.http.get<any>(endpoints.onlyDev.url).subscribe(
                res => {
                    let data_: M_Rider[] = [];
                    for (let i = 0; i < res.length; i++) {
                        data_.push(new M_Rider(res[i]))
                    }
                    resolve(data_);
                })
        })
    }



    getCloudTickets() {
        return new Promise<M_CloudTicket[]>(resolve => {
            this.http.get<any>(endpoints.cloudtickets.url).subscribe(
                res => {
                    let data_: M_CloudTicket[] = [];
                    for (let i = 0; i < res.length; i++) {
                        data_.push(new M_CloudTicket(res[i]))
                    }
                    resolve(data_);
                })
        })
    }


    createCloudTicket(rp: ReportedProblem) {
        return new Promise<M_CloudTicket>(resolve => {
            this.http.post<any>(endpoints.createTicket.url, rp.multipartformdata).subscribe(data => {
                resolve(new M_CloudTicket(data))
            })
        })
    }

    addComment(id: number, comment: string) {
        return new Promise<any>(resolve => {
            this.http.post<any>(endpoints.addcomment.url, { id: id, comment: comment }).subscribe(_data => {
                resolve(true)
            })
        })
    }

    user() {
        return new Promise<M_User>(resolve => {
            this.http.get<any>(endpoints.user.url).subscribe(data => {
                resolve(new M_User(data))
            })
        })
    }

    usersWorkers() {
        return new Promise<M_User[]>(resolve => {
            this.http.get<any>(endpoints.usersWorkers.url).subscribe(
                data => {
                    let wokers = [];
                    for (let i = 0; i < data.length; i++) {
                        let w = new M_User(data[i]);
                        if (w.isActivated) {
                            wokers.push(w)
                        }
                    }
                    resolve(wokers);
                })
        })
    }

    schedules(from: CustomDate, to: CustomDate, _showloading: boolean) {
        return new Promise<ORInterface[]>(resolve => {
            this.http.post<any>(endpoints.rangeOR.url, { startDate: from.datePickerFormat, endDate: to.datePickerFormat }).subscribe(data => {
                resolve(getArrayOf(M_OR, data))
            })
        })
    }

    appointments(from: CustomDate, to: CustomDate, _showloading: boolean) {
        return new Promise<AppointmentInterface[]>(resolve => {
            this.http.post<any>(endpoints.appointemtsRange.url, { startDate: from.datePickerFormat, endDate: to.datePickerFormat }).subscribe(data => {
                resolve(getArrayOf(M_Cita, data))
            })
        })
    }

    activateUser(user_id: number) {
        return new Promise<boolean>(resolve => {
            this.http.post<any>(endpoints.activateUser.url, { id: user_id }).subscribe(data => {
                resolve(true)
            })
        })
    }

    orByid(id: number, plate?: string) {
        let data = plate ? { NumOr: id, Matricula: plate } : { or_id: id }
        return new Promise<M_OR>(resolve => {
            this.http.post<any>(endpoints.orById.url, data).subscribe(data => {
                resolve(new M_OR(data))
            })
        })
    }

    // REFRESH OR
    closeOr(id: number) {
        return new Promise<boolean>((resolve, reject) => {
            this.http.post<any>(endpoints.closeOr.url, { or_id: id, close_or: true }).subscribe(data => {
                if (data.error) {
                    resolve(false)
                }
                else {
                    resolve(true)
                }
            },
                (error: Error) => {
                    reject(error.message);
                })
        })
    }



    // REFRESH OR
    startTimeOperator(or_id: number) {
        return new Promise<M_ORTime>((resolve, reject) => {
            this.http.post<any>(endpoints.startTimeOperator.url, { or_id: or_id }).subscribe(
                data => {
                    resolve(new M_ORTime(data))
                },
                (error: Error) => {
                    reject(error.message);
                })
        })
    }


    // REFRESH OR
    endTimeOperator(time_id: number) {
        return new Promise<boolean>((resolve, reject) => {
            this.http.post<any>(endpoints.endTimeOperator.url, { tiempo_id: time_id }).subscribe(
                data => {
                    resolve(true)
                },
                (error: Error) => {
                    reject(error.message);
                })
        })
    }

    // REFRESH OR
    deleteTimeLine(time_id: number) {
        return new Promise<boolean>((resolve, reject) => {
            this.http.post<any>(endpoints.deleteTimeHistory.url, { time_id: time_id }).subscribe(
                data => {
                    resolve(true)
                },
                (error: Error) => {
                    reject(error.message);
                })
        })
    }

    /** Not worker */
    // REFRESH OR
    addManualTime(or_id: number, op_id: number, hours: number, minutes: number, d: CustomDate) {
        return new Promise<M_ORTime>((resolve, reject) => {
            this.http.post<M_ORTime>(endpoints.addManualTime.url, { or_id: or_id, op_id: op_id, hours: hours, minutes: minutes, day: d.datePickerFormat }).subscribe(
                data => {
                    resolve(new M_ORTime(data))
                },
                (error: Error) => {
                    reject(error.message);
                })
        })
    }

    // REFRESH OR
    addLine(g: M_OR_Group) {
        return new Promise<{ id: number } | false | undefined>((resolve, reject) => {
            var finalTLine = g.TiLinea ? g.TiLinea.normal ? "N" : g.TiLinea.cargo ? "C" : g.TiLinea.siniestro ? "S" : g.TiLinea.garantia ? "G" : "M" : "M"
            let groupToSend: any = { ...g }
            groupToSend['TiLinea'] = finalTLine;
            this.http.post<any>(endpoints.addLine.url, groupToSend).subscribe(
                data => {
                    if (data.error == 500) {
                        resolve(false)
                    }
                    if (data.id) {
                        resolve(data)
                    }
                    else {
                        resolve(undefined)
                    }
                }
                ,
                (error: Error) => {
                    reject(error.message);
                })
        })
    }

    // REFRESH OR
    removeLine(line_id: number) {
        return new Promise<any>((resolve, reject) => {
            this.http.post<any>(endpoints.removeLine.url, { detalle_or_id: line_id }).subscribe(
                data => {
                    resolve(data)
                },
                (error: Error) => {
                    reject(error.message);
                })
        })
    }


    showArticulos() {
        return new Promise<M_Article[]>(resolve => {
            this.http.get<any>(endpoints.articles.url).subscribe(data => {
                resolve(getArrayOf(M_Article, data))
            })
        })
    }


    forceCloseOr(id: number) {
        return new Promise<boolean>(resolve => {
            this.http.post<any>(endpoints.forceCloseOr.url, { or_id: id }).subscribe(data => {
                resolve(true);
            })
        })
    }

    currentlyWorking() {
        return new Promise<M_OR[] | undefined>(resolve => {
            this.http.get<any>(endpoints.currentlyWorking.url).subscribe(data => {
                if (data.NumOr) {
                    if (Array.isArray(data)) {
                        resolve(getArrayOf(M_OR, data))
                    }
                    else {
                        resolve([new M_OR(data)]);
                    }
                }
                resolve(undefined)
            })
        })
    }


    checkHolidays(d: CustomDate, id: number | undefined) {
        return new Promise<boolean>(resolve => {
            this.http.post<any>(endpoints.checkHolidays.url, { day: d.dataBaseFormat, user_id: id }).subscribe(
                data => {
                    resolve(true)
                },
                error => {
                    resolve(false)
                })
        })
    }

    saveHolidaysByUserId(days: CustomDate[], id: number | undefined) {
        var finalDays = days.map(d => d.dataBaseFormat);

        return new Promise<any>(resolve => {
            this.http.post<any>(endpoints.setHolidays.url, { days_holiday: finalDays, user_id: id }).subscribe(data => {
                resolve(true)
            })
        })
    }


    changeRole(role: number) {
        return new Promise<any>(resolve => {
            this.http.post<any>(endpoints.changeRole.url, { role_number: role }).subscribe(data => {
                resolve(true)
            })
        })
    }

    logout(){
        return new Promise<any>(resolve => {
            this.http.post<any>(endpoints.logout.url, {}).subscribe(data => {
                resolve(true)
            })
        })
    }


    assignUser(or : M_OR, u : M_BaseUser){
        return new Promise<any>(resolve => {
            this.http.post<any>(endpoints.assignOR.url, { action_id: or.id, user_id: u.id }).subscribe(data => {
                resolve(data);
              });
          })
    }

}
